import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, getCurrentUserFollowing, setFollowingData } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';
import { apiFollowUser, apiGetFollowers, apiGetFollowing, apiGetTransactions } from '../../util/api'

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_SUCCESS_APPEND = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS_APPEND';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SET_REVIEWS_AVG = 'app/ProfilePage/SET_REVIEWS_AVG';

export const QUERY_FOLLOWERS_REQUEST = 'app/ProfilePage/QUERY_FOLLOWERS_REQUEST';
export const QUERY_FOLLOWERS_SUCCESS = 'app/ProfilePage/QUERY_FOLLOWERS_SUCCESS';
export const QUERY_FOLLOWERS_ERROR = 'app/ProfilePage/QUERY_FOLLOWERS_ERROR';

export const QUERY_FOLLOWING_REQUEST = 'app/ProfilePage/QUERY_FOLLOWING_REQUEST';
export const QUERY_FOLLOWING_SUCCESS = 'app/ProfilePage/QUERY_FOLLOWING_SUCCESS';
export const QUERY_FOLLOWING_ERROR = 'app/ProfilePage/QUERY_FOLLOWING_ERROR';

export const QUERY_TRANSACTION_REQUEST = 'app/ProfilePage/QUERY_TRANSACTION_REQUEST';
export const QUERY_TRANSACTION_SUCCESS = 'app/ProfilePage/QUERY_TRANSACTION_SUCCESS';
export const QUERY_TRANSACTION_ERROR = 'app/ProfilePage/QUERY_TRANSACTION_ERROR';

export const FOLLOW_USER_REQUEST = 'app/ProfilePage/FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'app/ProfilePage/FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_ERROR = 'app/ProfilePage/FOLLOW_USER_ERROR';


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  userId: null,
  searchParams: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  queryListingsInProgress: false,
  appendListingsInProgress: false,
  reviews: [],
  queryReviewsError: null,
  reviewsAvg: 0,
  queryFollowersInProgress: false,
  followers: [],
  queryFollowersError: null,
  queryFollowingInProgress: false,
  following: [],
  queryFollowingError: null,
  followUserInProgress: false,
  followUserError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        // Empty listings only when user id changes
        userListingRefs: payload.userId.uuid === state.userId.uuid ? state.userListingRefs : [],
        appendListingsInProgress: !!payload.searchParams.append,
        searchParams: payload.searchParams,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        userListingRefs: payload.listings.data.data.map(({ id, type }) => ({ id, type })),
        pagination: payload.listings.data.meta,
        queryListingsInProgress: false,
        appendListingsInProgress: false,
      };
    case QUERY_LISTINGS_SUCCESS_APPEND:
      return {
        ...state,
        userListingRefs: state.userListingRefs.concat(payload.listings.data.data.map(({ id, type }) => ({ id, type }))),
        pagination: payload.listings.data.meta,
        queryListingsInProgress: false,
        appendListingsInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload, queryListingsInProgress: false, appendListingsInProgress: false, };


    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SET_REVIEWS_AVG:
      return { ...state, reviewsAvg: payload };

    case QUERY_FOLLOWERS_REQUEST:
      return { ...state, queryFollowersInProgress: true, queryFollowersError: null };
    case QUERY_FOLLOWERS_SUCCESS:
      return { ...state, queryFollowersInProgress: false, followers: payload };
    case QUERY_FOLLOWERS_ERROR:
      return { ...state, queryFollowersInProgress: false, queryFollowersError: payload };

    case QUERY_FOLLOWING_REQUEST:
      return { ...state, queryFollowingInProgress: true, queryFollowingError: null };
    case QUERY_FOLLOWING_SUCCESS:
      return { ...state, queryFollowingInProgress: false, following: payload };
    case QUERY_FOLLOWING_ERROR:
      return { ...state, queryFollowingInProgress: false, queryFollowingError: payload };

    case QUERY_TRANSACTION_SUCCESS:
      return { ...state, sales: payload };

    case FOLLOW_USER_REQUEST:
      return { ...state, followUserInProgress: true, followUserError: null };
    case FOLLOW_USER_SUCCESS:
      return { ...state, followUserInProgress: false, };
    case FOLLOW_USER_ERROR:
      return { ...state, followUserInProgress: false, followUserError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = (userId, searchParams) => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId, searchParams },
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryListingsSuccessAppend = listings => ({
  type: QUERY_LISTINGS_SUCCESS_APPEND,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const setReviewsAvg = avg => ({
  type: SET_REVIEWS_AVG,
  payload: avg,
});

export const queryFollowersRequest = () => ({
  type: QUERY_FOLLOWERS_REQUEST,
});

export const queryFollowersSuccess = followers => ({
  type: QUERY_FOLLOWERS_SUCCESS,
  payload: followers,
});

export const queryFollowersError = e => ({
  type: QUERY_FOLLOWERS_ERROR,
  error: true,
  payload: e,
});

export const queryFollowingRequest = () => ({
  type: QUERY_FOLLOWING_REQUEST,
});

export const queryFollowingSuccess = followers => ({
  type: QUERY_FOLLOWING_SUCCESS,
  payload: followers,
});

export const queryFollowingError = e => ({
  type: QUERY_FOLLOWING_ERROR,
  error: true,
  payload: e,
});

export const transactionRequest = option => ({
  type: QUERY_TRANSACTION_REQUEST,
  payload: option,
});

export const queryTransactionsSuccess = transactions => ({
  type: QUERY_TRANSACTION_SUCCESS,
  payload: transactions,
});

export const followUserRequest = () => ({
  type: FOLLOW_USER_REQUEST,
});

export const followUserSuccess = () => ({
  type: FOLLOW_USER_SUCCESS,
});

export const followUserError = e => ({
  type: FOLLOW_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, searchParams) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId, searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, append, price, dates, pub_category, pub_size, pub_color, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const categoryMaybe = pub_category ? { pub_category: 'has_any:' + pub_category } : {};
  const sizeMaybe = pub_size ? { pub_size: 'has_any:' + pub_size } : {};
  const colorMaybe = pub_color ? { pub_color: 'has_any:' + pub_color }: {};

  let sortValue = sort === undefined ? 'createdAt' : sort


  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...categoryMaybe,
    ...sizeMaybe,
    ...colorMaybe,
    sort: ["meta_rankingSoldAttr", sortValue],
    per_page: perPage,
    author_id: userId,
    include: ['author', 'images', 'createdAt', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'fields.listing': ['title', 'price', 'publicData', 'metadata'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      dispatch(addMarketplaceEntities(response));
      if (append) {
        dispatch(queryListingsSuccessAppend(response));  
      } else {
        dispatch(queryListingsSuccess(response));
      }
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      let avg = 0;
      for (let r = 0; r < reviews.length; r++) {
        avg = avg + reviews[r].attributes.rating;
      }
      avg = avg / reviews.length;
      dispatch(setReviewsAvg(avg));
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId.uuid,
      include: ['profileImage', 'publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      
      let user = response.data.data;
      if (user.attributes.profile.publicData.followersCount === undefined || user.attributes.profile.publicData.followingCount === undefined) {
        dispatch(setFollowingData(userId, addMarketplaceEntities));
      }

      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const queryUserTransactions = (user) => (dispatch, getState) => {
  dispatch(transactionRequest(true));
  apiGetTransactions({ user }).then((response) => {
    dispatch(queryTransactionsSuccess(response.transactions));
    dispatch(transactionRequest(false));
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(transactionRequest(false));
  });
};

export const loadData = ({ userId, searchParams }) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, searchParams)),
    dispatch(queryUserReviews(userId)),
    dispatch(queryUserTransactions(userId.uuid)),
  ]);
};

export const queryUserFollowers = (user) => (dispatch, getState, sdk) => {
  dispatch(queryFollowersRequest());
  apiGetFollowers({ user })
    .then((response) => {
      dispatch(queryFollowersSuccess(response.followers));
    })
    .catch(e => {
      dispatch(queryFollowersError(e));
    });
};

export const queryUserFollowing = (user, withDetails = true) => (dispatch, getState, sdk) => {
  dispatch(queryFollowingRequest());
  apiGetFollowing({ user })
    .then((response) => {
      dispatch(queryFollowingSuccess(response.following));
    })
    .catch(e => {
      dispatch(queryFollowingError(e));
    });
};

export const followUser = (currentUser, user, currentPageUser) => (dispatch) => {
  dispatch(followUserRequest());
  apiFollowUser({ currentUser, user })
    .then(response => {
      Promise.all([
        dispatch(getCurrentUserFollowing()),
        dispatch(fetchCurrentUser()),
        dispatch(showUser(currentPageUser.id)),
      ]).then(dispatch(followUserSuccess()));
    })
    .catch(e => {
      dispatch(followUserError(e));
    });
}
