import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { apiGetListingAvailable, apiGetListingTransactions, apiSetAsSold, getAddress, likeProduct, transactionLineItems, setListingNewPriceDiscount, validateEnquiryTransaction } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT
} from '../../util/urlHelpers';

import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { AmplitudeAnalytics, getParamsListing, logEvent, logPixelFacebook } from '../../util/logsEvent';


const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SET_REVIEWS_AVG = 'app/ProfilePage/SET_REVIEWS_AVG';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const LOWER_LISTING_PRICE_REQUEST = 'app/ListingPage/LOWER_LISTING_PRICE_REQUEST';
export const LOWER_LISTING_PRICE_SUCCESS = 'app/ListingPage/LOWER_LISTING_PRICE_SUCCESS';
export const LOWER_LISTING_PRICE_ERROR = 'app/ListingPage/LOWER_LISTING_PRICE_ERROR';

export const TOGGLE_LIKE_REQUEST = 'app/ListingPage/TOGGLE_LIKE_REQUEST';
export const TOGGLE_LIKE_SUCCESS = 'app/ListingPage/TOGGLE_LIKE_SUCCESS';
export const TOGGLE_LIKE_ERROR = 'app/ListingPage/TOGGLE_LIKE_ERROR';

export const GET_COMMENTS_USERS_REQUEST = 'app/ListingPage/GET_COMMENTS_USERS_REQUEST';
export const GET_COMMENTS_USERS_SUCCESS = 'app/ListingPage/GET_COMMENTS_USERS_SUCCESS';

export const FETCH_AVAILABILITY_SUCCESS = 'app/ListingPage/FETCH_AVAILABILITY_SUCCESS';

export const SET_CURRENT_TRANSACTIONS = 'app/ListingPage/SET_CURRENT_TRANSACTIONS';

export const SET_PROVIDER_HAS_ADDRESS = 'app/ListingPage/SET_PROVIDER_HAS_ADDRESS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  queryReviewsError: null,
  reviewsAvg: 0,
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  likeInProgress: null,
  likeError: null,
  hasTransactions: false,
  providerHasAddress: false,
  isAvailable: false,
  commentUsersIds: [],
  fetchCommentUsersInProgress: false,


  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,

  lowerListingPriceInProgress: false,
  lowerListingPriceInError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SET_REVIEWS_AVG:
      return { ...state, reviewsAvg: payload };

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case LOWER_LISTING_PRICE_REQUEST:
      return { ...state, lowerListingPriceInProgress: true, lowerListingPriceInError: null };
    case LOWER_LISTING_PRICE_SUCCESS:
      return { ...state, lowerListingPriceInProgress: false };
    case LOWER_LISTING_PRICE_ERROR:
      return { ...state, lowerListingPriceInProgress: false, lowerListingPriceInError: payload };

    case TOGGLE_LIKE_REQUEST:
      return { ...state, likeInProgress: payload.params, likeError: null };
    case TOGGLE_LIKE_SUCCESS:
      return { ...state, likeInProgress: false };
    case TOGGLE_LIKE_ERROR:
      return { ...state, likeInProgress: false, likeError: payload };
    case GET_COMMENTS_USERS_REQUEST:
      return { ...state, fetchCommentUsersInProgress: true };
    case GET_COMMENTS_USERS_SUCCESS:
      return { ...state, fetchCommentUsersInProgress: false, commentUsersIds: payload };

    case SET_CURRENT_TRANSACTIONS:
      return { ...state, hasTransactions: payload };

    case SET_PROVIDER_HAS_ADDRESS:
      return { ...state, providerHasAddress: payload };

    case FETCH_AVAILABILITY_SUCCESS:
      return { ...state, isAvailable: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});


export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const setReviewsAvg = avg => ({
  type: SET_REVIEWS_AVG,
  payload: avg,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const fetchAvailabilitySuccess = available => ({
  type: FETCH_AVAILABILITY_SUCCESS,
  payload: available
});

export const setProviderHasAddress = status => ({ type: SET_PROVIDER_HAS_ADDRESS, payload: status })

export const setHasCurrentTransactions = e => ({
  type: SET_CURRENT_TRANSACTIONS,
  payload: e
});

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const lowerListingPriceRequest = () => ({ type: LOWER_LISTING_PRICE_REQUEST });
export const sendLowerListingPriceSuccess = () => ({ type: LOWER_LISTING_PRICE_SUCCESS });
export const sendLowerListingPriceError = e => ({ type: LOWER_LISTING_PRICE_ERROR, error: true, payload: e });

export const toggleLikeRequest = requestAction(TOGGLE_LIKE_REQUEST);
export const toggleLikeSuccess = successAction(TOGGLE_LIKE_SUCCESS);
export const toggleLikeError = errorAction(TOGGLE_LIKE_ERROR);


export const fetchCommentUsersRequest = requestAction(GET_COMMENTS_USERS_REQUEST);
export const fetchCommentUsersSuccess = users => ({ type: GET_COMMENTS_USERS_SUCCESS, payload: users });


// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      if (data.data.data) {
        if (data.data.data.relationships.author) {
          let userData = {
            user: data.data.data.relationships.author.data.id.uuid,
            isBoolean: true
          };
          getAddress(userData).then((response) => {
            dispatch(setProviderHasAddress(response.success));
          }).catch((e) => { 

            const data = {
              error_description: 'Error fetching listing on listing page',
            }
            log.error(e, 'fetching-listing-failed', data, 'listing')

          })
        }
      }
      dispatch(queryUserReviews(data.data.data.relationships.author.data.id.uuid))
      dispatch(addMarketplaceEntities(data));
      dispatch(getCommentsUsers(data.data.data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const fetchAvailability = listingId => (dispatch, getState, sdk) => {
  return apiGetListingAvailable({ listingId }).then((response) => {
    dispatch(fetchAvailabilitySuccess(!response.hasTransactions))
  }).catch(() => { });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};


export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      }, 'listing');
    });
};

export const sendEnquiry = (listingId, providerId, customerId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  let bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };

  return validateEnquiryTransaction({ listingId, providerId, customerId })
    .then(apiResponse => {

      if (apiResponse.hasEnquiry) {
        //return something to do history.push to message

        return sdk.messages.send({ transactionId: apiResponse.txId.uuid, content: message }).then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return apiResponse.txId;
        });
      } else {
        return sdk.transactions
          .initiate(bodyParams)
          .then(response => {
            const transactionId = response.data.data.id;

            // Send the message to the created transaction

            return sdk.messages.send({ transactionId, content: message }).then(() => {
              dispatch(sendEnquirySuccess());
              dispatch(fetchCurrentUserHasOrdersSuccess(true));
              return transactionId;
            });
          })
          .catch(e => {
            dispatch(sendEnquiryError(storableError(e)));
            throw e;
          });
      }
    }).catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    })
};

export const lowerListingPrice = (listingId, newPrice) => (dispatch, getState, sdk) => {
  dispatch(lowerListingPriceRequest());
  return setListingNewPriceDiscount({ listingId, newPrice })
    .then(res => {
      dispatch(sendLowerListingPriceSuccess());
      return res;

    }).catch(e => {
      dispatch(sendLowerListingPriceError(storableError(e)));
      return "error"
    })
}

export const messageTransactionValidation = (listingId, providerId, customerId) => (dispatch, getState, sdk) => {

  return validateEnquiryTransaction({ listingId, providerId, customerId })
    .then(apiResponse => {
      //if apiResponse has a transaction that is an enquiry with this data then 
      if (apiResponse.hasEnquiry) {
        return apiResponse.txId;
      } else {
        return null
      }
    }).catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    })
}

export const toggleLike = (user, itemId) => dispatch => {
  dispatch(toggleLikeRequest(itemId));

  likeProduct({ user, itemId })
    .then(async (response) => {

      const price = response.data.data.attributes.price;
      response.data.data.attributes.price = new Money(price.amount, price.currency);

      logEvent('favorite submitted', getParamsListing({ ...response?.data?.data, author: response?.data?.included[0] }));
      logPixelFacebook('AddToWishlist');

      dispatch(addMarketplaceEntities(response));
      dispatch(toggleLikeSuccess(response));
    })
    .catch(e => {
      dispatch(toggleLikeError(storableError(e)));
    })
};

export const getListingTransactions = (userId, itemId) => dispatch => {
  apiGetListingTransactions({ userId, itemId })
    .then(response => {
      dispatch(setHasCurrentTransactions(!!response.transactions.length));
    })
    .catch(e => {
      dispatch(toggleLikeError(storableError(e)));
    })
};

export const setAsSold = (itemId) => dispatch => {
  apiSetAsSold({ itemId, type: 'listing' })
    .then(response => window.location.reload())
    .catch(response => window.location.reload());
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([
    dispatch(showListing(listingId)),
    // dispatch(fetchTimeSlots(listingId)),
    dispatch(fetchAvailability(listingId)),
    dispatch(fetchReviews(listingId)),
  ]);
};

export const getCommentsUsers = (listing) => (dispatch, getState, sdk) => {
  dispatch(fetchCommentUsersRequest());

  if (listing.attributes.metadata && listing.attributes.metadata.comments) {
    const comments = listing.attributes.metadata.comments;
    const commentIds = !!comments && Array.isArray(comments) ? comments.flatMap(c =>
      [c.user.id.uuid, ...(c.comments ? c.comments.map(c => c.user.id.uuid) : [])]
    ) : [];
    const uniqueCommentIds = [...new Set(commentIds)];

    let promises = []

    uniqueCommentIds.forEach(id => {
      promises.push(sdk.users.show({
        id,
        include: ['profileImage', 'publicData'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }));

    });

    return Promise.allSettled(promises)
      .then(responses => {
        const succesful = responses.filter(res => res.status === 'fulfilled');
        succesful.forEach(res => dispatch(addMarketplaceEntities(res.value)))
        let ids = succesful.map(r => r.value.data.data.id)
        dispatch(fetchCommentUsersSuccess(ids));
      }).catch(e => {
        console.error(e);
      });
  }
  dispatch(fetchCommentUsersSuccess([]));
}

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      let avg = 0;
      for (let r = 0; r < reviews.length; r++) {
        avg = avg + reviews[r].attributes.rating;
      }
      avg = avg / reviews.length;
      dispatch(setReviewsAvg(avg));
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

