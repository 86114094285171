import React from 'react'

const ReloveIconWhatsapp = () => {
  return (
<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Social Icons">
<path id="Vector" d="M0 30.5L2.10875 22.7962C0.807498 20.5413 0.12375 17.985 0.125 15.3637C0.12875 7.16875 6.79749 0.5 14.9912 0.5C18.9675 0.50125 22.7 2.05 25.5074 4.86C28.3137 7.67 29.8587 11.405 29.8574 15.3775C29.8537 23.5738 23.1849 30.2425 14.9912 30.2425C12.5037 30.2413 10.0525 29.6175 7.88123 28.4325L0 30.5ZM8.24623 25.7413C10.3412 26.985 12.3412 27.73 14.9862 27.7313C21.7962 27.7313 27.3437 22.1887 27.3474 15.375C27.3499 8.5475 21.8287 3.0125 14.9962 3.01C8.18123 3.01 2.63749 8.5525 2.63499 15.365C2.63374 18.1462 3.44874 20.2287 4.81749 22.4075L3.56874 26.9675L8.24623 25.7413ZM22.48 18.9113C22.3875 18.7563 22.14 18.6637 21.7675 18.4775C21.3962 18.2913 19.57 17.3925 19.2287 17.2687C18.8887 17.145 18.6412 17.0825 18.3925 17.455C18.145 17.8263 17.4325 18.6637 17.2162 18.9113C17 19.1588 16.7825 19.19 16.4112 19.0037C16.04 18.8175 14.8425 18.4263 13.4237 17.16C12.32 16.175 11.5737 14.9587 11.3575 14.5863C11.1412 14.215 11.335 14.0138 11.52 13.8288C11.6875 13.6625 11.8912 13.395 12.0775 13.1775C12.2662 12.9625 12.3275 12.8075 12.4525 12.5588C12.5762 12.3113 12.515 12.0938 12.4212 11.9075C12.3275 11.7225 11.585 9.89375 11.2762 9.15C10.9737 8.42625 10.6675 8.52375 10.44 8.5125L9.72748 8.5C9.47998 8.5 9.07748 8.5925 8.73748 8.965C8.39748 9.3375 7.43748 10.235 7.43748 12.0638C7.43748 13.8925 8.76873 15.6587 8.95373 15.9062C9.13998 16.1537 11.5725 19.9062 15.2987 21.515C16.185 21.8975 16.8775 22.1262 17.4162 22.2975C18.3062 22.58 19.1162 22.54 19.7562 22.445C20.47 22.3387 21.9537 21.5462 22.2637 20.6787C22.5737 19.81 22.5737 19.0662 22.48 18.9113Z" fill="#25D366"/>
</g>
</svg>

  )
}

export default ReloveIconWhatsapp