import classNames from 'classnames';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import {
  AvatarLarge, Modal,
  BookingPanel,
  NamedLink,
  PrimaryButton,
  ReviewModal,
  UserDisplayName
} from '../../components';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txHasBeenDelivered,
  txHasBeenTrackingConfirmed, 
  txIsAccepted,
  txIsPendingValidation, 
  txIsCanceled, 
  txIsDeclined,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPaymentPendingQuickPurchase,
  txIsRequested,
  txIsRequestedQuickPurchase, 
  txIsSent,
  txIsTrackingConfirmed,
  txIsManualPaymentPending,
  txIsPendingConfirmation,
  txIsEnquired,
  txIsPendingPayout,
  txIsClabeMissing,
  txIsAuthenticating,
  txIsAuthenticatingTrackingConfirmed,
  txPaymentFailed
} from '../../util/transaction';
import { SendMessageForm } from '../../forms';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { isMobileSafari } from '../../util/userAgent';
// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import MessageSection from './MessageSection';
import PanelHeading, {
  HEADING_ACCEPTED, 
  HEADING_CANCELED, 
  HEADING_DECLINED, 
  HEADING_PAYMENT_FAILED,
  HEADING_DELIVERED, 
  HEADING_HAS_SENT, 
  HEADING_PAYMENT_EXPIRED, 
  HEADING_PAYMENT_PENDING, 
  HEADING_REQUESTED, 
  HEADING_TRACKING_CONFIRMED, 
  HEADING_MISSING_CLABE,
  HEADING_MANUAL_PAYMENT_PENDING, 
  HEADING_ENQUIRED,
  HEADING_AUTHENTICATING,
  HEADING_AUTHENTICATING_DELIVERING
} from './PanelHeading';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';

import css from './TransactionPanel.css';

import ClabeSection from './ClabeSection';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return formattedPrice
  } else if (price) {
    return price.currency
  }
  return {};
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole, currentListing } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const listing = currentListing ? currentListing.id.uuid : null;
    onSendReview(transactionRole, currentTransaction, rating, reviewContent, listing)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const { innerWidth: width } = window;
    const isMobile = width < 550;
    if (isMobile){
      window.scrollTo(0, document.body.scrollHeight);
    }else{
      const el = document.querySelector(selector);
      if (el) {
        el.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      // nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      hasAddressError,
      addressMessage,
      isSearching,
      isClientError,
      onCloseAlert,
      confirmBtnAction,
      confirmReceivedBtnAction,
      onContactUser,
      isEnquiryModalOpen,
      onCloseEnquiryModal,
      onSubmitEnquiry,
      sendEnquiryInProgress,
      sendEnquiryError,
      isClabeModalOpen,
      onCloseClabeModal,
      onOpenClabeModal,
      onSubmitClabe,
      updateClabeError,
      updateClabeInProgress,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;

    const isPaymentOfferPendingFn = tx => {
      switch (true) {
        case txIsPaymentPending(tx):
        case txIsManualPaymentPending(tx):
        case txIsPendingConfirmation(tx):
        case txIsRequested(tx):
          return true
        default:
          return false
      }
    }
    const stateDataFn = tx => {
      if (txIsPaymentPending(tx) || txIsPaymentPendingQuickPurchase(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsClabeMissing(tx)){
        return {
          headingState: HEADING_MISSING_CLABE,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showUpdateClabeButton: isProvider,
        };
      }else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx) || txIsRequestedQuickPurchase(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsManualPaymentPending(tx)){
        return {
          headingState: HEADING_MANUAL_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsAccepted(tx) || txIsPendingValidation(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsTrackingConfirmed(tx)) {
        return {
          headingState: HEADING_TRACKING_CONFIRMED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsAuthenticating(tx)){
        return {
          headingState: HEADING_AUTHENTICATING,
          showDetailCardHeadings: isCustomer,
        };
      }else if (txIsAuthenticatingTrackingConfirmed(tx)){
        return {
          headingState: HEADING_AUTHENTICATING_DELIVERING,
          showDetailCardHeadings: isCustomer,
        };
      }  else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txPaymentFailed(tx)){
        return {
          headingState: HEADING_PAYMENT_FAILED,
          showDetailCardHeadings: isCustomer,
        };
      }else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsSent(tx)) {
        return {
          headingState: HEADING_HAS_SENT,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenTrackingConfirmed(tx)) {
        return {
          headingState: HEADING_HAS_SENT,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsPendingPayout(tx)){
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsEnquired(tx)){
        return {
          headingState: HEADING_ENQUIRED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showBookingPanel: isCustomer && !isProviderBanned,
          showEnquiryInfoPanel : isProvider,
          showSendMessageForm: true
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const isPaymentOfferPending = isPaymentOfferPendingFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });
    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const isOwnListing = currentListing.author.id.uuid === currentUser.id.uuid;

    const lastTransition = currentTransaction.attributes ? currentTransaction.attributes.lastTransition : null;
  const canConfirmReceived = (lastTransition === 'transition/confirm-delivering'
    || lastTransition === 'transition/confirm-delivering-by-customer'
    || lastTransition === 'transition/confirm-delivering-by-operator') && isCustomer;
  const confirmReceiveBtn = canConfirmReceived ? (<PrimaryButton className={css.blackButton} onClick={confirmReceivedBtnAction} inProgress={acceptInProgress} disabled={acceptInProgress}>Confirmar mi pedido</PrimaryButton>) : null;


    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id, currentProvider, currentCustomer,listingTitle)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
        isSearching={isSearching}
        listingId={currentListing?.id?.uuid}
        currentProvider={currentProvider}
        currentCustomer={currentCustomer}
      />
    );

    const updateClabeSection = (
      <div className={css.actionButtons}>
      <div className={css.actionButtonWrapper}>
      <PrimaryButton className={css.blackButton} onClick={onOpenClabeModal}>
        Actualiza tu clabe
      </PrimaryButton>
      </div>
      </div>
    )

    let messagePlaceholder = stateData.showSendMessageForm ? "Escribe tu mensaje" : null;
    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const addressAlertSection = hasAddressError ?
      (<Modal
        id="UniqueIdForThisAlertInAddress"
        isOpen={true}
        onClose={onCloseAlert}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <p>{addressMessage}</p>
        {!isClientError ? (<PrimaryButton type="button" onClick={() => {
          window.location.href = '/account/profile-settings';
        }}>Configuración del perfil</PrimaryButton>) : null}
      </Modal>)
      : null;

    const hasListingState = !!currentListing.attributes.state;
    const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;
    const isAvailable = currentListing.attributes.publicData.available === 'Disponible'
    const showBookingPanelActions = !isOwnListing && hasListingState && !isClosed && !currentListing.attributes.publicData.sold && isAvailable;
    const formattedPrice = priceData(currentListing.attributes.price,intl)
    
    let mobileHeader = (
      <div className={css.mobileHeaderFlex}>
      <div className={css.mobileImage}>
      <NamedLink name="ListingPageCanonical" params={{id:currentListing.id && currentListing.id.uuid}}>
        <DetailCardImage
            rootClassName={css.imageWrapperMobile}
            avatarWrapperClassName={css.avatarWrapperMobile}
            listingTitle={listingTitle}
            image={firstImage}
            provider={currentProvider}
            isCustomer={false}
          />
        </NamedLink>
      </div>
        {/* <MobileCardListing/> */}
        <div className={css.mobileHeader}>
          <NamedLink name="ListingPageCanonical" params={{id:currentListing.id && currentListing.id.uuid}}>
            <p className={css.mobileTitle}><b>{listingTitle}</b></p>
          </NamedLink>
          <div className={stateData.showSendMessageForm ? css.mobileMoneyHeading: css.hidden}>{formattedPrice}</div>
        </div>
    </div>
    )

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {addressAlertSection}
            {/*validate if its message to show different headers*/}
            {mobileHeader}
            {isProvider && !stateData.showSendMessageForm ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              transaction={transaction}
            />
            {stateData.showSendMessageForm || isProvider
            ? null
            : <MessageSection
             listing={currentListing}
             onContactUser={onContactUser}
             isEnquiryModalOpen={isEnquiryModalOpen}
             onCloseEnquiryModal={onCloseEnquiryModal}
             sendEnquiryError={sendEnquiryError}
             sendEnquiryInProgress={sendEnquiryInProgress}
             onSubmitEnquiry={onSubmitEnquiry}
             currentUser={currentUser}
             onManageDisableScrolling={onManageDisableScrolling}
             authorDisplayName={authorDisplayName.props.user.attributes.profile.displayName}
             title={listingTitle}
             isOwnListing={isOwnListing}
            />
            }
            <ClabeSection
              listing={currentListing}
              isClabeModalOpen={isClabeModalOpen}
              onCloseClabeModal={onCloseClabeModal}
              updateClabeError={updateClabeError}
              updateClabeInProgress={updateClabeInProgress}
              onSubmitClabe={onSubmitClabe}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
              authorDisplayName={authorDisplayName.props.user.attributes.profile.displayName}
              title={listingTitle}
              isOwnListing={isOwnListing}
            />
            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} isPendingPaymentOffer={isPaymentOfferPending} />
            </div>
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              isCustomer={isCustomer}
              confirmBtnAction={confirmBtnAction}
              confirmReceivedBtnAction={confirmReceivedBtnAction}
              scrollToMessage={this.scrollToMessage}
              showBookingPanelActions={showBookingPanelActions}
            />
            {stateData.showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={showBookingPanelActions ? css.sendMessageForm : css.sendMessageFormAlt }
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
                messagePlaceholder={messagePlaceholder}
                inProgress={sendMessageInProgress}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onTouchStart={this.onSendMessageFormFocus}
              />
            ) : null }
            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
            {stateData.showUpdateClabeButton && (
              <div className={css.mobileActionButtons}>{updateClabeSection}</div>
            )}
            <div className={css.mobileActionButtons}>{confirmReceiveBtn}</div>
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  isClosed={isClosed}
                  showBookingPanelActions={showBookingPanelActions}
                  formClassName={css.openBookingForm}
                />
              ) : null}
              <div className={css.bookingHeading}>
              {!stateData.showBookingPanel &&
                <NamedLink name="ListingPageCanonical" params={{id:currentListing.id && currentListing.id.uuid}}>
                  <h2 className={css.bookingTitle}>{listingTitle}</h2>
                </NamedLink>
              }

                {stateData.showEnquiryInfoPanel ? <div className={css.bookingHelp}>{bookingSubTitle}</div> : null}
              </div>
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                isPendingPaymentOffer={isPaymentOfferPending}
              />

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showUpdateClabeButton && (
                <div className={css.desktopActionButtons}>{updateClabeSection}</div>
              )}
              <div className={css.desktopActionButtons}>{confirmReceiveBtn}</div>
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
  currentListing: null
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,

  confirmBtnAction: func,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
