import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconShareListing.css';

const ReloveIconShareListing = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Communication / Paper_Plane">
        <path id="Vector" d="M10.3076 13.6922L15.1538 8.84607M20.1111 5.88855L16.0205 19.1831C15.6538 20.3749 15.4705 20.9708 15.1543 21.1684C14.88 21.3398 14.5401 21.3679 14.2414 21.244C13.897 21.1011 13.6178 20.5434 13.0602 19.4281L10.4692 14.2462C10.3807 14.0692 10.3364 13.9807 10.2773 13.904C10.2249 13.836 10.1641 13.7749 10.0961 13.7225C10.0194 13.6634 9.93067 13.6191 9.75368 13.5306L4.57177 10.9397C3.45647 10.382 2.89876 10.1033 2.75585 9.75891C2.63191 9.46022 2.66018 9.11992 2.83154 8.84567C3.02912 8.52943 3.62508 8.34614 4.81689 7.97943L18.1115 3.88879C19.0484 3.6005 19.5168 3.45633 19.8332 3.57251C20.1089 3.67371 20.3264 3.89096 20.4276 4.16662C20.5438 4.48311 20.3994 4.95159 20.1111 5.88855Z" stroke="#FD7C67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>

  );
};

ReloveIconShareListing.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconShareListing.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconShareListing;
