import { CircularProgress, Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ListingCard } from '../../components';
import { propTypes } from '../../util/types';
import css from './SearchResultsPanel.css';

import { useMediaQuery } from 'react-responsive';

import ResizeObserver from 'rc-resize-observer';

import {InfiniteLoader, WindowScroller, AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';

const ITEMS_COUNT = 100

const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true
});

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    loadListingsHandler,
    searchInProgress,
    search,
    setActiveListing,
    activeListingId,
    handleLoginRequest,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  let listRef = React.useRef(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1921px)' })

  const itemsPerRow = isBigScreen ? 5 : isDesktopOrLaptop ? 4 : 2;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const scrollToListing = (listingId) => {
    if (!listingId) {
      return false;
    }
    const listing = document.getElementById(`ListingCard-${listingId}`);
    if (!listing) {
      return false;
    }
    listing.scrollIntoView(false);
    return true;
  }

  const loadFunc = (activeListingId = false) => {

    if(!searchInProgress) {
      let page = pagination ? pagination.page + 1 : 1;
      loadListingsHandler({ ...search, page, append: true }).then(() => {
        scrollToListing(activeListingId);
      });
    }
  }

  const hasMore = pagination ? pagination.page < pagination.totalPages : false;


  const isRowLoaded = ({ index }) => {
    return !hasMore || index < Math.floor(( listings.length)/itemsPerRow);
  }
  
  const rowRender = ({index, key, style, parent}) => {
    let page = pagination ? pagination.page + 1 : 1;

    const items = [];
    const fromIndex = index * itemsPerRow;
    const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT * page);


    for (let i = fromIndex; i < toIndex; i++) {

      const l = listings[i]
      
      if (!l)
        break
        
      items.push(
          <ListingCard
            id={l.id.uuid}
            setActiveListing={setActiveListing}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            handleLoginRequest={handleLoginRequest}
          />
        
      )
    }

    return <CellMeasurer
        cache={cache}
        key={key}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
        >
         {({measure}) => {
          return( 
            <div key={key}  style={style}>
              <ResizeObserver onResize={measure}>
                <div className={css.listingCards} >
                  {items}
                </div>
            </ResizeObserver>
          </div>
        )}}
      </CellMeasurer>
}

  const loader = (
    <Grid container key={'loader'} justifyContent={'center'} style={{marginTop: '10px'}}>
      <CircularProgress  className={css.loader} color={'inherit'}/>
    </Grid>
  );
  useEffect(() => {
    if (activeListingId && !scrollToListing(activeListingId)) {
      loadFunc(activeListingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeListingId ]);

  const rowCount = hasMore ? Math.floor(((listings.length) / itemsPerRow)) + 1 : Math.floor(( listings.length)/itemsPerRow);

  useEffect(() => {
    window.addEventListener("resize", () => updateRowHeight());

    return () => window.removeEventListener("resize", updateRowHeight);
  }, []);

  function updateRowHeight() {
    if (!listRef) {
      return;
    }
    
    // cache.clearAll();
    // listRef.current.recomputeRowHeights();
  }

  return (
    <div className={classes}>
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadFunc}
          rowCount={rowCount}
          threshold={2}
          itemsPerRow={itemsPerRow}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
            <WindowScroller
                scrollElement={window}>
                {({height, isScrolling, onChildScroll, scrollTop}) => (
                  <div className={classes.list}>
                      <AutoSizer disableHeight>
                          {({width}) => {
                            return (
                              <List
                                  autoHeight
                                  ref={(reference) => {
                                    registerChild(reference)
                                    listRef.current = reference
                                  }}
                                  height={height}
                                  isScrolling={isScrolling}
                                  onScroll={onChildScroll}
                                  overscanRowCount={itemsPerRow}
                                  rowCount={Math.floor((listings.length)/itemsPerRow) + 1}
                                  columnCount={itemsPerRow}
                                  columnWidth={300}
                                  rowHeight={cache.rowHeight}
                                  rowRenderer={rowRender}
                                  scrollTop={scrollTop}
                                  width={width}
                                  deferredMeasurementCache={cache}
                                  onRowsRendered={onRowsRendered}
                                  itemsPerRow={itemsPerRow}
                              />
                          )}}
                      </AutoSizer>
                  </div>
                )}
            </WindowScroller>
            );
          }}
        </InfiniteLoader>
        {searchInProgress ? loader : null}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  activeListingId: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  activeListingId: string,
};

export default SearchResultsPanel;
