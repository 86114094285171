/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import _ from 'lodash';
import { List, ListItem, Collapse, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { NamedLink } from '../../components';
import config from '../../config'

import css from './TopbarMobileQuicklinks.css';

const TopbarMobileQuicklinks = props => {
  const { quickLinksItems } = props

  const [opened, setOpened] = React.useState([]);
  const handleClick = (toOpen) => {
    setOpened(_.xor(opened, [toOpen]));
  };

  return (
    <div className={css.root}>
      <List>
      <NamedLink name="SearchPage" to={{ search: 'hotlist=true&featured=true&sort=-pub_updatedAt' }}>
        <ListItem button className={css.linkParent}>
            <span>Hot list</span>                
          </ListItem>
        </NamedLink>
        <NamedLink name="SearchPage" >
          <ListItem button className={css.linkParent}>
            <span>Lo más nuevo</span>                
          </ListItem>
        </NamedLink>
       {quickLinksItems.map(qRoot => {
        let children = [(
          <List key={'all'} component="div" disablePadding>
            <NamedLink className={css.nestedLink}name="SearchPage" to={{ search: 'pub_category=' + qRoot.key + '&track='+ qRoot.label }}>
            </NamedLink>
          </List>
        )];

        qRoot.options.forEach(opt => {
          if (opt.label === "Top marcas") {
            children.push(
              <List key={opt.key} component="div" disablePadding>
                <NamedLink
                  className={css.nestedLink}
                  name="SearchPage"
                  to={{
                    search:
                      'pub_category=' +
                      opt.key.slice(0, opt.key.indexOf(',')) +
                      '&pub_brand=' +
                      opt.key +
                      '&track=' +
                      opt.label,
                  }}
                >
                  <ListItem button className={css.nested}>
                    <span>{opt.label}</span>
                  </ListItem>
                </NamedLink>
              </List>
            );
          } else {
            children.push(
              <List key={opt.key} component="div" disablePadding>
                <NamedLink
                  className={css.nestedLink}
                  name="SearchPage"
                  to={{
                    search: 'pub_category=' + opt.key,
                  }}
                >
                  <ListItem button className={css.nested}>
                    <span>{opt.label}</span>
                  </ListItem>
                </NamedLink>
              </List>
            );
          }
        });
        
        let label = qRoot.label;

        return (
          <React.Fragment key={qRoot.key}>
            <ListItem button onClick={() => handleClick(label)}>
              <ListItemText primary={qRoot.label} className={css.linkParent} disableTypography/>
              {opened.includes(label) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={opened.includes(label)} timeout="auto" unmountOnExit>
              {children}
            </Collapse>
          </React.Fragment>
        );
       })}
      </List>
    </div>
  );
};

TopbarMobileQuicklinks.defaultProps = {
  quickLinksItems: config.quickLinks,
}

export default TopbarMobileQuicklinks;
