import { fetchCurrentUser, getCurrentUserFollowing, setFollowingData } from '../../ducks/user.duck';

import {
  fetchStripeAccount,
  
} from '../../ducks/stripeConnectAccount.duck';

import { createStripePayout, fetchReferredUsers, emailHandler, fetchPendingTransactions, fetchApiTransfers, fetchStatusForTransfer, calculateTransferPagination } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/BalancePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/BalancePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/BalancePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/BalancePage/SHOW_USER_ERROR';

export const STRIPE_DATA_REQUEST = 'app/BalancePage/STRIPE_DATA_REQUEST';
export const STRIPE_DATA_SUCCESS = 'app/BalancePage/STRIPE_DATA_SUCCESS';
export const STRIPE_DATA_ERROR = 'app/BalancePage/STRIPE_DATA_ERROR';

export const CREATE_PAYOUT_REQUEST = 'app/BalancePage/CREATE_PAYOUT_REQUEST';
export const CREATE_PAYOUT_SUCCESS = 'app/BalancePage/CREATE_PAYOUT_SUCCESS';
export const CREATE_PAYOUT_ERROR = 'app/BalancePage/CREATE_PAYOUT_ERROR';

export const UPDATE_COUNTER = 'app/BalancePage/UPDATE_COUNTER';

export const UPDATE_TOTAL_PENDING = 'app/BalancePage/UPDATE_TOTAL_PENDING';

export const UPDATE_TRANSFERS_ARRAY = 'app/BalancePage/UPDATE_TRANSFERS_ARRAY';

export const UPDATE_TRANFER_METADATA = 'app/BalancePage/UPDATE_TRANFER_METADATA';

export const UPDATE_TRANSFER_STATUS = 'app/BalancePage/UPDATE_TRANSFER_STATUS';



// ================ Reducer ================ //

const initialState = {
    userId: null,
    stripeAccountDataExternalAccount: null,
    stripeAccountError: null,
    initiatePayoutInProgess: false,
    initiatePayoutError: null,
    userCounter: null,
    totalPending : 0,
    transfers: [],
    totalPages: 1,
    paginationInfo: {}
  };

export default function balancePageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
      case SET_INITIAL_STATE:
        return { ...initialState };
  
      case SHOW_USER_REQUEST:
        return state;
      case SHOW_USER_SUCCESS:
        return { ...state, userShowError: null, userId: payload.userId };
      case SHOW_USER_ERROR:
        return { ...state, userShowError: payload };

      case STRIPE_DATA_REQUEST:
        return state;
      case STRIPE_DATA_SUCCESS:
        return { ...state, userShowError: null, stripeAccountDataExternalAccount: payload.stripeAccountDataExternalAccount };
      case STRIPE_DATA_ERROR:
        return { ...state, userShowError: payload };

      case CREATE_PAYOUT_REQUEST:
        return { ...state, initiatePayoutInProgess: true, initiatePayoutError: null };
      case CREATE_PAYOUT_SUCCESS:
        return { ...state, initiatePayoutInProgess: false };
      case CREATE_PAYOUT_ERROR:
        return { ...state, initiatePayoutInProgess: false, initiatePayoutError: payload };

      case UPDATE_COUNTER:
        return { ...state, userCounter: payload };

      case UPDATE_TOTAL_PENDING:
        return {...state, totalPending: payload}
      case UPDATE_TRANSFERS_ARRAY:
        return {...state, transfers: payload.data}
      case UPDATE_TRANFER_METADATA:
        return {...state, totalPages: payload.totalPages, paginationInfo: payload.paginationInfo,}

      case UPDATE_TRANSFER_STATUS: {
        const { id, status } = action.payload;
        
        // Update the transaction with the matching ID
        const updatedTransfers = state.transfers.map(transfer => {
          if (transfer.id === id) {
            return { ...transfer, status }; // Update the status of the matched transaction
          }
          return transfer;
        });
        
        // Return the updated state
        return { ...state, transfers: updatedTransfers };
      }
      default:
        return state;
    }
  }
// ================ Action creators ================ //

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
  });
  
  export const showUserRequest = userId => ({
    type: SHOW_USER_REQUEST,
    payload: { userId },
  });
  
  export const showUserSuccess = () => ({
    type: SHOW_USER_SUCCESS,
  });
  
  export const showUserError = e => ({
    type: SHOW_USER_ERROR,
    error: true,
    payload: e,
  });

  export const fetchStripeAccountRequest = () => ({
    type: STRIPE_DATA_REQUEST,
  });
  
  export const fetchStripeAccountSuccess = stripeAccountDataExternalAccount => ({
    type: STRIPE_DATA_SUCCESS,
    payload: {stripeAccountDataExternalAccount}
  });
  
  export const fetchStripeAccountError = e => ({
    type: STRIPE_DATA_ERROR,
    error: true,
    payload: e,
  });

  export const createPayoutRequest = () => ({ type: CREATE_PAYOUT_REQUEST })
  export const createPayoutSuccess = () => ({ type: CREATE_PAYOUT_SUCCESS })
  export const createPayoutError = e => ({ type: CREATE_PAYOUT_ERROR, error: true, payload: e})

  export const updateCounter = (userCounter) => ({ type: UPDATE_COUNTER, payload: userCounter })

  export const updatePendingTransactionAmount = (totalPending) => ({type: UPDATE_TOTAL_PENDING, payload: totalPending})
  
  export const updateTransfersArray = (transfers) => ({type: UPDATE_TRANSFERS_ARRAY, payload: transfers})

  export const updateTransfersMetadata = (metadata) => ({type: UPDATE_TRANFER_METADATA, payload: metadata})


  export const updateTransferStatus = ({ id, status }) => ({
    type: UPDATE_TRANSFER_STATUS,
    payload: { id, status },
  });

export const showUser = userId => (dispatch, getState, sdk) => {
    dispatch(showUserRequest(userId));
    return sdk.users
      .show({
        id: userId.uuid,
        include: ['profileImage', 'publicData'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
      .then(response => {
        dispatch(showUserSuccess());
        return response;
      })
      .catch(e => dispatch(showUserError(storableError(e))));
  };

export const handlePayout = (data) => (dispatch, getState, sdk) => {

  dispatch(createPayoutRequest())
  return createStripePayout({...data}).then(response =>{
    dispatch(fetchCurrentUser())

    dispatch(createPayoutSuccess())

    return emailHandler({...data, emailType: 'AccountPayout'}).then(() => {
      return response
    })
  }).catch(e => {
    dispatch(createPayoutError(e));
  })
};

export const loadData = () => (dispatch, getState, sdk) => {
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());
    return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccountRequest())
        dispatch(fetchStripeAccount())
          .then(() => {
            const stripeAccount = getState().stripeConnectAccount.stripeAccount;
            const stripeAccountDataExternalAccount = stripeAccount.attributes.stripeAccountData.external_accounts.data[0];
            dispatch(fetchStripeAccountSuccess(stripeAccountDataExternalAccount));
          })
      }
      return response;
    })
    .catch(e => {
      throw e;
    });
  };


export const loadTranferPage = (data) => (dispatch, getState, sdk) => {

  return fetchApiTransfers(data).then(fetchApiTransfersResponse => {
    if(fetchApiTransfersResponse){
      dispatch(updateTransfersArray(fetchApiTransfersResponse))
    }
  }).catch(error => {
    console.log(error)
  })
}

export const loadNonCriticalData = (data) => (dispatch, getState, sdk) => {

  const {
    userReferredCode,
    userId,
    stripeAccountId
  } = data;
  

  return fetchReferredUsers({userReferredCode}).then(fetchReferredUsersResponse => {
    dispatch(updateCounter(fetchReferredUsersResponse))
    return fetchPendingTransactions({userId}).then((fetchPendingTransactionsResponse) => {
      dispatch(updatePendingTransactionAmount(fetchPendingTransactionsResponse.totalPending))

      return calculateTransferPagination({stripeAccountId}).then(calculateTransferPaginationRes => {
        dispatch(updateTransfersMetadata(calculateTransferPaginationRes))
        dispatch(loadTranferPage({stripeAccountId, currentPage : 1, action: 'next', targetPage: 1}))
      })
    }).catch(error => {
      console.log(error)
    })
  }).catch(error => {
    console.log(error)
  })
}
  