import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconLock.css';

const ReloveIconProtection = props => {
  const { rootClassName, className } = props;
  const classes = classNames( css.root, className);
  return (
    <svg className={classes} width="24" height="26" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 14.8333V10.1666C3 9.2332 3 8.76655 3.16349 8.41003C3.3073 8.09643 3.5366 7.84144 3.81885 7.68166C4.13972 7.5 4.56007 7.5 5.40015 7.5H12.6001C13.4402 7.5 13.86 7.5 14.1808 7.68166C14.4631 7.84144 14.6929 8.09643 14.8367 8.41003C15.0002 8.76655 15 9.2332 15 10.1666V14.8333C15 15.7667 15.0002 16.2335 14.8367 16.59C14.6929 16.9036 14.4631 17.1585 14.1808 17.3183C13.86 17.5 13.4402 17.5 12.6001 17.5H5.40015C4.56007 17.5 4.13972 17.5 3.81885 17.3183C3.5366 17.1585 3.3073 16.9036 3.16349 16.59C3 16.2335 3 15.7667 3 14.8333ZM6.75 7.30769V5C6.75 3.61929 7.75736 2.5 9 2.5C10.2426 2.5 11.25 3.61929 11.25 5V7.30769C11.25 7.4139 11.1723 7.5 11.0767 7.5H6.92285C6.82726 7.5 6.75 7.4139 6.75 7.30769Z" stroke="#812039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    

  );
};

ReloveIconProtection.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconProtection.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconProtection;
