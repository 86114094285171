import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconListing.css';

const ReloveIconListing = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M15 4v7H5.17l-.59.59-.58.58V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z"/>
    </svg>
  );
};

ReloveIconListing.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconListing.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconListing;
