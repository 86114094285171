import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.css';
import { getParamsListing, logEvent } from '../../util/logsEvent';
import { useSelector } from 'react-redux';
import { getListingById } from '../../util/api';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    listingId,
    isSearching,
    currentCustomer,
  } = props;

  const handleAcceptSale = async () => {
    onAcceptSale();
    const listing = await getListingById({ idListing: listingId });
    const paramsListing = getParamsListing(listing)
 
    logEvent('offer accepted', {
      ...paramsListing,
      ['buyer id']: currentCustomer?.id?.uuid,
      ['buyer name']: currentCustomer?.attributes?.profile?.displayName
    });
  };

  const handleDeclineSale = async () => {
    onDeclineSale();
    const listing = await getListingById({ idListing: listingId });
    const paramsListing = getParamsListing(listing)
 
    logEvent('offer rejected', { ...paramsListing,
      ['buyer id']: currentCustomer?.id?.uuid,
      ['buyer name']: currentCustomer?.attributes?.profile?.displayName
    });
  };

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={handleDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptInProgress || isSearching}
          disabled={buttonsDisabled}
          onClick={handleAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
