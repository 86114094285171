import React from 'react'
// import { Button } from '@material-ui/core';
import {Modal, Button, ReloveIconComment} from '../../components';
import { EnquiryForm } from '../../forms';

import css from './TransactionPanel.css'


const MessageSection = props => {

  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    onManageDisableScrolling,
    isOwnListing
  } = props;

  const hanldeContactUser = () =>{
    let user = listing.author
    onContactUser(user);
  }
  return (
    <div  className={isOwnListing ? css.iconsHidden : css.icons}>
        <Button
        onClick={hanldeContactUser}
        rootClassName={css.messageButton}
        spinnerClassName={css.messageButtonSpinner}  
        // inProgress={true}
        > 
        <ReloveIconComment className={css.messageIcon} /><p className={css.messageIconText}>Chat</p></Button>
        <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  )
}

export default MessageSection