import React from 'react';
import countryCodePhone from './countryCodePhone.json';

const MEXICAN_LADA = '521';

export const codesPhone = () => {
  return countryCodePhone;
};

export const omitCodesPhone = codes => {
  return countryCodePhone.filter(codePhone => !codes.some(code => code === codePhone.iso));
};

export const getCodeOfPhoneNumber = (phoneNumber = '') => {
  if (!phoneNumber) return `+${MEXICAN_LADA}`;
  const countryData = countryCodePhone.find(codePhone =>
    phoneNumber.includes(`+${codePhone.code}`)
  );
  return `+${countryData ? countryData?.code : MEXICAN_LADA}`;
};

export const getNumberPhoneWithoutCode = (phoneNumber = '') => {
  if (!phoneNumber) return '';
  const countryData = countryCodePhone.find(codePhone =>
    phoneNumber.includes(`+${codePhone.code}`)
  );
  if (!countryData && phoneNumber.length > 10) {
    return phoneNumber.slice(3);
  }
  return phoneNumber.slice(countryData ? countryData.code.length + 1 : 0);
};
