import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse, stringify } from '../../util/urlHelpers';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Button,
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge, Modal, ReviewRating, Avatar, NamedLink as NamedLinkComponent,
} from '../../components'
import { TopbarContainer, NotFoundPage } from '../../containers';
import { followUser, queryUserFollowers, queryUserFollowing, queryUserListings, loadData } from './ProfilePage.duck'
import config from '../../config';

import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
} from '../SearchPage/SearchPage.helpers';

import ListingsPanel from './ListingsPanel';
import defaultCover from '../../assets/default-profile-banner.jpg';

import css from './ProfilePage.css';

const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 24;
const MAX_MOBILE_SCREEN_WIDTH = 768;

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
      isMobileModalOpen: false,
      isSeeingReviews: false,
      isSeeingFollowers: false,
      isSeeingFollowing: false,
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this)
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this)

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  render() {
    const {
      scrollingDisabled,
      params,
      history,
      user,
      currentUser,
      currentUserFollowing,
      currentUserFollowingInProgress,
      userShowError,
      pagination,
      queryListingsError,
      searchInProgress,
      appendInProgress,
      location,
      handleSearchListings,
      listings,
      searchParams,
      filterConfig,
      sortConfig,
      onManageDisableScrolling,
      intl,
      onFollowUser,
      followUserInProgress,
      followers,
      queryFollowersInProgress,
      queryFollowersError,
      following,
      queryFollowingInProgress,
      queryFollowingError,
      loadUserFollowers,
      loadUserFollowing,
      reviews,
      reviewsAvg,
      sales,
    } = this.props;

    const isAnother = (currentUser && user) ? currentUser.id.uuid !== user.id.uuid : false;

    const profileUser = ensureUser(user);
    const displayName = profileUser.attributes.profile.displayName;
    const isLoadingUser = !profileUser.attributes.profile.publicData;
    const { followersCount, followingCount, coverImage } = profileUser.attributes.profile.publicData || {};

    //Filtering stuff
    const { page, ...searchInURL } = parse(location.search);

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const coverLocation = coverImage && coverImage.location ? coverImage.location : defaultCover;

    /*
    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;
    */

    const handleFollow = () => {
      onFollowUser(currentUser, user, user);
    };

    const handleFollowUser = (userSelected) => {
      onFollowUser(currentUser, userSelected, user);
    };

    const currentFollow = user ? !!currentUserFollowing.find((f) => f.uuid === user.id.uuid) : false;

    const followBtn = isAnother ?
      (<div className={css.heading__content}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={false} md={5}/>
          <Grid item xs={12} md={2} >
            <Button onClick={handleFollow} inProgress={followUserInProgress || currentUserFollowingInProgress} className={css.followButton}>
              { currentFollow ? 'Siguiendo' : 'Seguir' }
            </Button>
          </Grid>
          <Grid item xs={false} md={5} />
        </Grid>
      </div>)
        : null;


    const followersContainer = followers.map((follower) => {
      const isOwnFollow = currentUserFollowing.find((own) => {
        return own.uuid === follower.follower;
      });
      const followBtnList = currentUser ? follower.user === currentUser.id.uuid : false;
      const followBtnAction = () => {
        handleFollowUser(follower.follower_profile)
      }

      const followerUser = follower.follower_profile;
      const ensuredFollower = ensureUser(followerUser);
      return (<div key={follower.follower}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={4} md={2}>
            <NamedLinkComponent className={css.followName} name="ProfilePage" params={{ id: ensuredFollower.id.uuid }} target="_blank">
              <Avatar user={ensuredFollower} disableProfileLink/>
            </NamedLinkComponent>
          </Grid>
          <Grid item xs={8} md={7}>
            <NamedLinkComponent className={css.followName} name="ProfilePage" params={{ id: ensuredFollower.id.uuid }} target="_blank">
              <p className={css.followName}>{ensuredFollower.attributes.profile.displayName}</p>
            </NamedLinkComponent>
          </Grid>
          <Grid item xs={4} md={3}>
            {followBtnList ?
              (<Button className={css.followBtnList} inProgress={followUserInProgress} onClick={followBtnAction}>{ !!isOwnFollow ? 'Siguiendo' : 'Seguir' }</Button>)
              : null}
          </Grid>
        </Grid>
      </div>);
    });

    const followingContainer = following.map((following) => {
      const isOwnFollow = currentUserFollowing.find((own) => {
        return own.uuid === following.user;
      });
      const followBtnList = currentUser ? following.follower === currentUser.id.uuid : false;
      const followBtnAction = () => {
        handleFollowUser(following.user_profile)
      };

      const followingUser = following.user_profile;
      const ensuredFollowing = ensureUser(followingUser);
      return (<div key={following.user}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={4} md={2}>
            <NamedLinkComponent className={css.followName} name="ProfilePage" params={{ id: following.user }} target="_blank">
              <Avatar user={ensuredFollowing} disableProfileLink/>
            </NamedLinkComponent>
          </Grid>
          <Grid item xs={4} md={7}>
            <NamedLinkComponent className={css.followName} name="ProfilePage" params={{ id: following.user }} target="_blank">
              <p className={css.followName}>{ensuredFollowing.attributes.profile.displayName}</p>
            </NamedLinkComponent>
          </Grid>
          <Grid item xs={4} md={3}>
            {followBtnList ?
              (<Button className={css.followBtnList} inProgress={followUserInProgress} onClick={followBtnAction}>{ !!isOwnFollow ? 'Siguiendo' : 'Seguir' }</Button>)
              : null}
          </Grid>
        </Grid>
      </div>);
    });

    

    const followersModal =
      (<Modal
        id="UniqueIdForThisFollowersAlert"
        isOpen={this.state.isSeeingFollowers}
        onClose={() => {
          this.setState({ isSeeingFollowers: false });
        }}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
      >
        <p className={css.modalTitle}>Seguidores</p>
        {queryFollowersInProgress ? (
          <Grid container justifyContent={'center'} style={{marginTop: '10px'}}>
            <CircularProgress  className={css.loader} color={'inherit'}/>
          </Grid>
        ) : queryFollowersError ? (
          <p className={css.modalError}>
            <FormattedMessage id="ProfilePage.loadingFollowersFailed" />
          </p>
        ) : followersContainer}
      </Modal>);

    const followingModal =
      (<Modal
        id="UniqueIdForThisFollowingAlert"
        isOpen={this.state.isSeeingFollowing}
        onClose={() => {
          this.setState({ isSeeingFollowing: false });
        }}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainer}
      >
        <p className={css.modalTitle}>Siguiendo</p>
        {queryFollowingInProgress ? (
          <Grid container justifyContent={'center'} style={{marginTop: '10px'}}>
            <CircularProgress  className={css.loader} color={'inherit'}/>
          </Grid>
        ) : queryFollowingError ? (
          <p className={css.modalError}>
            <FormattedMessage id="ProfilePage.loadingFollowingFailed" />
          </p>
        ) : followingContainer}
      </Modal>);

    const Review = props => {
      const { author, content, rating } = props;
      return (
        <div>
          <p className={css.reviewAuthor}>{author}</p>
          <p className={css.reviewContent}>{content}</p>
          {rating ? (
            <ReviewRating
              reviewStarClassName={css.reviewStar}
              className={css.reviewStars}
              rating={rating}
            />
          ) : null}
        </div>
      );
    };

    const reviewsContainer = reviews.map((review) => {
      return (<div>
        <Grid container justifyContent="left" spacing={2}>
          <Grid item xs={4} md={2}>
            <Avatar user={review.author} disableProfileLink/>
          </Grid>
          <Grid item xs={8} md={10}>
            <Review
              author={review.author.attributes.profile.displayName}
              content={review.attributes.content}
              rating={review.attributes.rating}
            />
          </Grid>
        </Grid>
      </div>);
    });

    const reviewsModal =
      (<Modal
        id="UniqueIdForThisReviewsAlert"
        isOpen={this.state.isSeeingReviews}
        onClose={() => {
          this.setState({ isSeeingReviews: false });
        }}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
        // className={css.modalContent}

        containerClassName={css.modalContainer}
      >
        <p>Reseñas ({reviews.length})</p>
        {reviewsContainer}
      </Modal>);

    const profileHeading = (
      <div className={css.heading}>
        <div className={css.heading__cover} style={{backgroundImage: `url(${!isLoadingUser ? coverLocation : null})`}}></div>
        <div className={css.heading__content}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={false} md={4}/>
            <Grid item xs={12} md={4} >
              <div className={css.heading__user}>
                <AvatarLarge className={css.heading__avatar} user={user} disableProfileLink />
                <h1 className={css.heading__name}>{displayName}</h1>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={css.heading__info}>
                <div className={classNames(css.infoItem, reviews.length ? css.infoItem__clickable : null)} onClick={() => {
                  if (reviews.length) {
                    this.setState({ isSeeingReviews: true })
                  }
                }}>
                  <p>{reviewsAvg ? reviewsAvg.toFixed(1) : 0}</p>
                  <p className={css.infoItem__label}>Reseñas</p>
                </div>
                <div className={classNames(css.infoItem, followersCount ? css.infoItem__clickable : null)} onClick={() => {
                  if (followersCount && user) {
                    loadUserFollowers(user.id.uuid);
                    this.setState({ isSeeingFollowers: true });
                  }
                }}>
                  <p>{ followersCount || '0' }</p>
                  <p className={css.infoItem__label}>Seguidores</p>
                </div>
                <div className={classNames(css.infoItem, followingCount ? css.infoItem__clickable : null)} onClick={() => {
                  if (followingCount && user) {
                    loadUserFollowing(user.id.uuid);
                    this.setState({ isSeeingFollowing: true })
                  }
                }}>
                  <p>{ followingCount || '0' }</p>
                  <p className={css.infoItem__label}>Siguiendo</p>
                </div>
                <div className={css.infoItem}>
                  <p>{sales}</p>
                  <p className={css.infoItem__label}>Ventas</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {followBtn}
      </div>
    );

    const loadMoreListings = (searchParams) => {
      let loadMoreParams = {
        ...searchParams,
        perPage: RESULT_PAGE_SIZE,
      };

      let userId = new UUID(params.id);

      return handleSearchListings(userId, loadMoreParams);
    };

    const mainContent = (
      <div>
        {reviewsModal}
        {followersModal}
        {followingModal}
        {profileHeading}
        <ListingsPanel
          urlQueryParams={validQueryParams}
          listings={listings}
          searchInProgress={searchInProgress}
          appendInProgress={appendInProgress}
          loadListingsHandler={loadMoreListings}
          searchListingsError={queryListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={this.onOpenMobileModal}
          onCloseModal={this.onCloseMobileModal}
          pagination={pagination}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MAX_MOBILE_SCREEN_WIDTH}
          history={history}
          userId={profileUser.id ? profileUser.id.uuid : '0'}
        />
      </div>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError || queryListingsError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>{content}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  isHandlingFollow: false,
  followers: [],
  following: [],
  sales: 0,
  reviewsAvg: 0,
};

const { array, bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isHandlingFollow: bool,
  followers: array,
  following: array,
  sales: number,
  reviewsAvg: number,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFollowUser: (currentUser, user, currentPageUser) => dispatch(followUser(currentUser, user, currentPageUser)),
  handleSearchListings: (userId, params) => dispatch(queryUserListings(userId, params)),
  loadUserFollowers: (userId) => dispatch(queryUserFollowers(userId)),
  loadUserFollowing: (userId) => dispatch(queryUserFollowing(userId)),
});

const mapStateToProps = state => {
  const { currentUser, currentUserFollowing, currentUserFollowingInProgress } = state.user;
  const {
    userId,
    pagination,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    searchParams,
    queryListingsInProgress,
    appendListingsInProgress,
    followUserInProgress,
    followers,
    queryFollowersInProgress,
    queryFollowersError,
    following,
    queryFollowingInProgress,
    queryFollowingError,
    reviewsAvg,
    sales,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserFollowing,
    currentUserFollowingInProgress,
    user,
    userShowError,
    pagination,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    searchParams,
    searchInProgress: queryListingsInProgress,
    appendInProgress: appendListingsInProgress,
    followUserInProgress,
    followers,
    queryFollowersInProgress,
    queryFollowersError,
    following,
    queryFollowingInProgress,
    queryFollowingError,
    reviewsAvg,
    sales,
  };
};

const ProfilePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = (params, queryParams) => {
  const {page = 1, ...rest} = parse(queryParams);
  const id = new UUID(params.id);
  return loadData({
    searchParams: {
      ...rest,
      perPage: RESULT_PAGE_SIZE,
      page,
    },
    userId: id,
  });
};

export default ProfilePage;
