import React from 'react'

const ReloveIconMail = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Mail">
<path id="Oval" d="M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 7.21573 23.2843 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5Z" fill="#0971BD"/>
<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M8 11.2346C8 11.2346 14.0372 14.6581 15.209 14.6581C16.4372 14.6581 22.409 11.3 22.409 11.3L22.4186 10.7C22.4186 10.0376 21.881 9.5 21.2168 9.5H9.2012C8.5376 9.5 8 10.0376 8 10.7V11.2346ZM22.409 13.1C22.409 13.1 16.4936 16.3081 15.209 16.3081C14.0372 16.3081 8.009 13.1 8.0096 13.1L8 20.3C8 20.9624 8.5382 21.5 9.2012 21.5H21.2168C21.881 21.5 22.4186 20.9624 22.4186 20.3L22.409 13.1Z" fill="white"/>
</g>
</svg>
  )
}

export default ReloveIconMail