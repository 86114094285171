import React from 'react'

import { InlineTextButton, PrimaryButton } from '../Button/Button'

import css from './AuthenticationPageModalWrapper.css';

const SectionNavigation = (props) => {

    const {
        setAuthPage,
        authPage
    } = props;


  return (
    <div className={css.tabs}>
        <InlineTextButton className={authPage === 'SignUp' ? css.tabSelected : css.tab} onClick={() => setAuthPage('SignUp')}>Crear Cuenta</InlineTextButton>
        <InlineTextButton className={authPage === 'Login' ? css.tabSelected : css.tab} onClick={() => setAuthPage('Login')}>Iniciar Sesión</InlineTextButton>
    </div>
  )
}

export default SectionNavigation