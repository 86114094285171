import { storableError } from '../../util/errors';
import { apiGetFullUser } from '../../util/api'
import { markNotificationsAsRead } from '../../ducks/user.duck'

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/NotificationsPage/SET_INITIAL_STATE';
export const QUERY_USER_REQUEST = 'app/NotificationsPage/QUERY_USER_REQUEST';
export const QUERY_USER_SUCCESS = 'app/NotificationsPage/QUERY_USER_SUCCESS';
export const QUERY_USER_ERROR = 'app/NotificationsPage/QUERY_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  notifications: [],
  queryUserInProgress: true,
};

export default function notificationsPageReducer(state = initialState, action = {}) {
	const { type, payload } = action;
  switch (type) {
  	case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_USER_REQUEST:
      return {
        ...state,
        notifications: [],
        queryUserInProgress: true,
      };
    case QUERY_USER_SUCCESS:
      return {
        ...state,
        notifications: payload,
        queryUserInProgress: false
      };
    case QUERY_USER_ERROR:
      return { ...state, notifications: [], queryUserInProgress: false };
  	default:
  		return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryUserRequest = () => ({
  type: QUERY_USER_REQUEST,
});

export const queryUserSuccess = notifications => ({
  type: QUERY_USER_SUCCESS,
  payload: notifications,
});

export const queryUserError = e => ({
  type: QUERY_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryFullUser = (user) => (dispatch, getState, sdk) => {
  dispatch(queryUserRequest());

  return apiGetFullUser({ user }).then(response => {
    if (response.status === 200) {
      if (response.data.data) {
        let privateData = response.data.data.attributes.profile.privateData;
        let notifications = privateData.notifications ? privateData.notifications : [];
        dispatch(queryUserSuccess(notifications));
      }
    }
  }).catch(e => dispatch(queryUserError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(markNotificationsAsRead()),
  ]);
};
