import classNames from 'classnames';
import { node } from 'prop-types';
import React from 'react';
import { IconClose } from '../../../components';
import config from '../../../config';
import css from './ReloveSelectedFilters.css';


function formatFilters (filters, config) {
  let formatted = [];

  const filterKeys  = Object.keys(filters);

  filterKeys.forEach(filter => {

    const values = filters[filter];

    const filterConfig = config.find(j => j.queryParamNames?.[0] === filter);
    const filterOptions = filterConfig?.config?.options;

    const split = values.split(',');

    let items = [];

    switch (filterConfig.type) {
      case 'ReloveCategoryFilter':
        items = split.map(value => {
          const cats = value.split('-');
          if(cats.length > 1) {
            const findCategory = filterOptions.find(option => option.key === cats[0])
            const findSubcategory = findCategory && findCategory.options ? findCategory.options.find(option => option.key === value) : null;

            return {
              key: value,
              param: filter,
              label: findSubcategory ? findSubcategory.label : value,
            };
          } else {
            const findLabel = filterOptions.find(i => i.key === value);
            return {
              key: value,
              param: filter,
              label: findLabel ? findLabel.label : value,
            };
          }
        });

        formatted.push(...items);
        break;

      case 'PriceFilter':
        const priceLabel = '$' + String(split[0]) + '-' + String(split[1]);
        const item = {
          key: values,
          param: filter,
          label: priceLabel,
        };

        formatted.push(item);

        break;

      case 'ReloveSizeFilter':
        const sizeShoesOptions = config.find(j => j.id === 'sizeShoes')?.config?.options;
        items = split.map(value => {
          const findLabel = filterOptions.find(i => i.key === value) || sizeShoesOptions.find(i => i.key === value);
          return {
            key: value,
            param: filter,
            label: findLabel ? findLabel.label : value,
          };

        });
        formatted.push(...items);

        break;

      default:
        items = split.map(value => {
          const findLabel = filterOptions.find(i => i.key === value);
          return {
            key: value,
            param: filter,
            label: findLabel ? findLabel.label : value,
          };

        });
        formatted.push(...items);
        break;
    }
  });

  return formatted;
}

const FilterItem = props => {
  const {
    filter,
    handleFilterRemove,
  } = props;
  return (
    <div className={css.filterItem}>
      <span>{filter.label}</span>
      <span onClick={() => handleFilterRemove(filter)}>
        <IconClose rootClassName={css.closeIcon} />
      </span>
    </div>
  );
}

const ReloveSelectedFiltersComponent = props => {
  const {
    rootClassName,
    className,
    selectedFilters,
    filterConfig,
    onChange,
  } = props;

  if (!Object.keys(selectedFilters).length) {
    return <div></div>;
  }

  const rootclasses = classNames(rootClassName || css.root, className);

  const formattedFilters = formatFilters(selectedFilters, filterConfig);

  const handleFilterRemove = filter => {
    let format = null;
    if (filter.param === 'price') {
      format = { [filter.param]: null };
    } else {
      const selectedArray = selectedFilters[filter.param].split(',');
      const hasOptionsSelected = selectedArray.length - 1 > 0;
      const withoutValue = selectedArray.filter(item => item !== filter.key);
      const value = hasOptionsSelected ? withoutValue.join(',') : null;
      format = { [filter.param]: value }
    }
    onChange(format);
  }

  return (
  <div>
        <div className={rootclasses}>
          {formattedFilters.map(filter => {
            return (<FilterItem filter={filter} key={filter.key} handleFilterRemove={handleFilterRemove}/>);
          })}
        </div>
    </div>
  );
};

ReloveSelectedFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectedFilters: {},
  filterConfig: config.custom.filters,
  sortByComponent: null,
};

ReloveSelectedFiltersComponent.propTypes = {
  sortByComponent: node
};

export default ReloveSelectedFiltersComponent;
