import React from 'react';
import { InlineTextButton, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import AddComment from './AddComment';
import Comment from './Comment';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './ListingPage.css';

function queryParamsToObject(url) {
  const urlObj = new URL(url);
  const queryParams = urlObj.searchParams;
  const queryParamsObject = {};

  for (const [key, value] of queryParams) {
    queryParamsObject[key] = value;
  }

  return queryParamsObject;
}

class CommentsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showReplyBox: false, replyTo: null };
  }

  componentDidMount() {
    const params = queryParamsToObject(window.location.href);
    const { commentId } = params;
    if (commentId) {
      this.setState({ showReplyBox: commentId, replyTo: commentId });
    }
  }

  render() {
    const {
      listingId,
      currentUser,
      comments,
      commentUsers,
      onCommentPosted,
      loadingCurrentUser,
      setAuthContainerOpen
    } = this.props;

    const { showReplyBox, replyTo } = this.state;

    if (loadingCurrentUser) {
      return null;
    }

    const addComment = !!currentUser ? (
      <AddComment
        listingId={listingId}
        currentUser={currentUser}
        onCommentPosted={onCommentPosted}
      />
    ) : (
      <p>
        Para agregar un comentario o hacer una compra,&nbsp;
        <InlineTextButton name="LoginPageButton" className={css.loginLink} onClick={() => {
            const { history, location } = this.props;
            const state = { from: `${location.pathname}${location.search}${location.hash}` };

            setAuthContainerOpen()
            // history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
          }}>
          <span className={css.login}>
            <FormattedMessage id="TopbarDesktop.login" />
          </span>
        </InlineTextButton>
      </p>
    );
    return (
      <div className={css.sectionComments}>
        <h3 className={css.titleSectionsComments}>Comentarios</h3>
        {comments.slice(0).map((comment, key) => {
          let replyBox =
            comment.id && showReplyBox === comment.id.uuid ? (
              <AddComment
                listingId={listingId}
                currentUser={currentUser}
                commentId={comment.id.uuid}
                replyTo={replyTo}
                autoFocus={true}
                onCommentPosted={e => {
                  this.setState({ showReplyBox: false, replyTo: null });
                  onCommentPosted(e);
                }}
              />
            ) : null;
          return (
            <div key={key}>
              <Comment
                comment={comment}
                showReplyButton={!!currentUser}
                commentUsers={commentUsers}
                onShowReplyBox={replyTo =>
                  this.setState({ showReplyBox: comment.id.uuid, replyTo: replyTo })
                }
              />
              <div className={css.comment__reply__box}>{replyBox}</div>
            </div>
          );
        })}
        {addComment}
      </div>
    );
  }
}

CommentsSection.defaultProps = {
  comments: [],
  commentUsers: [],
};

export default CommentsSection;
