import React from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../../util/urlHelpers';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../../util/types';
import { ensureOwnListing } from '../../../util/data';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../../ducks/UI.duck';
import {
  stripeAccountClearError,
  createStripeAccount,
  getStripeConnectAccountLink,
  saveClientClabeNumber
} from '../../../ducks/stripeConnectAccount.duck';

import { ReloveEditListingWizard, NamedRedirect, NamedLink, Page } from '../../../components';
import { TopbarContainer } from '../../../containers';

import {
  requestCreateListingDraft,
  requestPublishListingDraft,
  requestUpdateListing,
  requestImageUpload,
  updateImageOrder,
  removeListingImage,
  loadData,
  clearUpdatedTab,
  savePayoutDetails,
  updateProfile,
  clearPublishedListing,
} from './ReloveEditListingPage.duck';

import css from './ReloveEditListingPage.css';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

const { UUID } = sdkTypes;

// N.B. All the presentational content needs to be extracted to their own components
export const ReloveEditListingPageComponent = props => {
  const {
    createStripeAccountError, // From state.stripeConnectAccount
    currentUser, // From state.user
    fetchInProgress, // From state.stripeConnectAccount, is createStripeAccountInProgress
    fetchStripeAccountError, // From state.stripeConnectAccount
    getAccountLinkError, // From state.stripeConnectAccount
    getAccountLinkInProgress, // From state.stripeConnectAccount
    getOwnListing, // Helper function defined in mapStateToProps
    history, // From router
    intl, // From intl injection
    onChange, // From component dispatch (clearUpdatedTab()), sets the error to false
    onCreateListingDraft, // From component dispatch (requestCreateListingDraft(values)), creates draft
    onGetStripeConnectAccountLink, // From stripeConnectAccount dispatch (getStripeConnectAccountLink(params)), creates the link for stripe create account with it's redirects
    onImageUpload, // From component dispatch(requestImageUpload(data)), uploads image and returns temporary id
    onManageDisableScrolling, // Somehting from the UI duck, idk
    onPayoutDetailsFormChange, // Clears Stripe error when modifying payout details form
    onPayoutDetailsFormSubmit, // From stripeConnectAccount dispatch(createStripeAccount()), creates an stripe Account
    onPublishListingDraft, // From component dispatch(requestPublishListingDraft()), sets a listing draft's state to published
    onRemoveListingImage, // From component dispatch(removeListingImage(imageId)), removes an image from a listing
    onUpdateImageOrder, // From component dispatch(updateImageorder(imageOrder)), updates the images order
    onUpdateListing, // From component dispatch(requestUpdateListing(values))
    onUpdateProfile,
    page, // From mapStateToProps, current page state
    params, // From router, page params
    scrollingDisabled, // Something from the UI duck, idk
    stripeAccount, // From state.stripeConnectAccount, users account
    stripeAccountFetched, // From state.stripeConnectAccount, user has been fetched
    updateStripeAccountError, // From state.stripeConnectAccount, error when updating stripe account
    clearDataAfterPublish,
    onSaveClientClabeNumber
  } = props;

  const { id, type, returnURLType } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  let listingId = page.submittedListingId || (id ? new UUID(id) : null);


  const currentListing = ensureOwnListing(getOwnListing(listingId));
  const { state: currentListingState } = currentListing.attributes;
  if(currentListing?.id){
    listingId = currentListing.id
  }
  const hasListingPriceBeingDiscounted = currentListing?.attributes?.metadata?.hasListingPriceBeingDiscounted

  const isPastDraft = currentListingState && currentListingState !== LISTING_STATE_DRAFT;
  const shouldRedirect = isNewListingFlow && listingId && isPastDraft; // Should redirect when finished a draft

  if (currentListing.attributes && currentListing.attributes.publicData) {
    if (!!currentListing.attributes.publicData.sold) {
      window.location = '/';
    }
  }

  const hasStripeOnboardingDataIfNeeded = returnURLType ? !!(currentUser && currentUser.id) : true; // If redirected from stripe, data is fetched
  const showForm = hasStripeOnboardingDataIfNeeded && (isNewURI || currentListing.id);

  if (shouldRedirect) {
    const isPendingApproval =
      currentListing && currentListingState === LISTING_STATE_PENDING_APPROVAL;

    // If page has already listingId (after submit) and current listings exist
    // redirect to listing page
    const listingSlug = currentListing ? createSlug(currentListing.attributes.title) : null;

    // Clean state after publish to prevent redirect problem
    clearDataAfterPublish();

    const redirectProps = isPendingApproval
      ? {
          name: 'ListingPageVariant',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
          },
        }
      : {
          name: 'ListingPage',
          params: {
            id: listingId.uuid,
            slug: listingSlug,
          },
        };

    return <NamedRedirect {...redirectProps} />;
  } else if (showForm) {
    const {
      createListingDraftError = null,
      publishListingError = null,
      updateListingError = null,
      showListingsError = null,
      uploadImageError = null,
    } = page;
    const errors = {
      createListingDraftError,
      publishListingError,
      updateListingError,
      showListingsError,
      uploadImageError,
      createStripeAccountError,
    };
    // TODO: is this dead code? (shouldRedirect is checked before)
    const newListingPublished =
      isDraftURI && currentListing && currentListingState !== LISTING_STATE_DRAFT;

    // Show form if user is posting a new listing or editing existing one
    const disableForm = page.redirectToListing && !showListingsError;

    // Images are passed to EditListingForm so that it can generate thumbnails out of them
    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];


    // Set imageOrder(array of strings)
    let imageOrder = page.imageOrder || [];

    if(imageOrder.length === 0 && currentListingImages.length > 0){
      imageOrder = currentListingImages.map(i => i.id.uuid);
    }
    else if(!currentListingImages.every(id => imageOrder.includes(id.id.uuid))){
      // Not all ids from currentListingImages are in imageOrder.
      // This means that we cant simply use page.imageOrder for the imageOrder.
      // Combine currentListingImages with imageOrder and remove duplicates.
      imageOrder = currentListingImages.map(i => i.id.uuid).concat(imageOrder);
      imageOrder = imageOrder.filter((id, index) => {
        return imageOrder.indexOf(id) === index;
      });
    }

    const getImage = (id) => {
      let image = {};
      // Search the image with the given id in currentListingImages(array of objects).
      for (let i = 0; i < currentListingImages.length; i++) {
        if(currentListingImages[i].id.uuid === id){
          image = currentListingImages[i];
          break;
        }
      }
      // Return the image if it was found.
      if(JSON.stringify(image) !== '{}')
        return image;
      else{
        // Search the image with the given id in page.images(object).
        if(page.images.hasOwnProperty(id))
          image = page.images[id];
        // Return the image if it was found.
        if(JSON.stringify(image) !== '{}')
          return image;
      }
    }

    const allImages = imageOrder.map(i => getImage(i)).filter(img => {return img != null});

    const removedImageIds = page.removedImageIds || [];    

    const images = allImages.filter(img => {
    return !removedImageIds.includes(img.id);
    });

    const title = isNewListingFlow
      ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
      : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });

    const MyListingsLink = (
        <NamedLink name="ManageListingsPage" className={css.helpSubtitleLink}>
          <FormattedMessage id="EditListingPage.helpSubtitleLink" />
        </NamedLink>
      );

    const handleOnSaveClientClabeNumber = (values) => {
      onSaveClientClabeNumber({...values, id: currentUser.id.uuid})
    }

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.titleWrapper}>
          <h1 className={css.pageTitle} >{title}</h1>
          <p className={css.helpSubtitle}><FormattedMessage id="EditListingPage.helpSubtitle" values={{MyListingsLink}} /></p>
        </div>
        <ReloveEditListingWizard
          id="EditListingWizard"
          className={css.wizard}
          params={params}
          disabled={disableForm}
          errors={errors}
          fetchInProgress={fetchInProgress}
          newListingPublished={newListingPublished || page.updateSuccess}
          history={history}
          images={images}
          listing={currentListing}
          onUpdateListing={onUpdateListing}
          onCreateListingDraft={onCreateListingDraft}
          onPublishListingDraft={onPublishListingDraft}
          onPayoutDetailsFormChange={onPayoutDetailsFormChange}
          onPayoutDetailsSubmit={onPayoutDetailsFormSubmit}
          onGetStripeConnectAccountLink={onGetStripeConnectAccountLink}
          getAccountLinkInProgress={getAccountLinkInProgress}
          onImageUpload={onImageUpload}
          onUpdateImageOrder={onUpdateImageOrder}
          imageOrder={imageOrder}
          onRemoveImage={onRemoveListingImage}
          onChange={onChange}
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
          stripeOnboardingReturnURL={params.returnURLType}
          updateInProgress={page.updateInProgress || page.createListingDraftInProgress}
          payoutDetailsSaveInProgress={page.payoutDetailsSaveInProgress}
          payoutDetailsSaved={page.payoutDetailsSaved}
          stripeAccountFetched={stripeAccountFetched}
          stripeAccount={stripeAccount}
          stripeAccountError={
            createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
          }
          stripeAccountLinkError={getAccountLinkError}
          onUpdateProfile={onUpdateProfile}
          updateProfileInProgress={page.updateProfileInProgress}
          updateProfileError={page.updateProfileError}
          hasListingPriceBeingDiscounted={hasListingPriceBeingDiscounted}
          onSaveClientClabeNumber={handleOnSaveClientClabeNumber}
        />
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditListingPage.loadingListingData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled} />
    );
  }
};

ReloveEditListingPageComponent.defaultProps = {
  createStripeAccountError: null,
  fetchStripeAccountError: null,
  getAccountLinkError: null,
  getAccountLinkInProgress: null,
  stripeAccountFetched: null,
  currentUser: null,
  stripeAccount: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
};

ReloveEditListingPageComponent.propTypes = {
  createStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  getAccountLinkError: propTypes.error,
  getAccountLinkInProgress: bool,
  updateStripeAccountError: propTypes.error,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  getOwnListing: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onPublishListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsFormSubmit: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveListingImage: func.isRequired,
  onUpdateListing: func.isRequired,
  onChange: func.isRequired,
  page: object.isRequired,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }).isRequired,
  stripeAccountFetched: bool,
  stripeAccount: object,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.ReloveEditListingPage;
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;

  const { currentUser } = state.user;

  const fetchInProgress = createStripeAccountInProgress;

  const getOwnListing = id => {
    const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);

    return listings.length === 1 ? listings[0] : null;
  };
  return {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    currentUser,
    fetchInProgress,
    getOwnListing,
    page,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: values => dispatch(requestUpdateListing(values)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onPayoutDetailsFormChange: () => dispatch(stripeAccountClearError()),
  onPayoutDetailsSubmit: values => dispatch(createStripeAccount(values)),
  onPayoutDetailsFormSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  onSaveClientClabeNumber: params => dispatch(saveClientClabeNumber(params)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveListingImage: imageId => dispatch(removeListingImage(imageId)),
  onChange: () => dispatch(clearUpdatedTab()),
  clearDataAfterPublish: () => dispatch(clearPublishedListing())
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ReloveEditListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(ReloveEditListingPageComponent));

ReloveEditListingPage.loadData = loadData;

export default ReloveEditListingPage;
