import PropTypes from 'prop-types';
import React from 'react';

const IconKeys = props => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="110" height="64" fill="none" viewBox="35 10 64 80">
      <path fill="url(#paint0_linear)" d="M93.47 37.781c-3.718-9.461-15.172-15.135-24.873-11.38-11.074 4.285-7.402 21.899-17.46 25.46-5.434 1.925-13.467-5.262-18.956-6.364-10.116-2.031-19.273 5.106-21.528 14.99-2.3 10.084 1.39 23.553 12.468 26.29 13.198 3.26 20.072-8.604 31.143-12.01 11.361-3.496 23.994 4.76 33.4-5.285 6.948-7.421 9.488-22.332 5.806-31.701z"/>
      <path fill="#fff" fillRule="evenodd" d="M46 47.46c3.858 2.206 8.635 2.224 12.52.076l2.48 2.18v3.428h3.462l1.153 1.2v3.37h3.04L71.105 60H76v-4.847L63.414 42.689c2.7-4.783 2.005-10.942-2.1-15.007-2.894-2.866-6.836-4.059-10.604-3.579" clipRule="evenodd"/>
      <path fill="#fff" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M46 47.46c3.858 2.206 8.635 2.224 12.52.076l2.48 2.18v3.428h3.462l1.153 1.2v3.37h3.04L71.105 60H76v-4.847L63.414 42.689c2.7-4.783 2.005-10.942-2.1-15.007-2.894-2.866-6.836-4.059-10.604-3.579"/>
      <path fill="#fff" fillRule="evenodd" d="M43 29V18.615C43 16.068 45.016 14 47.5 14s4.5 2.068 4.5 4.615v5.197" clipRule="evenodd"/>
      <path fill="#fff" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M43 29V18.615C43 16.068 45.016 14 47.5 14s4.5 2.068 4.5 4.615v5.197"/>
      <path fill="#fff" fillRule="evenodd" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M55 33.65C55 26.663 49.402 21 42.5 21S30 26.663 30 33.65c0 5.79 3.848 10.658 9.09 12.16v2.79l-2.272 2.298 2.273 2.302v2.3l-2.273 2.3 2.273 2.3v3.45L42.5 67l3.41-3.45V45.81C51.151 44.308 55 39.44 55 33.65z" clipRule="evenodd"/>
      <path fill="#fff" fillRule="evenodd" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M46 29.5c0 1.932-1.568 3.5-3.5 3.5S39 31.432 39 29.5s1.568-3.5 3.5-3.5 3.5 1.568 3.5 3.5v0z" clipRule="evenodd"/>
      <defs>
        <linearGradient id="paint0_linear" x1="81.905" x2="42.986" y1="95.836" y2="44.278" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C6D1FF"/>
          <stop offset=".583" stopColor="#E6D5F4"/>
          <stop offset=".997" stopColor="#FFD9EC"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

IconKeys.defaultProps = { className: null };

const { string } = PropTypes;

IconKeys.propTypes = {
  className: string,
};

export default IconKeys;
