import React, { useEffect, useState } from 'react';
import css from './SectionProtection.css'
import { apiGetTransactions } from '../../util/api';
import { Avatar, NamedLink } from '../../components';

import * as log from '../../util/log';

const SectionDetailUser = ({ user, reviewsAvg, scope }) => {
    const [transactions, setTransactions] = useState(0);
    useEffect(() => {
        if (user?.id?.uuid) {
            const fetchTransaction = async () => {
                try {
                    const countTransactions = await apiGetTransactions({ user: user.id.uuid });
                    setTransactions(countTransactions.transactions);
                } catch (error) {
                    log.error(error, 'get-transactions-error', { id: user?.id?.uuid }, 'listing');
                }
            };
            fetchTransaction();
        }
    }, [user?.id?.uuid]);

    return (
        <div className={css.containerSectionDetailUser}>
            <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
                <div className={css.containerAvatar} >
                    <Avatar user={user} className={css.avatarDesktop} disableProfileLink />
                    <div>
                        <label>{user?.attributes?.profile?.displayName}</label>
                        <span>{transactions} ventas</span>
                    </div>
                </div>
            </NamedLink>
            <div className={css.containerText}>
                <span className={css.containerText_span}>{reviewsAvg ? reviewsAvg.toFixed(1) : 0}</span>
                <label className={css.containerText_label}>Reseñas</label>
            </div>
            <div className={css.containerText}>
                <span className={css.containerText_span}>{user?.attributes?.profile?.publicData?.followersCount ? user?.attributes?.profile?.publicData?.followersCount : 0}</span>
                <label className={css.containerText_label}>Seguidores</label>
            </div>
        </div>
    )
}

export default SectionDetailUser