import { CircularProgress, Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ListingCard } from '../../components';
import { propTypes } from '../../util/types';
import css from './SearchResultsPanel.css';

const SearchResultsPanelProfile = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    loadListingsHandler,
    searchInProgress,
    search,
    setActiveListing,
    activeListingId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const scrollToListing = (listingId) => {
    if (!listingId) {
      return false;
    }
    const listing = document.getElementById(`ListingCard-${listingId}`);
    if (!listing) {
      return false;
    }
    listing.scrollIntoView(false);
    return true;
  }

  const loadFunc = (activeListingId = false) => {
    if(!searchInProgress) {
      let page = pagination ? pagination.page + 1 : 1;
      loadListingsHandler({ ...search, page, append: true }).then(() => {
        scrollToListing(activeListingId);
      });
    }
  }

  const hasMore = pagination ? pagination.page < pagination.totalPages : false;

  const loader = (
    <Grid container key={'loader'} justifyContent={'center'} style={{marginTop: '10px'}}>
      <CircularProgress  className={css.loader} color={'inherit'}/>
    </Grid>
  );
  useEffect(() => {
    if (activeListingId && !scrollToListing(activeListingId)) {
      loadFunc(activeListingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeListingId ]);

  return (
    <div className={classes}>
      <InfiniteScroll
        loadMore={loadFunc}
        initialLoad={false}
        hasMore={hasMore}
        loader={loader}
      >
        <div className={css.listingCards}>
          {listings.map(l => (
            <ListingCard
              id={l.id.uuid}
              setActiveListing={setActiveListing}
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
            />
          ))}
          {props.children}
        </div>
      </InfiniteScroll>
    </div>
  );
};

SearchResultsPanelProfile.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  activeListingId: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanelProfile.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  activeListingId: string,
};

export default SearchResultsPanelProfile;