import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Grid } from '@material-ui/core';
import config from '../../config';

import { NamedLink } from '../../components';

import css from './SectionPopularBrands.css';

const BrandLink = ({ item }) => {
  const {name, logo, searchQuery} = item;
  return (
    <Grid item xs={4} sm={6}>
      <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.brandItem} title={name}>
        <div className={css.brandBox} style={{backgroundImage: `url(${logo})`}}></div>
      </NamedLink>
    </Grid>
  );
};

const SectionPopularBrands = props => {
  const { rootClassName, className, brands, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  const slicedBrands = isMobileLayout ? brands.slice(0, 6) : brands;

  return (
    <div className={classes}>
      <h3 className={css.title}><FormattedMessage id="SectionPopularBrands.title" /></h3>
      <Grid container spacing={1} className={css.brands}>
        {slicedBrands.map(item => (
          <BrandLink item={item} key={item.id} />
        ))}
      </Grid>
    </div>
  );
};

SectionPopularBrands.defaultProps = {
  rootClassName: null,
  className: null,
  brands: config.landingBrands,
  isMobileLayout: false,
};

const { string } = PropTypes;

SectionPopularBrands.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPopularBrands;
