import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { saveEmailBD } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';
export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const SEND_EMAIL_REQUEST = 'app/LandingPage/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'app/LandingPage/SEND_EMAIL_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';


// ================ Reducer ================ //

const initialState = {
  featuredListingsIds: [],
  queryListingsError: null,
  queryListingsInProgress: false,
};

export const sendEmailRequest = option => ({
  type: SEND_EMAIL_REQUEST,
  payload: option,
});

export const sendEmailSuccess = wasSave => ({
  type: SEND_EMAIL_SUCCESS,
  payload: wasSave,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

const resultIds = data => data.data.map(l => l.id);

export default function landingPageReducer(state = initialState, action = {}) {
	const { type, payload } = action;
  switch (type) {
  	case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        featuredListingsIds: state.featuredListingsIds,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { 
        ...state,
        featuredListingsIds: resultIds(payload.listings.data),
        queryListingsInProgress: false
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, featuredListingsIds: [], queryListingsError: payload, queryListingsInProgress: false };
  	default:
  		return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryFeaturedListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const params = {
  	'meta_featured': true,
    per_page: 100,
    include: ['author', 'images', 'createdAt', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    'fields.listing': ['title', 'price', 'publicData', 'metadata'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const sendEmail = (email) => (dispatch, getState, sdk) => {
  dispatch(sendEmailRequest(true));
  return saveEmailBD({ email }).then((response) => {
  dispatch(sendEmailSuccess(response.wasSave));
    dispatch(sendEmailRequest(false));
    return response;
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(sendEmailRequest(false));
  });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(queryFeaturedListings()),
  ]);
};