import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#dc8fce',
    fontStyle: 'CircularStd',
  },
  letter:{
    color: '#FFFFFF',
  }
}));

const ShippingBanner = props => {
  const {message, link} = props;
  const classes = useStyles();
  return (
      <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}> 

              <a class href={`${link}`}> 
                <div className={classes.letter}>{message}</div>
              </a>
          </Paper> 
        </Grid>

      </Grid>
    </div>

  );
};



export default ShippingBanner;
