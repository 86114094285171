import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
// import Button from '@material-ui/core/Button'
import {Button} from '../../components'
import getCroppedImg from './CropImage'
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import CropImageButton from './CropImageButton'

import css from './ImageFromFile.css';

const muiTheme = createMuiTheme({
	overrides:{
	  MuiSlider: {
		thumb:{
		color: "gray",
		},
		track: {
		  color: '#F4331A'
		},
		rail: {
		  color: 'gray'
		}
	  }
  }
  });
  
const ImageCropper = props => {

	const {onCropCompleted, sourceImage, showCropper} = props;

	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const [inProgress, setInProgresss] = useState(false)


	const [show, setShow] = useState(showCropper);

	const handleClose = () => setShow(false);
	const handleShow = e => {
		e.preventDefault();
		setShow(true);
	}

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
		
	}, [])

	const showCroppedImage = useCallback(async () => {
		setInProgresss(true)
		try {
		const croppedImage = await getCroppedImg(
			sourceImage,
			croppedAreaPixels,
		)
		onCropCompleted(croppedImage)
		setInProgresss(false)

		} catch (e) {
		console.error(e)
		}
	}, [croppedAreaPixels, onCropCompleted, sourceImage])

	return (
		<>
		<CropImageButton variant="primary" onClick={handleShow} className={css.showCropperButton}/>
		<Modal
		  show={show}
		  onHide={handleClose}
		  backdrop="static"
		  keyboard={false}
		  contentClassName={css.modalBody}
		>
		  <Modal.Body>
			<div className={css.modalFlexContainer}>
				<button 
					className={css.closeButton} 
					onClick={handleClose}
				><CloseIcon/></button>
				<>
					<div className={css.cropContainer}>
						<Cropper
						image={sourceImage}
						crop={crop}
						zoom={zoom}
						aspect={1 / 1}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						/>
					</div>
					<div className={css.controls}>
						<div className={css.sliderContainer}>
						<div
							variant="overline"
							className={css.sliderLabel}
						>
							Zoom
						</div>
						<ThemeProvider theme={muiTheme}>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								css={{ root: css.slider }}
								onChange={(e, zoom) => setZoom(zoom)}
							/>
						</ThemeProvider>
						</div>
						<Button
						onClick={showCroppedImage}
						color="primary"
						className={css.cropButton}
						rootClassName={css.cropButton}
						disabled={inProgress}
						>
						Guardar
						</Button>
					</div>
					{/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
				</>
			</div>
			</Modal.Body>
			</Modal>
		</>
	  )
}

ImageCropper.propTypes = {
	initialZoom: PropTypes.number,
	initialRotate: PropTypes.number,
	mime: PropTypes.string,
	quality: PropTypes.number,
	file: PropTypes.object,
	labels: PropTypes.shape({
		heading: PropTypes.string,
		confirm: PropTypes.string,
		discard: PropTypes.string,
		zoom: PropTypes.string,
		rotate: PropTypes.string,
	}),
	cropperProps: PropTypes.object,
	modalProps: PropTypes.object,
	croppedCanvasProps: PropTypes.object,
	onDiscard: PropTypes.func,
	onCompleted: PropTypes.func,
}

ImageCropper.defaultProps = {
	initialZoom: 0,
	initialRotate: 0,
	mime: null,
	quality: 70,
	labels: {
		heading: 'Crop Image',
		confirm: 'Confirm',
		discard: 'Discard',
		zoom: 'Zoom',
		rotate: 'Rotate',
	},
	modalProps: {},
	cropperProps: {},
	croppedCanvasProps: {},
	onDiscard: () => {},
	onCompleted: () => {},
}

export default ImageCropper