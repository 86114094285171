import React from 'react';
import css from './ListingPage.css';
import { Button, ReloveIconFavoriteLike } from '../../components';
import classnames from 'classnames'

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    originalPriceTitle,
    formattedOriginalPrice,
    richTitle,
    priceShipping,
    onClickLike,
    isLikedByCurrentUser,
    likedBy
  } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.containerTitleHeading}>
        <h1 className={css.title}>{richTitle}</h1>
        <Button
          onClick={onClickLike}
          rootClassName={css.buttonSocialButtonIconLike}
        >
          <ReloveIconFavoriteLike className={classnames(css.socialButtonIconLike, { [css.socialButtonIconLikeActive]: isLikedByCurrentUser })} isFull={isLikedByCurrentUser} />
          <span>{likedBy?.length}</span>
        </Button>
      </div>
      <div className={css.prices}>
        <div className={css.productPrice} title={priceTitle}>{formattedPrice}</div>
        <div className={css.originalPrice} title={originalPriceTitle}>{formattedOriginalPrice}</div>
      </div>
      {/*<div className={css.shippingPrice}>{priceShipping}</div>  */}
    </div>
  );
};

export default SectionHeading;
