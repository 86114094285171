import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './WhatsAppChat.css';
import classNames from 'classnames';

import { AmplitudeAnalytics, logEvent } from '../../util/logsEvent';

const WhatsAppChat = ({ phoneNumber, message, componentTextShown, showCloseButton, onClose, showText }) => {
  const encodedMessage = encodeURIComponent(message);

  const classes = classNames({
    [styles.whatsappChatContainer]: componentTextShown,
    [styles.whatsappChatContainerBottom]: !componentTextShown,
  });

  const handleClick = (event) => {
    logEvent('WhatsApp Clicked', {});
  };

  return (
    <div className={classes}>
      <div className={styles.iconContainer}>
        <a
          href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.whatsappLink}
          draggable="false"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} draggable="false" />
        </a>
        <div className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

export default WhatsAppChat;
