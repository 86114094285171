import React from 'react'

import { FieldRadioButton } from '..';

import { Form as FinalForm } from 'react-final-form';

import css from './ListingQualityAssurance.css';


const ListingQualityAssurance = (props) => {

    return(
        <FinalForm
            {...props}
            render={fieldRenderProps => {
            const {
                isListingQualityAssuranceBeingChanged,
                isListingQualityAssuranceSelected,
                onListingQualityAssuranceChange,
                handleSubmit,
                freeListingQualityAssurance
            } = fieldRenderProps;
        
            // const enquirySubmission = (values) =>{
            //     setIsExpressDeliveryChosen(false);
            //     onSubmitEnquiry(values)
            // }
        
            // const chooseShippingOption = (option, price) => {
            //     onShippingPriceChange(option, price);
            //     onNewShippingMethodSelection(option)
            // }

            return (
                <>
                    <h1 className={css.title}>Autenticación</h1>
                    <FieldRadioButton
                        id={`ListingQualityAssuranceSelected`}
                        name="ListingQualityAssuranceSelected"
                        key={`ListingQualityAssuranceSelected`}
                        onClick={() => {
                        // chooseShippingOption("normalDelivery", originalShippingPrice);
                        // setIsExpressDeliveryChosen(false);
                            onListingQualityAssuranceChange(true)
                        }}
                        disabled={false}
                        label={<div>Autenticar mi compra ({freeListingQualityAssurance ? 'GRATIS' : '$250 MXN'}  y 3-5 días hábiles adicionales)</div>}
                        value={`ListingQualityAssurance`}
                        checked={isListingQualityAssuranceSelected}
                    />
                        <FieldRadioButton
                        id={`ListingQualityAssuranceRejected`}
                        name="ListingQualityAssuranceRejected"
                        key={`ListingQualityAssuranceRejected`}
                        onClick={() => {
                        // chooseShippingOption("normalDelivery", originalShippingPrice);
                        // setIsExpressDeliveryChosen(false);
                            onListingQualityAssuranceChange(false)
                        }}
                        disabled={false}
                        label={<div>No autenticar mi compra</div>}
                        value={`ListingQualityAssurance`}
                        checked={!isListingQualityAssuranceSelected}
                    />
                    {/* <button onSubmit={() => {return}}/> */}
                </>
                );
            }}
        />
    )
};

export default ListingQualityAssurance