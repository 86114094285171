import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Promised } from '../../components';

import ImageCropper from './ImageCropper';
import css from './ImageFromFile.css';

// readImage returns a promise which is resolved
// when FileReader has loaded given file as dataURL
const readImage = file =>
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.onerror = e => {
      // eslint-disable-next-line
      reject(new Error(`Error reading ${file.name}: ${e.target.result}`));
    };
    reader.readAsDataURL(file);
  });

// Create elements out of given thumbnail file
class ListingImageFromFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promisedImage: readImage(this.props.file),
      originalPromisedImage: this.props.originalFile ? readImage(this.props.originalFile) : null,
      image: null,
      cropComplete:false
    };
  }

  onCropCompleted = async (promisedImage) => {
    this.setState({promisedImage, cropComplete:true})
    let response = await fetch(promisedImage);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    let file = new File([data], this.props.image.file.name, metadata);
    const originaFile = this.props.originalFile ? this.props.originalFile :  this.props.file
    this.props.handleCropComplete(file, originaFile)
    const newImageOrder = this.props.imageOrder;
    const replace = this.props.imageOrder.length-1;
    const initial = newImageOrder.indexOf(this.props.id);
    const aux = newImageOrder[initial];
    newImageOrder[initial] = newImageOrder[replace];
    newImageOrder[replace] = aux;
    this.props.onUpdateImageOrder(newImageOrder)
    this.props.handleRemoveClick()
  }

  
  render() {
    const { className, rootClassName, aspectRatioClassName, file, id, children, handleDraggedOver, handleDragged, handleDrop, originalFile} = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const aspectRatioClasses = aspectRatioClassName || css.aspectWrapper;
    const imageId = this.props.image.imageId ? this.props.image.imageId.uuid : "";
    return (
      originalFile ? (
        <Promised
        key={id}
        promise={this.state.originalPromisedImage}
        renderFulfilled={originalDataURL => {
          return (
            <Promised
            key={id}
            promise={this.state.promisedImage}
            renderFulfilled={dataURL => {
              return (
                <div className={classes} id={id} draggable={true} onDragOver={handleDraggedOver} onDrag={handleDragged} onDrop={handleDrop} onMouseDown={handleDragged} onMouseMove={handleDraggedOver} onMouseUp={handleDrop}>
                  <div className={css.threeToTwoWrapper}>
                    <div className={aspectRatioClasses}>
                      <img className={css.imageSrc} src={dataURL} alt={file.name} ref={imageRef => (this.image = imageRef)} id={"source"+imageId} />
                      <div className={css.hover} ><img alt="drag" className={css.image} src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDE2IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz4KPHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMDAwMDAwIiBkPSJNMTYgOGwtMy0zdjJoLTR2LTRoMmwtMy0zLTMgM2gydjRoLTR2LTJsLTMgMyAzIDN2LTJoNHY0aC0ybDMgMyAzLTNoLTJ2LTRoNHYyeiIgZGF0YS1vcmlnaW5hbD0iIzQ0NDQ0NCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPgo8L2c+PC9zdmc+" /></div>
                      <ImageCropper
                        show={this.props.show}
                        handleClose={this.props.handleClose}
                        onZoomChange={this.onZoomChange}
                        onCropChange={this.onCropChange}
                        sourceImage={originalDataURL}
                        onCropCompleted={this.onCropCompleted}
                      />
                    </div>
                  </div>
                  {children}
                </div>
              );
            }}
            renderRejected={() => (
              <div className={classes}>
                <FormattedMessage id="ImageFromFile.couldNotReadFile" />
              </div>
            )}
          />
          );
        }}
        renderRejected={() => (
          <div className={classes}>
            <FormattedMessage id="ImageFromFile.couldNotReadFile" />
          </div>
        )}
      />
      ) : (
        <Promised
        key={id}
        promise={this.state.promisedImage}
        renderFulfilled={dataURL => {
          return (
            <div className={classes} id={id} draggable={true} onDragOver={handleDraggedOver} onDrag={handleDragged} onDrop={handleDrop}>
              <div className={css.threeToTwoWrapper}>
                <div className={aspectRatioClasses}>
                  <img className={css.imageSrc} src={dataURL} alt={file.name} ref={imageRef => (this.image = imageRef)} id={"source"+imageId} />
                  <div className={css.hover} ><img alt="drag" className={css.image} src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDE2IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz4KPHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMDAwMDAwIiBkPSJNMTYgOGwtMy0zdjJoLTR2LTRoMmwtMy0zLTMgM2gydjRoLTR2LTJsLTMgMyAzIDN2LTJoNHY0aC0ybDMgMyAzLTNoLTJ2LTRoNHYyeiIgZGF0YS1vcmlnaW5hbD0iIzQ0NDQ0NCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPgo8L2c+PC9zdmc+" /></div>
                  <ImageCropper
                    showCropper={this.props.show}
                    handleClose={this.props.handleClose}
                    onZoomChange={this.onZoomChange}
                    onCropChange={this.onCropChange}
                    sourceImage={dataURL}
                    onCropCompleted={this.onCropCompleted}
                  />
                </div>
              </div>
              {children}
            </div>
          );
        }}
        renderRejected={() => (
          <div className={classes}>
            <FormattedMessage id="ImageFromFile.couldNotReadFile" />
          </div>
        )}
      />
      )
    );
  }
}

ListingImageFromFile.defaultProps = {
  className: null,
  children: null,
  rootClassName: null,
  aspectRatioClassName: null,
  imageClassName: null,
};

const { any, node, string } = PropTypes;

ListingImageFromFile.propTypes = {
  className: string,
  rootClassName: string,
  aspectRatioClassName: string,
  file: any.isRequired,
  id: string.isRequired,
  children: node,
};

export default ListingImageFromFile;
