import React from 'react'
import { Modal } from '..';
import { EnquiryForm } from '../../forms';

import css from './ShippingOptions.css';


const ShippingOptionsMessaging = props => {

  const {
    title,
    authorDisplayName,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
  } = props;

  return (
    <div>
        <Modal
        id="CheckoutPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={() => console.log()}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  )
}

export default ShippingOptionsMessaging