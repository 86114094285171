import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconComment.css';

const ReloveIconComment = props => {
  const { rootClassName, className, size = '20'} = props;
  const classes = classNames(rootClassName || css.root, className);
  return (

    <svg className={classes} width={size} height={size} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 7.375V10.5M7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625ZM7.53113 5.5V5.5625L7.46887 5.56248V5.5H7.53113Z" stroke="#4A4A4A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};

ReloveIconComment.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconComment.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconComment;
