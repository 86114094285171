export const prepareTitle = (options, key) => {
    for (const option of options) {
      if (option.key === key) {
        return option;
      }
      if (option.options) {
        const found = prepareTitle(option.options, key);
        if (found) {
          return found;
        }
      }
    }
    return null;
}
