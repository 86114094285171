import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard } from '../../components';

import css from './SectionFeatured.css';

const SectionFeatured = props => {
  const { rootClassName, className, featuredListings, handleLoginRequest } = props;

  const classes = classNames(rootClassName || css.root, className);

  if (!featuredListings.length) {
    return null;
  }

  return (
    <div className={classes}>
      <h3 className={css.title}><FormattedMessage id="SectionFeatured.title" /></h3>
      <Grid container spacing={2} className={css.featured}>
        {featuredListings.map(l => (
          <Grid item xs={6} md={3} key={l.id.uuid}>
            <ListingCard
              className={css.featuredItem}
              listing={l} 
              handleLoginRequest={handleLoginRequest}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

SectionFeatured.defaultProps = { rootClassName: null, className: null, featuredListings: [] };

const { string } = PropTypes;

SectionFeatured.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFeatured;
