import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, ReloveKeywordInput } from '../../components';

import css from './TopbarSearchForm.css';
import { logEvent } from '../../util/logsEvent';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
    this.state = {
      enableFirstLogEvent: false,
    };
  }

  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  handleModifyForm = values => {
    for (let clave in values) {
      if (values.hasOwnProperty(clave)) {
        let valor = values[clave];
        if (typeof valor === 'string' && valor.length > 2) {
          return valor; //
        }
      }
    }
    return null;
  };

  logFirstCharacter = values => {
    if (!this.state.enableFirstLogEvent) {
      const firstInput = this.handleModifyForm(values);
      if (firstInput) {
        this.setState({ enableFirstLogEvent: true });
        logEvent('search started', { ['keywords']:  firstInput});
      }
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          return (
            <Form className={classes} key={'form-search'} onSubmit={handleSubmit}>
              <Field
                name="keywords"
                render={({ input, meta }) => {
                  return (
                    <ReloveKeywordInput
                      id="keyword-search-gloset"
                      inputProps={input}
                      key={'search-global'}
                      inputRef={this.searchInput}
                      inputType="search"
                      className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                      iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                      inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                      placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                    />
                  );
                }}
              />
              <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                this.logFirstCharacter(values);
                return null;
              }}
            </FormSpy>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
