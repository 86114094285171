import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Grid } from '@material-ui/core';
import config from '../../config';
import { Link } from 'react-router-dom';
import CardSkeleton from '../skeleton/Card'

import css from './SectionTrends.css';

const TrendLink = ({ item }) => {
  const { name, image, link } = item;  
  return (
    <Grid item xs={6} md={3}>
      <Link draggable="false" to={link} className={css.trendItem} title={name}>
        <div className={css.trendBox} style={{backgroundImage: `linear-gradient(0, rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0)), url(${image})`}}>
          <span draggable="false" className={css.trendLabel}>{ name }</span>
        </div>
      </Link>
    </Grid>
  );
};

const SectionTrends = props => {
  const { rootClassName, className, trends } = props;

  const classes = classNames(rootClassName || css.root, className);

  if (!(Array.isArray(trends) && trends.length > 0)) {
    return null;
  }

  return (
    <div draggable="false" className={classes}>
      <h3 className={css.title}><FormattedMessage id="SectionTrends.title" /></h3>
      <Grid container spacing={2} className={css.brands}>        
        {trends.map(trend => (
          <TrendLink draggable="false" item={trend} key={trend.id}/>
        ))}
      </Grid>
    </div>
  );
};

SectionTrends.defaultProps = { 
  rootClassName: null,
  className: null,
  trends: config.landingTrends, 
};

const { string } = PropTypes;

SectionTrends.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTrends;
