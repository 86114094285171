import React, { Component } from 'react';
import classNames from 'classnames';

import IconMagnifyingGlass from './IconMagnifyingGlass.js';

import css from './ReloveKeywordInput.css';

class ReloveKeywordInputComponent extends Component {
  render() {
    const {
      id,
      inputProps,
      inputRef,
      inputType,
      className,
      rootClassName,
      iconClassName,
      inputClassName,
      placeholder,
    } = this.props;

    const rootClass = classNames(rootClassName || css.root, className);
    const iconClass = classNames(iconClassName || css.icon);
    const inputClass = classNames(inputClassName || css.input);

    return (
      <div className={rootClass}>
        <div className={iconClass}>
          <IconMagnifyingGlass />
        </div>
        <input
          {...inputProps}
          id={id}
          className={inputClass}
          type={inputType}
          autoComplete="off"
          placeholder={placeholder}
          ref={inputRef}
        />
      </div>
    );
  }
}

export default ReloveKeywordInputComponent;
