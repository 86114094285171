import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE = 'transition/request-payment-quick-purchase';
export const TRANSITION_SEND_OFFER = 'transition/send-offer';
export const TRANSITION_ENQUIRE = 'transition/send-enquiry';
export const TRANSITION_CONEKTA_PAYMENT_QUICK_PURCHASE = 'transition/conekta-payment-quick-purchase';

export const TRANSITION_PAYMENT_VALIDATION = 'transition/payment-validation'
export const TRANSITION_APPROVE_PAYMENT = 'transition/approve-payment'
export const TRANSITION_REJECT_PAYMENT = 'transition/reject-payment'

export const TRANSITION_ENQUIRY_SEND_OFFER = 'transition/enquiry-send-offer'
export const TRANSITION_ENQUIRY_REQUEST_PAYMENT = 'transition/request-payment-quick-purchase-after-enquiry'


// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/complete-manual-payment';
export const TRANSITION_CONFIRM_PAYMENT_QUICK_PURCHASE = 'transition/confirm-payment-quick-purchase';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_EXPIRE_PAYMENT_QUICK_PURCHASE = 'transition/expire-payment-quick-purchase';

export const TRANSITION_INITIATE_MANUAL_PAYMENT = 'transition/transition-to-manual-payment';

export const TRANSITION_OFFER_PAYMENT_FAILED = 'transition/offer-payment-failed';
export const TRANSITION_OFFER_PAYMENT_SUCCESSFUL = 'transition/offer-payment-successful';



export const TRANSITION_INITIATE_PAYMENT = 'transition/initiate-manual-payment';



// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT_QUICK_PURCHASE = 'transition/accept-quick-purchase';
export const TRANSITION_ACCEPT_PURCHASE = 'transition/accept-purchase'
export const TRANSITION_DECLINE_OFFER = 'transition/decline-offer';
export const TRANSITION_EXPIRE_OFFER = 'transition/expire-offer';
export const TRANSITION_ACEPT_OFFER = 'transition/accept-offer';

//TO BE DELETED
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

export const TRANSITION_CONFIRM_TRACKING = 'transition/confirm-tracking';
export const TRANSITION_CANCEL_TRACKING = 'transition/cancel-tracking';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';
export const TRANSITION_EXPIRE_QUICK_PURCHASE = 'transition/expire-quick-purchase';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CANCEL_BY_OPERATOR = 'transition/cancel-by-operator';
export const TRANSITION_CANCEL_BY_OPERATOR_AFTER_REMINDER = 'transition/cancel-by-operator-after-reminder';
export const TRANSITION_CANCEL_AFTER_REMINDER = 'transition/cancel-after-reminder';

export const TRANSITION_REFUND_STRIPE = 'transition/refund-stripe';
export const TRANSITION_REFUND_CONEKTA = 'transition/refund-conekta';
export const TRANSITION_REFUND_PAYPAL = 'transition/refund-paypal';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_COMPLETE_BY_CUSTOMER = 'transition/complete-by-customer';

export const TRANSITION_STRIPE_PAYOUT = 'transition/stripe-payout';
export const TRANSITION_CONEKTA_PAYOUT = 'transition/conekta-payout';

export const TRANSITION_SEND_REMINDER = 'transition/send-reminder';
export const TRANSITION_CONFIRM_TRACKING_AFTER_REMINDER = 'transition/confirm-tracking-after-reminder';

export const TRANSITION_CONFIRM_TRACKING_FOR_AUTHENTICATION = 'transition/confirm-tracking-for-authentication';
export const TRANSITION_CONFIRM_TRACKING_FOR_AUTHENTICATION_AFTER_REMINDER = 'transition/confirm-tracking-for-authentication-after-reminder';

export const TRANSITION_CONFIRM_DELIVERING_FOR_AUTHENTICATION = 'transition/confirm-delivering-for-authentication'

export const TRANSITION_COMPLETE_AUTHENTICATION = 'transition/complete-authentication';
export const TRANSITION_DENY_AUTHENTICATION = 'transition/deny-authentication';

export const TRANSITION_CONFIRM_DELIVERING = 'transition/confirm-delivering';
export const TRANSITION_CONFIRM_DELIVERING_BY_OPERATOR = 'transition/confirm-delivering-by-operator';
export const TRANSITION_CONFIRM_DELIVERING_BY_CUSTOMER = 'transition/confirm-delivering-by-customer';

export const TRANSITION_CLAIM = 'transition/claim';
export const TRANSITION_ISSUE_CLAIM = 'transition/issue-a-claim'
export const TRANSITION_ACCEPT_CLAIM = 'transition/accept-claim';
export const TRANSITION_DECLINE_CLAIM = 'transition/decline-claim';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

export const TRANSITION_VALIDATE_CONEKTA = 'transition/validate-conekta'
export const TRANSITION_REQUEST_CLABE = 'transition/request-clabe'
export const TRANSITION_CONEKTA_PAYOUT_AFTER_CLABE = 'transition/conekta-payout-after-clabe'


export const TRANSITION_FINALIZE_TRANSACTION = 'transition/finalize-transaction'

export const TRANSITION_REQUEST_CONEKTA_PAYOUT = "transition/request-conekta-payout";
export const TRANSITION_CONEKTA_PAYMENT_SUCCESSFUL = "transition/conekta-payment-successful";
export const TRANSITION_FAILURE_ON_PAYOUT = "transition/failure-on-payout";
export const TRANSITION_RETRY_CONEKTA_PAYMENT_SUCCESSFUL = "transition/retry-conekta-payment-successful";


/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_PENDING_OFFER = 'pending-offer';
const STATE_PENDING_PAYMENT_QUICK_PURCHASE = 'pending-payment-quick-purchase';
const STATE_AWAITING_PAYMENT_VALIDATION = 'awaiting-payment-validation';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED_QUICK_PURCHASE = 'preauthorized-quick-purchase';
const STATE_DECLINED = 'declined';
const STATE_PAYMENT_FAILED = 'payment-failed'
const STATE_PENDING_MANUAL_PAYMENT = 'pending-manual-payment';
const STATE_PENDING_CONFIRMATION = 'pending-confirmation';
const STATE_ACCEPTED = 'accepted';
const STATE_PRODUCT_AUTHENTICATING_TRACKING_CONFIRMED = 'product-authenticating-tracking-confirmed'
const STATE_PRODUCT_AUTHENTICATING = 'product-authenticating';
const STATE_TRACKING_ADVISED = 'tracking-advised';
const STATE_TRACKING_CONFIRMED = 'tracking-confirmed';
const STATE_DELIVERED_CONFIRMED = 'delivered-confirmed';
const STATE_CLAIMED = 'claimed';
const STATE_CANCELED = 'canceled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

const STATE_PREPARING_REFUND = 'preparing-refund'

const STATE_PENDING_PAYOUT = 'pending-payout'

const STATE_ENQUIRY = 'enquiry'

const STATE_VALIDATE_CLABE = 'validate-clabe'
const STATE_MISSING_CLABE = 'missing-clabe'


const STATE_PENDING_CONEKTA_PAYOUT = "pending-conekta-payout";
const STATE_TRYING_CONEKTA_PAYOUT = "trying-conekta-payout";
const STATE_CONEKTA_PAYMENT_FAILURE = "conekta-payment-failure";

const STATE_PENDING_PURCHASE_CONFIRMATION = 'pending-purchase-confirmation';


//Not included on transactrion process
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED_QUICK_PURCHASE = 'payment-expired-quick-purchase';
// const STATE_PREAUTHORIZED = 'preauthorized';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_SEND_OFFER]: STATE_PENDING_OFFER,
        [TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE]: STATE_PENDING_PAYMENT_QUICK_PURCHASE,
        [TRANSITION_CONEKTA_PAYMENT_QUICK_PURCHASE]: STATE_ACCEPTED,
        [TRANSITION_PAYMENT_VALIDATION] : STATE_AWAITING_PAYMENT_VALIDATION,
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY
      },
    },
    [STATE_AWAITING_PAYMENT_VALIDATION]:{
      on: {
        [TRANSITION_REJECT_PAYMENT] : STATE_DECLINED,
        [TRANSITION_APPROVE_PAYMENT]: STATE_ACCEPTED
      }
    },
    [STATE_ENQUIRY]:{
      on: {
        [TRANSITION_ENQUIRY_SEND_OFFER]: STATE_PENDING_OFFER,
        [TRANSITION_ENQUIRY_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT_QUICK_PURCHASE,
      },
    },
    [STATE_PENDING_OFFER]: {
      on: {
        [TRANSITION_EXPIRE_OFFER]: STATE_DECLINED,
        [TRANSITION_DECLINE_OFFER]: STATE_DECLINED,
        [TRANSITION_ACEPT_OFFER]: STATE_PENDING_PURCHASE_CONFIRMATION,
      },
    },

    [STATE_PENDING_PURCHASE_CONFIRMATION]:{
      on:{
        [TRANSITION_OFFER_PAYMENT_FAILED]: STATE_PAYMENT_FAILED,
        [TRANSITION_OFFER_PAYMENT_SUCCESSFUL]: STATE_ACCEPTED
      }
    },

    [STATE_PENDING_PAYMENT]:{
      on:{
        [TRANSITION_ACCEPT_PURCHASE]: STATE_ACCEPTED,
        [TRANSITION_INITIATE_MANUAL_PAYMENT] : STATE_PENDING_MANUAL_PAYMENT
      }
    },

    [STATE_PENDING_MANUAL_PAYMENT]:{
      on:{
        [TRANSITION_INITIATE_PAYMENT]: STATE_PENDING_CONFIRMATION,
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED
      }
    },
    [STATE_PENDING_CONFIRMATION]:{
      on:{
        [TRANSITION_CONFIRM_PAYMENT]: STATE_ACCEPTED
      }
    },
    [STATE_PENDING_PAYMENT_QUICK_PURCHASE]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT_QUICK_PURCHASE]: STATE_PAYMENT_EXPIRED_QUICK_PURCHASE,
        [TRANSITION_CONFIRM_PAYMENT_QUICK_PURCHASE]: STATE_PREAUTHORIZED_QUICK_PURCHASE,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PAYMENT_EXPIRED_QUICK_PURCHASE]: {},
    [STATE_PREAUTHORIZED_QUICK_PURCHASE]: {
      on: {
        [TRANSITION_EXPIRE_QUICK_PURCHASE]: STATE_DECLINED,
        [TRANSITION_ACCEPT_QUICK_PURCHASE]: STATE_ACCEPTED,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_PAYMENT_FAILED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CANCEL_TRACKING]: STATE_PREPARING_REFUND,
        [TRANSITION_CANCEL_BY_OPERATOR]: STATE_PREPARING_REFUND,
        [TRANSITION_CONFIRM_TRACKING]: STATE_TRACKING_CONFIRMED,
        [TRANSITION_SEND_REMINDER]: STATE_TRACKING_ADVISED,
        [TRANSITION_CONFIRM_TRACKING_FOR_AUTHENTICATION]: STATE_PRODUCT_AUTHENTICATING_TRACKING_CONFIRMED,
      },
    },
    [STATE_TRACKING_ADVISED]: {
      on: {
        [TRANSITION_CONFIRM_TRACKING_AFTER_REMINDER]: STATE_TRACKING_CONFIRMED,
        [TRANSITION_CANCEL_BY_OPERATOR_AFTER_REMINDER]: STATE_PREPARING_REFUND,
        [TRANSITION_CANCEL_AFTER_REMINDER]: STATE_PREPARING_REFUND,
        [TRANSITION_CONFIRM_TRACKING_FOR_AUTHENTICATION_AFTER_REMINDER]: STATE_PRODUCT_AUTHENTICATING_TRACKING_CONFIRMED,
      },
    },

    [STATE_PRODUCT_AUTHENTICATING_TRACKING_CONFIRMED]:{
      on: {
        [TRANSITION_CONFIRM_DELIVERING_FOR_AUTHENTICATION]: STATE_PRODUCT_AUTHENTICATING
      }
    },

    [STATE_PRODUCT_AUTHENTICATING]: {
      on: {
        [TRANSITION_COMPLETE_AUTHENTICATION]: STATE_TRACKING_CONFIRMED,
        [TRANSITION_DENY_AUTHENTICATION]: STATE_PREPARING_REFUND,
      }
    },

    [STATE_TRACKING_CONFIRMED]: {
      on: {
        [TRANSITION_CONFIRM_DELIVERING]: STATE_DELIVERED_CONFIRMED,
        [TRANSITION_CONFIRM_DELIVERING_BY_OPERATOR]: STATE_DELIVERED_CONFIRMED,
        [TRANSITION_ISSUE_CLAIM]: STATE_CLAIMED,
        [TRANSITION_CANCEL]: STATE_PREPARING_REFUND
      }
    },

    [STATE_DELIVERED_CONFIRMED]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_PENDING_PAYOUT,
        [TRANSITION_COMPLETE_BY_CUSTOMER]: STATE_PENDING_PAYOUT,
        [TRANSITION_CLAIM]: STATE_CLAIMED
      }
    },

    [STATE_PENDING_PAYOUT]: {
      on: {
        [TRANSITION_STRIPE_PAYOUT]: STATE_DELIVERED,
        [TRANSITION_VALIDATE_CONEKTA]: STATE_VALIDATE_CLABE,
        [TRANSITION_FINALIZE_TRANSACTION]: STATE_DELIVERED
      }
    },

    [STATE_VALIDATE_CLABE]:{
      on: {
        [TRANSITION_CONEKTA_PAYOUT]: STATE_PENDING_CONEKTA_PAYOUT,
        [TRANSITION_REQUEST_CLABE]: STATE_MISSING_CLABE,
      }
    },

    [STATE_MISSING_CLABE]:{
      on: {
        [TRANSITION_CONEKTA_PAYOUT_AFTER_CLABE]: STATE_PENDING_CONEKTA_PAYOUT,
      }
    },

    [STATE_PENDING_CONEKTA_PAYOUT]:{
      on:{
        [TRANSITION_REQUEST_CONEKTA_PAYOUT]: STATE_TRYING_CONEKTA_PAYOUT
      }
    },

    [STATE_TRYING_CONEKTA_PAYOUT]:{
      on:{
        [TRANSITION_CONEKTA_PAYMENT_SUCCESSFUL]: STATE_DELIVERED,
        [TRANSITION_FAILURE_ON_PAYOUT]: STATE_CONEKTA_PAYMENT_FAILURE,
      }
    },

    [STATE_CONEKTA_PAYMENT_FAILURE]:{
      on:{
        [TRANSITION_RETRY_CONEKTA_PAYMENT_SUCCESSFUL]: STATE_DELIVERED
      }
    },

    [STATE_CLAIMED]: {
      on: {
        [TRANSITION_ACCEPT_CLAIM]: STATE_PREPARING_REFUND,
        [TRANSITION_DECLINE_CLAIM]: STATE_PENDING_PAYOUT
      }
    },

    [STATE_PREPARING_REFUND]: {
      on: {
        [TRANSITION_REFUND_STRIPE]: STATE_CANCELED,
        [TRANSITION_REFUND_CONEKTA]: STATE_CANCELED,
        [TRANSITION_REFUND_PAYPAL] : STATE_CANCELED
      }
    },

    [STATE_CANCELED]: {},

    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

const transitionsToFilterOut = [
  'transition/send-enquiry', 
  'transition/expire-quick-purchase',
  'transition/expire-payment-quick-purchase',
  'transition/expire-offer',
  'transition/request-payment-quick-purchase'
]; // Add all the keys you want to filter out

export const TRANSACTION_TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription))
  .filter(t => !transitionsToFilterOut.includes(t.key))
  .map(t => t.key);

export const MESSAGE_TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).filter(t => t.key === 'transition/send-enquiry').map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PENDING_OFFER);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

  export const txIsManualPaymenConfirmationPending = tx =>
  getTransitionsToState(STATE_PENDING_CONFIRMATION).includes(txLastTransition(tx));

export const txIsPaymentPendingQuickPurchase = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT_QUICK_PURCHASE).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPaymentExpiredQuickPurchase = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED_QUICK_PURCHASE).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PENDING_OFFER).includes(txLastTransition(tx));

export const txIsRequestedQuickPurchase = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED_QUICK_PURCHASE).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsPendingValidation = tx =>
  getTransitionsToState(STATE_AWAITING_PAYMENT_VALIDATION).includes(txLastTransition(tx));

export const txIsPreparingRefund = tx  =>
  getTransitionsToState(STATE_PREPARING_REFUND).includes(txLastTransition(tx));


export const txIsPendingPayout = tx => 
  getTransitionsToState(STATE_PENDING_PAYOUT).includes(txLastTransition(tx));

export const txIsTrackingConfirmed = tx =>
  getTransitionsToState(STATE_TRACKING_CONFIRMED).includes(txLastTransition(tx));

export const txIsAuthenticatingTrackingConfirmed = tx => 
  getTransitionsToState(STATE_PRODUCT_AUTHENTICATING_TRACKING_CONFIRMED).includes(txLastTransition(tx));

export const txIsAuthenticationCompleted = tx =>
  txLastTransition(tx) === TRANSITION_COMPLETE_AUTHENTICATION

  
export const txIsAuthenticating = tx => 
  getTransitionsToState(STATE_PRODUCT_AUTHENTICATING).includes(txLastTransition(tx));


export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

  export const txPaymentFailed = tx => 
  getTransitionsToState(STATE_PAYMENT_FAILED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsSent = tx =>
  getTransitionsToState(STATE_TRACKING_CONFIRMED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsDeliveredConfirmed = tx =>
  getTransitionsToState(STATE_DELIVERED_CONFIRMED).includes(txLastTransition(tx));

export const txIsClaimed = tx =>
  getTransitionsToState(STATE_CLAIMED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

export const txIsManualPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_MANUAL_PAYMENT).includes(txLastTransition(tx));

export const txIsReminderSent = tx => 
  getTransitionsToState(STATE_TRACKING_ADVISED).includes(txLastTransition(tx));


export const txIsPendingConfirmation = tx =>
  getTransitionsToState(STATE_PENDING_CONFIRMATION).includes(txLastTransition(tx));


export const txIsEnquired = tx => 
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsClabeMissing = tx => 
  getTransitionsToState(STATE_MISSING_CLABE).includes(txLastTransition(tx));

export const txIsValidatingClabe = tx => 
  getTransitionsToState(STATE_VALIDATE_CLABE).includes(txLastTransition(tx));


/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED); 
export const txHasBeenDeliveredLegacy = hasPassedStateFn(STATE_PENDING_PAYOUT);
export const txHasReminder = hasPassedStateFn(STATE_TRACKING_ADVISED);
export const txHasBeenCancelled = hasPassedStateFn(STATE_PREPARING_REFUND);
export const txHasBeenTrackingConfirmed = hasPassedStateFn(STATE_TRACKING_CONFIRMED);
export const txHasBeenPaid = hasPassedStateFn(STATE_PENDING_CONEKTA_PAYOUT);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    // TRANSITION_ACEPT_OFFER,
    TRANSITION_DECLINE_OFFER,
    TRANSITION_ACCEPT_QUICK_PURCHASE,
    TRANSITION_CANCEL,
    TRANSITION_CANCEL_BY_OPERATOR,
    TRANSITION_CANCEL_BY_OPERATOR_AFTER_REMINDER,
    TRANSITION_CANCEL_TRACKING,
    TRANSITION_CANCEL_AFTER_REMINDER,
    TRANSITION_COMPLETE,
    TRANSITION_COMPLETE_BY_CUSTOMER,
    TRANSITION_CLAIM,
    TRANSITION_DECLINE_CLAIM,
    TRANSITION_ACCEPT_CLAIM,
    TRANSITION_CONFIRM_TRACKING,
    TRANSITION_CONFIRM_DELIVERING_BY_OPERATOR,
    TRANSITION_CONFIRM_DELIVERING_BY_CUSTOMER,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_QUICK_PURCHASE,
    TRANSITION_CONEKTA_PAYMENT_QUICK_PURCHASE,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_INITIATE_PAYMENT,
    TRANSITION_OFFER_PAYMENT_SUCCESSFUL,
    TRANSITION_OFFER_PAYMENT_FAILED
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [TRANSITION_SEND_OFFER, TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE, TRANSITION_ENQUIRY_SEND_OFFER, TRANSITION_ENQUIRY_REQUEST_PAYMENT].includes(
    transition
  );
};
