import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { uploadCoverImage as postUploadCoverImage } from '../../util/api';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { sendVerification, checkVerification } from '../../util/api';

import Resizer from "react-image-file-resizer";

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPLOAD_COVER_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_COVER_IMAGE_REQUEST';
export const UPLOAD_COVER_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_COVER_IMAGE_SUCCESS';
export const UPLOAD_COVER_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_COVER_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const SEND_VERIFICATION_REQUEST = 'app/Auth/SEND_VERIFICATION_REQUEST';
export const SEND_VERIFICATION_SUCCESS = 'app/Auth/SEND_VERIFICATION_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const CHECK_VERIFICATION_REQUEST = 'app/Auth/CHECK_VERIFICATION_REQUEST';
export const CHECK_VERIFICATION_SUCCESS = 'app/Auth/CHECK_VERIFICATION_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  image: null,
  coverImage: null,
  uploadImageError: null,
  uploadInProgress: false,
  uploadCoverImageError: null,
  uploadCoverInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  status: null,
  phoneNumber: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPLOAD_COVER_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        coverImage: { ...payload.params },
        uploadCoverInProgress: true,
        uploadCoverImageError: null,
      };
    case UPLOAD_COVER_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { fileName, fileLocation } = payload;
      const coverImage = { id: fileName, location: fileLocation };
      return { ...state, coverImage, uploadCoverInProgress: false };
    }
    case UPLOAD_COVER_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, coverImage: null, uploadCoverInProgress: false, uploadCoverImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    case SEND_VERIFICATION_SUCCESS:
      return {...state, status: payload.response.status, phoneNumber: payload.response.phoneNumber};

    case CHECK_VERIFICATION_SUCCESS:
      return {...state, status: payload.response.status};
  
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: images.upload
export const uploadCoverImageRequest = params => ({ type: UPLOAD_COVER_IMAGE_REQUEST, payload: { params } });
export const uploadCoverImageSuccess = result => ({ type: UPLOAD_COVER_IMAGE_SUCCESS, payload: result.data });
export const uploadCoverImageError = error => ({
  type: UPLOAD_COVER_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// Whatsapp verification for phone number

export const sendVerifRequest = status => ({
  type: SEND_VERIFICATION_REQUEST,
  payload: status,
});
export const sendVerifSuccess = response => ({
  type: SEND_VERIFICATION_SUCCESS,
  payload: {response},
});
export const checkVerifRequest = status => ({
  type: CHECK_VERIFICATION_REQUEST,
  payload: status,
});
export const checkVerifSuccess = response => ({
  type: CHECK_VERIFICATION_SUCCESS,
  payload: {response},
});
export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return async (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));
    let compressedFile = await resizeFile(actionPayload.file);
    const bodyParams = {
      image: compressedFile,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

// Images return imageId which we need to map with previously generated temporary id
export function uploadCoverImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadCoverImageRequest(actionPayload));
    let fd = new FormData();
    fd.append('image', actionPayload.file, `${Date.now()}-${actionPayload.file.name}`);

    return postUploadCoverImage(fd)
      .then(resp => {
        dispatch(uploadCoverImageSuccess({ data: resp }));
      })
      .catch(e => dispatch(uploadCoverImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = (actionPayload, target = null) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
        if (target !== null && target !== '') {
          window.location.href = target.replace('/checkout', '');
        }
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const sendVerif = (phone) => (dispatch, getState, sdk) => {
  dispatch(sendVerifRequest(true));
  return sendVerification( phone ).then((response) => {
    dispatch(sendVerifSuccess(response));
    dispatch(sendVerifRequest(false));
    return response;
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(sendVerifRequest(false));
    throw e
  });
};

export const checkVerif = (phone, code) => (dispatch, getState, sdk) => {
  dispatch(checkVerifRequest(true));
  return checkVerification({ phone, code }).then((response) => {
    dispatch(checkVerifSuccess(response));
    dispatch(checkVerifRequest(false));
    return response;
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(checkVerifRequest(false));
  });
};

const resizeFile = (file) =>
new Promise((resolve) => {

  let compression = file.size > 20000 ? 70 : 100;

  return Resizer.imageFileResizer(
    file,
    1000,
    1000,
    "JPEG",
    compression,
    0,
    (uri) => {
      resolve(uri);
    },
    "file",
  );
});
