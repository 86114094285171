import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { func, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Avatar, NamedLink, ReloveIconFavorite, ResponsiveImage } from '../../components';
import config from '../../config';
import { toggleLike } from '../../containers/ListingPage/ListingPage.duck';
import routeConfiguration from '../../routeConfiguration';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { isMobile } from '../../util/userAgent';
import css from './ListingCard.css';
import { getParamsListing, logEvent } from '../../util/logsEvent';
import { useSelector } from 'react-redux'

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    currentUser,
    onToggleLike,
    likeInProgress,
    setActiveListing,
  } = props;

  const keywordsParams = useSelector(state => state?.SearchPage?.searchParams?.keywords)

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const authorLinkProps = {
    name: 'ProfilePage',
    params: { id: author.id.uuid },
  };
  const size = Array.isArray(currentListing.attributes.publicData.size)
    ? currentListing.attributes.publicData.size[0]
    : currentListing.attributes.publicData.size;

  const sizeOptions = findOptionsForSelectFilter('size', config.custom.filters);
  const shoeSizeOptions = findOptionsForSelectFilter('sizeShoes', config.custom.filters);
  const accessoriesSizeOptions = findOptionsForSelectFilter(
    'sizeAccessories',
    config.custom.filters
  );

  const foundSize =
    sizeOptions.find(({ key }) => key === size) ||
    shoeSizeOptions.find(({ key }) => key === size) ||
    accessoriesSizeOptions.find(({ key }) => key === size);

  const sizeFormatted = foundSize ? foundSize.label : size;

  const original_price = currentListing.attributes.publicData.original_price;

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const { formattedPrice: originalPrice, priceTitle: originalPriceTitle } = original_price
    ? priceData(new Money(original_price.amount, original_price.currency), intl)
    : { formattedPrice: '$000', priceTitle: 'original_price' };

  const { metadata } = currentListing.attributes;

  const isCurrentLikeInProgress = !!likeInProgress && likeInProgress === id;

  const handleToggleLike = (e) => {
      e.preventDefault();
      if (!!currentUser) {
        if(!likeInProgress){
          onToggleLike(currentUser.id.uuid, id);
        }
      } else {

        const { history, location, listing, handleLoginRequest } = props;
        if(handleLoginRequest){
          handleLoginRequest(listing.id.uuid)
        }else{
          const state = { from: `${location.pathname}${location.search}${location.hash}` };
          history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
        }
      }
    };

  const fixedBrand = brand => {
    return brand
      .replace('&', '%26')
      .replace('+', '%2B')
      .replace(' ', '%20');
  };

  const isLikedByCurrentUser =
    !!currentUser &&
    !!metadata &&
    Array.isArray(metadata.likedBy) &&
    metadata.likedBy.includes(currentUser.id.uuid);

  const likesCount = !!metadata && Array.isArray(metadata.likedBy) ? metadata.likedBy.length : 0;

  const brand = currentListing.attributes.publicData.brand;

  const isBought = currentListing.attributes.publicData.sold;

  const isAvailable = currentListing.attributes.publicData.available;

  const boughtLabelPicture = isBought ? <div className={css.pictureSold}></div> : null;

  const boughtLabel = isBought ? <div className={css.pictureSoldText}>VENDIDO</div> : null;

  const availableLabelPicture =
    isAvailable === 'No-Disponible' ? <div className={css.pictureSold}></div> : null;

  const availableLabel =
    isAvailable === 'No-Disponible' ? (
      <div className={css.pictureNotAvailableText}>NO DISPONIBLE</div>
    ) : null;

  const onListingNaviagation = e => {
    setActiveListing(id);
    if (keywordsParams?.length > 3) {
      logEvent('search result clicked', { rank: currentListing?.attributes?.metadata?.rankingSoldAttr, ...getParamsListing(currentListing) });
    }

  };
  // set target to _blank if on mobile
  const target = isMobile() ? '_blank' : null;
  return (
    <div className={classes} id={`ListingCard-${id}`}>
      <NamedLink
        draggable="false"
        onClick={onListingNaviagation}
        className={css.noUnderline}
        name="ListingPage"
        target={target}
        params={{ id, slug }}
      >
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            {boughtLabelPicture}
            {availableLabelPicture}
            {boughtLabel}
            {availableLabel}
            <LazyImage
              draggable="false"
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['square-small', 'square-small2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
      </NamedLink>
      <div className={css.info}>
        <NamedLink
          draggable="false"
          onClick={onListingNaviagation}
          name="ListingPage"
          target={target}
          params={{ id, slug }}
        >
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </NamedLink>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.originalPriceValue} title={originalPriceTitle}>
            {originalPrice}
          </div>
        </div>
        <div className={css.size}>
          Talla: <span className={css.allCaps}>{sizeFormatted}</span> |{' '}
          <NamedLink name="SearchPage" to={{ search: 'pub_brand=' + fixedBrand(brand) }}>
            {brand}
          </NamedLink>
        </div>
        <div draggable="false" className={css.authorInfo}>
          <Avatar className={css.avatar} user={author} />
          <NamedLink draggable="false" {...authorLinkProps}>
            {authorName}
          </NamedLink>
        </div>
        <hr className={css.separator} />
        <div className={css.actions}>
          <div
            className={classNames(css.favorite, isLikedByCurrentUser ? css.favoriteLiked : null)}
            onClick={handleToggleLike}
          >
            <ReloveIconFavorite className={css.favoriteIcon} />
            {!isCurrentLikeInProgress ? (
              <span>{likesCount}</span>
            ) : (
              <CircularProgress className={css.favoriteLoader} color={'inherit'} size={14} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  isBought: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  const { likeInProgress } = state.ListingPage;
  const { currentUser } = state.user;
  return {
    currentUser,
    likeInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleLike: (user, listingId) => dispatch(toggleLike(user, listingId)),
});

const ListingCard = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingCardComponent);

export default ListingCard;
