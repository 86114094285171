/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ListingImageFromFile, ResponsiveImageEdit, IconSpinner } from '../../components';

import css from './AddImages.css';
import RemoveImageButton from './RemoveImageButton';


function ThumbnailWrapper(props){
  const { className, image, savedImageAltText, onRemoveImage,onImageUpload, onUpdateImageOrder, imageOrder, publicData, imageUploaded , dragged, setDragged, draggedOver, setDraggedOver} = props; 
  const [ show, setShow ] = useState(image.originalFile ? false : image.index && image.index !== 0 ? false : true);
  const [ firstImageLoad, setFirstImageLoad ] = useState(true);
  
  
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  };
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true);
  }
  const handleRemoveClick = e => {
    onRemoveImage(image.id);
  };
  const imageId = image.id.uuid ? image.id.uuid : image.id;
  const handleDraggedOver = e =>{
    e.stopPropagation();
    setDraggedOver(imageId);
  }
  const handleDragged = e =>{
    e.stopPropagation();
    setDragged(imageId);
  }
  const handleDrop = e => {
    e.stopPropagation();
    const newImageOrder = imageOrder;
    const initial = newImageOrder.indexOf(draggedOver);
    const replace = newImageOrder.indexOf(dragged);
    const aux = newImageOrder[initial];
    newImageOrder[initial] = newImageOrder[replace];
    newImageOrder[replace] = aux;
    onUpdateImageOrder(newImageOrder);
    setDragged(null);
    setDraggedOver(null);
  }

  const handleCropComplete = (file, newCrop = null) => {
    onImageUpload(file, newCrop)
  }

  //---------------------------------------------------------------------------------------------------//
  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ?           <RemoveImageButton onClick={(e) => {
      e.preventDefault();
      if(e.nativeEvent.constructor.name === 'PointerEvent' || e.nativeEvent.constructor.name === 'MouseEvent'){
        handleRemoveClick();
      }
    }} /> : null;
    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;
    if(image.imageId && firstImageLoad){
      if(imageOrder[0] === image.id){
        handleShow();
        setFirstImageLoad(false);
      }else{
        handleShow();
      setFirstImageLoad(false);
      sleep(100).then(() =>{
        handleClose();
      })
      }
    }

    return (
        <ListingImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
        originalFile={image.originalFile}
        show={show}
        handleClose={handleClose}
        image={image}
        handleRemoveClick={handleRemoveClick}
        publicData={publicData}
        imageUploaded={imageUploaded}
        savedImageAltText={savedImageAltText}
        handleDragged={handleDragged}
        handleDraggedOver={handleDraggedOver}
        handleDrop={handleDrop}
        handleCropComplete={handleCropComplete}
        onUpdateImageOrder={onUpdateImageOrder}
        imageOrder={imageOrder}
      >
        {removeButton}
        {uploadingOverlay}
      </ListingImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes} id={imageId} draggable={true} onDragOver={handleDraggedOver} onDrag={handleDragged} onDrop={handleDrop} onMouseDown={handleDragged} onMouseMove={handleDraggedOver} onMouseUp={handleDrop}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapperResponsive}>
            <ResponsiveImageEdit
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x', 'scaled-large']}
              publicData={publicData}
              show={show}
              handleClose={handleClose}
            />
          </div>
          <RemoveImageButton onClick={(e) => {
            e.preventDefault();
            if(e.nativeEvent.constructor.name === 'PointerEvent' || e.nativeEvent.constructor.name === 'MouseEvent'){
              handleRemoveClick();
            }
          }} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateImageOrder: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    wrapperClassName,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onUpdateImageOrder,
    imageOrder,
    onImageUpload,
    publicData,
  } = props;
  const classes = classNames(css.root, className);
  const [ dragged, setDragged ] = useState(null);
  const [ draggedOver, setDraggedOver] = useState(null);
  return (
    <div className={classes}>
      {children}
      <div className={wrapperClassName}>
        {images.map((image, index) => {
          return (
              <ThumbnailWrapper
              image={image}
              images={images}
              index={index}
              key={image.id.uuid || image.id}
              className={thumbnailClassName}
              savedImageAltText={savedImageAltText}
              onRemoveImage={onRemoveImage}
              onImageUpload={onImageUpload}
              onUpdateImageOrder={onUpdateImageOrder}
              imageOrder={imageOrder}
              publicData={publicData}
              dragged={dragged}
              setDragged={setDragged}
              draggedOver={draggedOver}
              setDraggedOver={setDraggedOver}
            />
          );
        })}
      </div>
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onUpdateImageOrder: func.isRequired,
};

export default AddImages;
