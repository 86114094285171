import React from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.css';

const ResponsiveImageEdit = props => {
    const { 
        className, 
        rootClassName, 
        alt, 
        noImageMessage, 
        image, 
        variants, 
    } = props;

    if (image == null || variants.length === 0) {
        const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

        // NoImageMessage is needed for listing images on top the map (those component lose context)
        // https://github.com/tomchentw/react-google-maps/issues/578
        const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
        /* eslint-disable jsx-a11y/img-redundant-alt */
        return (
        <div className={noImageClasses}>
            <div className={css.noImageWrapper}>
            <NoImageIcon className={css.noImageIcon} />
            <div className={css.noImageText}>{noImageMessageText}</div>
            </div>
        </div>
        );
    }

    const imageVariants = image.attributes.variants;
    const srcSet = imageVariants["scaled-large"].url;

    return (
        <div className={css.parent}>
            <img className={css.image} src={srcSet} alt={alt}/>
            <div className={css.hover} ><img alt="drag" className={css.imageHover} src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDE2IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz4KPHBhdGggeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMDAwMDAwIiBkPSJNMTYgOGwtMy0zdjJoLTR2LTRoMmwtMy0zLTMgM2gydjRoLTR2LTJsLTMgMyAzIDN2LTJoNHY0aC0ybDMgMyAzLTNoLTJ2LTRoNHYyeiIgZGF0YS1vcmlnaW5hbD0iIzQ0NDQ0NCIgc3R5bGU9IiIgY2xhc3M9IiI+PC9wYXRoPgo8L2c+PC9zdmc+" /></div>
        </div>
    );
}


ResponsiveImageEdit.defaultProps = {
    className: null,
    rootClassName: null,
    image: null,
    noImageMessage: null,
  };
  
  ResponsiveImageEdit.propTypes = {
    className: string,
    rootClassName: string,
    alt: string.isRequired,
    variants: arrayOf(string).isRequired,
    noImageMessage: string,
  };
  
  export default ResponsiveImageEdit;