import classNames from 'classnames';
import { arrayOf, bool, number} from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  BookingTimeInfo, Footer,
  IconSpinner, LayoutSideNavigation, LayoutWrapperFooter, LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar, NamedLink,
  Page,
  PaginationLinks,
  AvatarLarge,
  Avatar
} from '../../components';
import {formatDate} from '../../util/dates';
import config from '../../config';
import { TopbarContainer } from '..';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ensureCurrentUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
    txIsEnquired
} from '../../util/transaction';
import { DATE_TYPE_DATE, propTypes } from '../../util/types';

import css from './MessagePage.css';
import { loadData} from './MessagePage.duck';



import Arrow from '@material-ui/icons/ArrowForwardIos';

const MAX_MOBILE_SCREEN_WIDTH = 600;

// Translated name of the state of the given transaction
export const txState = (intl, tx) => {
  if (txIsEnquired(tx)){
    return {
        nameClassName: css.nameNotEmphasized,
        bookingClassName: css.bookingNoActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
        stateClassName: css.stateNoActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.stateDelivered',
        }),
      };
  }else{
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const MessageItem = props => {
  const {intl, tx, currentUser } = props;
  // const { customer, provider } = tx;

  const isBuyer = currentUser?.id?.uuid === tx.customer.id.uuid

  let otherUser = isBuyer ? tx.provider : tx.customer

  let otherUserDisplayName = isBuyer ? tx.provider.attributes.profile.displayName : tx.customer.attributes.profile.displayName

  let messageCount = tx.messages.length - 1;

  let lastMessage = tx.messages[messageCount].attributes.content;

  let messageTimeStampt = formatDate(intl, "Hoy", tx.messages[messageCount].attributes.createdAt)

  let timeBetweenMessageAndNow = Math.floor(Math.abs(new Date() - tx.messages[messageCount].attributes.createdAt) / 36e5)

  const minutes = parseInt(Math.abs(tx.messages[messageCount].attributes.createdAt - new Date()) / (1000 * 60) % 60);

  if(timeBetweenMessageAndNow < 1){
    if (minutes === 1){
      messageTimeStampt = `Hace ${minutes} minuto`
    }else{
      messageTimeStampt = `Hace ${minutes} minutos`
    }
  }else if (timeBetweenMessageAndNow < 24 ){
    messageTimeStampt = `Hace ${timeBetweenMessageAndNow}h`
  }

  const isMobileLayout = window.innerWidth < MAX_MOBILE_SCREEN_WIDTH;

  const hasUnreadMessages = isBuyer ? tx.attributes.metadata.unreadMessagesCustomer : tx.attributes.metadata.unreadMessagesProvider

  const imageUrl = tx.listing.images && 
    tx.listing.images[0] && 
    tx.listing.images[0].attributes && 
    tx.listing.images[0].attributes.variants && 
    tx.listing.images[0].attributes.variants['square-small'] &&
    tx.listing.images[0].attributes.variants['square-small'].url;

  return (
    <div
      className={hasUnreadMessages ? css.unreadMessageContainer: css.orderContainer}
    >
      <div className={css.orderDetailRow}>
        <NamedLink
          name={isBuyer ? 'MessageCustomerDetailsPage' : 'MessageProviderDetailsPage'}
          params={{ id: tx.id.uuid }}
          className={css.fullWidth}
        >
        

        {isMobileLayout ? <Avatar user={otherUser} className={css.avatarMobile} disableProfileLink /> : <AvatarLarge user={otherUser} disableProfileLink />}

        <div className={css.orderDetailFlex}>
          <div className={css.orderDetailListing}>
            <p className={hasUnreadMessages ? css.orderDetailUnread : css.orderDetail}>{otherUserDisplayName}</p>
            <p className={hasUnreadMessages ? css.orderDetailUnread : css.orderDetail}>{lastMessage}</p>
          </div>
          <div className={css.orderDetailTimeStamps}>
            {messageTimeStampt}
          </div>
        </div>
        <div className={css.orderDetailListingImage}>          
          {isMobileLayout ? <img
            src={imageUrl}
            width="40"
            height="40"
            alt="Imágen producto"
          /> : <img
          src={imageUrl}
          width="100"
          height="100"
          alt="Imágen producto"
        />}
        </div>
        <div className={css.orderDetailArrow}><Arrow/></div>
        </NamedLink>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  currentUser: propTypes.currentUser,

};

export const MessagePageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    scrollingDisabled,
    onSetApiReceived,
  } = props;

  let { transactions} = props;

  transactions = transactions.filter(transaction => {
    let disabled = [
      'transition/expire-payment',
      'transition/expire-payment-quick-purchase',
    ];
    return !disabled.includes(transaction.attributes.lastTransition);
  })

  const ensuredCurrentUser = ensureCurrentUser(currentUser);


  const title = intl.formatMessage({ id: 'MessagePage.ordersTitle' });

  const toTxItem = tx => {
    if (tx.listing.attributes.title === null) {
      return null;
    }
    const stateData = txState(intl, tx);

    // Render MessageItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <MessageItem unitType={unitType} tx={tx} intl={intl} onSetApiReceived={onSetApiReceived} currentUser={currentUser}/>
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="MessagePage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={'MessagePage.noOrdersFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
  !fetchInProgress && (hasOrderOrSaleTransactions(transactions, true, ensuredCurrentUser) || hasOrderOrSaleTransactions(transactions, false, ensuredCurrentUser))

  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="MessagePage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  return (
    <Page title={title} className={css.root} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="MessagePage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav />
        <LayoutWrapperMain>
          <div className={css.content}>
            {error}
            <h2 className={css.inboxTitle}>{'Mensajes'}</h2>
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                transactions.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

MessagePageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

MessagePageComponent.propTypes = {
  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.MessagePage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;

  //sorts transactions by last mesasage received
  let transactions = getMarketplaceEntities(state, transactionRefs);

  transactions = transactions.filter(transaction => transaction.messages.length !== 0)
  transactions = transactions.sort(function(a,b){
    return b.messages[b.messages.length-1]?.attributes?.createdAt - a.messages[a.messages.length-1]?.attributes?.createdAt 
  });
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: transactions,
  };
};

const MessagePage = compose(
  connect(mapStateToProps),
  injectIntl
)(MessagePageComponent);

MessagePage.loadData = loadData;

export default MessagePage;
