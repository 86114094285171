import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ReloveIconFriends = props => {

    return ( 
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="&#240;&#159;&#166;&#134; emoji &#34;two women holding hands&#34;">
<path id="Vector" d="M48.3613 11.093V26.946H26.7064V11.093C26.7064 -3.69768 48.3613 -3.69768 48.3613 11.093Z" fill="#7C6F51"/>
<path id="Vector_2" d="M46.0732 34.6274L40.0262 24.3311C39.0456 22.6967 41.3336 21.7978 42.2325 23.4322L48.2795 33.7285L46.0732 34.6274Z" fill="#FF4848"/>
<g id="Group">
<path id="Vector_3" d="M46.0732 34.6277C47.2172 36.5889 47.7892 36.5072 48.3612 36.1803C49.015 35.9352 49.4236 35.6083 48.2795 33.6471L46.0732 34.6277ZM47.7075 14.1986C47.7075 11.1751 45.4194 12.7277 45.4194 12.7277C45.5829 8.47841 43.6217 8.06982 43.6217 8.06982C34.3877 8.06982 29.6481 12.7277 29.6481 12.7277C29.6481 12.7277 27.3601 11.1751 27.3601 14.1986C27.3601 15.3426 27.7687 16.3232 29.2396 16.4049C29.8933 21.0628 32.9985 22.0434 37.5747 22.0434C42.1508 22.0434 45.1743 21.0628 45.828 16.4049C47.2989 16.3232 47.7075 15.3426 47.7075 14.1986Z" fill="#F3D97E"/>
<path id="Vector_4" d="M35.1231 20.7354H39.9444V24.3309H35.1231V20.7354ZM39.209 49.0093V36.6701C39.209 34.7089 41.7422 34.7089 41.7422 36.6701V49.0093H39.209Z" fill="#F3D97E"/>
</g>
<path id="Vector_5" d="M38.2284 47.3748C39.0456 46.476 41.9056 46.476 42.7228 47.3748V49.0092H38.2284V47.3748Z" fill="#FF4848"/>
<path id="Vector_6" d="M33.4071 49.0098V36.6706C33.4071 34.7094 35.9403 34.7094 35.9403 36.6706V49.0098H33.4071Z" fill="#F3D97E"/>
<path id="Vector_7" d="M36.8392 47.3749C36.022 46.476 33.1619 46.476 32.3448 47.3749V49.0092H36.8392V47.3749ZM40.7616 22.6147H39.9444C39.9444 23.3502 38.8821 23.9222 37.4929 23.9222C36.1855 23.9222 35.0414 23.3502 35.0414 22.6147H34.2243C33.0802 23.5136 33.2437 27.7629 32.3448 32.0122C31.2825 37.242 29.403 40.5107 29.403 40.5107H45.4194C45.4194 40.5107 43.54 37.242 42.4776 32.0122C41.8239 27.6812 41.9056 23.4319 40.7616 22.6147Z" fill="#FF4848"/>
<path id="Vector_8" d="M0.638763 11.093V26.946H22.2119V11.093C22.2937 -3.69768 0.638763 -3.69768 0.638763 11.093Z" fill="#7C6F51"/>
<g id="Group_2">
<path id="Vector_9" d="M1.29251 14.1983C1.29251 11.1748 3.58057 12.7274 3.58057 12.7274C7.25782 10.4393 7.25782 6.35352 7.25782 6.35352C7.6664 10.0308 19.3519 12.7274 19.3519 12.7274C19.3519 12.7274 21.6399 11.1748 21.6399 14.1983C21.6399 15.3423 21.2314 16.3229 19.7605 16.4047C19.1067 21.0625 16.0015 22.0431 11.4254 22.0431C6.84923 22.0431 3.744 21.0625 3.17199 16.4047C1.70109 16.3229 1.29251 15.3423 1.29251 14.1983ZM2.92684 34.6275L8.97387 24.3312C9.95446 22.6968 7.6664 21.798 6.76752 23.4323L0.72049 33.7286L2.92684 34.6275Z" fill="#F3D97E"/>
<path id="Vector_10" d="M2.92682 34.6275C1.78279 36.5887 1.21077 36.507 0.638758 36.1801C-0.0149747 35.9349 -0.423558 35.6081 0.720475 33.6469L2.92682 34.6275ZM22.5388 32.6663L13.6317 24.6581C12.2425 23.4323 14.0403 21.8797 15.5112 23.1054L24.4183 31.1137L22.5388 32.6663Z" fill="#F3D97E"/>
<path id="Vector_11" d="M22.5388 32.6659C24.2548 34.2185 24.7451 33.8916 25.2354 33.4831C25.7257 33.0745 26.0526 32.5842 24.3366 31.1133L22.5388 32.6659Z" fill="#F3D97E"/>
</g>
<path id="Vector_12" d="M26.0526 32.6663L34.9597 24.6581C36.3489 23.4323 34.5511 21.8797 33.0802 23.1054L24.1731 31.1137L26.0526 32.6663Z" fill="#FF4848"/>
<path id="Vector_13" d="M26.0526 32.666C24.3366 34.2186 23.8463 33.8917 23.356 33.4831C22.8657 33.0746 22.5388 32.5843 24.2549 31.1134L26.0526 32.666ZM9.05558 20.7354H13.8769V24.3309H9.05558V20.7354ZM9.79103 49.0093V36.6701C9.79103 34.7089 7.25781 34.7089 7.25781 36.6701V49.0093H9.79103Z" fill="#F3D97E"/>
<path id="Vector_14" d="M10.7716 47.3748C9.95444 46.476 7.09436 46.476 6.27719 47.3748V49.0092H10.7716V47.3748Z" fill="#C28FEF"/>
<path id="Vector_15" d="M15.5929 49.0098V36.6706C15.5929 34.7094 13.0597 34.7094 13.0597 36.6706V49.0098H15.5929Z" fill="#F3D97E"/>
<path id="Vector_16" d="M12.1608 47.3749C12.978 46.476 15.838 46.476 16.6552 47.3749V49.0092H12.1608V47.3749ZM8.2384 22.6147H9.05557C9.05557 23.3502 10.1179 23.9222 11.5071 23.9222C12.8145 23.9222 13.9586 23.3502 13.9586 22.6147H14.7757C15.9198 23.5136 17.1455 38.1409 17.1455 38.1409H5.86862C5.86862 38.1409 7.09437 23.4319 8.2384 22.6147Z" fill="#C28FEF"/>
<g id="Group_3">
<path id="Vector_17" d="M33.8157 15.179C34.6732 15.179 35.3683 14.4838 35.3683 13.6263C35.3683 12.7689 34.6732 12.0737 33.8157 12.0737C32.9582 12.0737 32.2631 12.7689 32.2631 13.6263C32.2631 14.4838 32.9582 15.179 33.8157 15.179Z" fill="#937237"/>
<path id="Vector_18" d="M41.3336 15.179C42.1911 15.179 42.8862 14.4838 42.8862 13.6263C42.8862 12.7689 42.1911 12.0737 41.3336 12.0737C40.4761 12.0737 39.781 12.7689 39.781 13.6263C39.781 14.4838 40.4761 15.179 41.3336 15.179Z" fill="#937237"/>
<path id="Vector_19" d="M40.0262 18.2839C38.4736 19.5096 36.6758 19.5096 35.1232 18.2839C34.9597 18.1204 34.7963 18.3656 34.878 18.6107C35.3683 19.5096 36.3489 20.2451 37.5747 20.2451C38.8004 20.2451 39.781 19.4279 40.2713 18.6107C40.4348 18.4473 40.2713 18.2022 40.0262 18.2839Z" fill="#937237"/>
<path id="Vector_20" d="M7.58466 15.179C8.44215 15.179 9.13727 14.4838 9.13727 13.6263C9.13727 12.7689 8.44215 12.0737 7.58466 12.0737C6.72717 12.0737 6.03204 12.7689 6.03204 13.6263C6.03204 14.4838 6.72717 15.179 7.58466 15.179Z" fill="#937237"/>
<path id="Vector_21" d="M15.1026 15.179C15.9601 15.179 16.6552 14.4838 16.6552 13.6263C16.6552 12.7689 15.9601 12.0737 15.1026 12.0737C14.2451 12.0737 13.55 12.7689 13.55 13.6263C13.55 14.4838 14.2451 15.179 15.1026 15.179Z" fill="#937237"/>
<path id="Vector_22" d="M13.7951 18.2839C12.2425 19.5096 10.4447 19.5096 8.89213 18.2839C8.7287 18.1204 8.56526 18.3656 8.64698 18.6107C9.13728 19.5096 10.1179 20.2451 11.3436 20.2451C12.5694 20.2451 13.55 19.4279 14.0403 18.6107C14.122 18.4473 13.9586 18.2022 13.7951 18.2839Z" fill="#937237"/>
</g>
</g>
</svg>

    )

}

ReloveIconFriends.defaultProps = {
    rootClassName: null,
    className: null,
  };
  
const { string } = PropTypes;

ReloveIconFriends.propTypes = {
    rootClassName: string,
    className: string,
};

export default ReloveIconFriends;
  