import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconComment.css';

const ReloveIconComment = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      width={24}
      className={classes}
    >
      <g>
        <g>
          <path
            fill="#25D366"
            d="M246,118.5c0,55-52.9,99.6-118.1,99.6c-18.1,0-35-3.4-50.4-9.5l-44.7,28.5l7-52.1C21.4,167.4,10,144,10,118.5c0-55,52.9-99.6,118.1-99.6C193.1,18.8,246,63.5,246,118.5z M46.7,215.5l25.3-16l9.7-0.9c14.4,5.7,30.1,8.7,46.4,8.7c59.1,0,107.3-39.9,107.3-88.8c0-48.9-48.1-88.8-107.3-88.8C69,29.7,20.8,69.4,20.8,118.5c0,21.5,9.5,42.4,26.6,58.6l3.2,9.3L46.7,215.5z"
          />
          <path
            fill="#25D366"
            d="M62.5,113.9c0,7.7,6.2,13.9,13.9,13.9c7.7,0,13.9-6.2,13.9-13.9c0-7.7-6.2-13.9-13.9-13.9C68.7,100,62.5,106.3,62.5,113.9L62.5,113.9z"
          />
          <path
            fill="#25D366"
            d="M117.5,113.9c0,7.7,6.2,13.9,13.9,13.9c7.7,0,13.9-6.2,13.9-13.9c0-7.7-6.2-13.9-13.9-13.9C123.7,100,117.5,106.3,117.5,113.9L117.5,113.9z"
          />
          <path
            fill="#25D366"
            d="M172.4,113.9c0,7.7,6.2,13.9,13.9,13.9s13.9-6.2,13.9-13.9c0-7.7-6.2-13.9-13.9-13.9C178.6,100,172.4,106.3,172.4,113.9L172.4,113.9z"
          />
        </g>
      </g>
    </svg>
  );
};

ReloveIconComment.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconComment.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconComment;
