/**
 Use this file to edit the Quick Links on the header.
 Each trend is enclosed in curly braces ({}) followed by a comma (,), and contains the following elements:
 - id: an unique id, can be a number or a 'text' (enclosed in apostrophes)
 - name: The title for the Trend (enclosed in apostrophes)
 - image: An URL to the image you want to use (enclosed in apostrophes)
 - link: An URL to whatever you want the trend to take you to. It's preferred to used relative links (ej. '/s?keywords=Something' or '/u/123012739127301927310')
 Each of these elements is also followed by a comma (,).
*/


export const links = [
  {
    key: 'Vestidos',
    label: 'Vestidos formales',
    options: [
      { key: 'Vestidos,A.L.C.,AFRM,Agua%20Bendita,AIIFOS,ALALA,Alexander%20Wang,Alexis,Alice+Olivia,Alice%20McCall,ALLSAINTS,AMUR,ANINE%20BING,ASTR%20the%20Label,AYNI,Azeeza,AZULU,BALMAIN,Baobab,Black%20and%20Bone,Bardot,BCBGeneration,BCBGMAXAZRIA,Beach%20Bunny,BEACH%20RIOT,Bec%20and%20Bridge,Bobi,Bronx%20and%20Banco,by%20the%20way.,BY.DYLN,C/MEO,C/MEO%20Collective,Camila%20Coelho,Carolina%20Herrera,Cinq%20a%20Sept,Citizens%20of%20Humanity,Cult%20Gaia,CULTNAKED,Cruella,DANNIJO,David%20Koma,DEVON%20WINDSOR,Diane%20von%20Furstenberg,Dion%20Lee,Dodo%20Bar%20Or,Dundas,ELLIATT,Favorite%20Daughter,FIORUCCI,leur%20du%20mal,Flook%20The%20Label,FRAME,Free%20People,Ganni,GIUSEPPE%20DI%20MORABITO,h:ours,Hansen%20+%20Gretel,House%20of%20Harlow%201960,Isabel%20Marant%20Etoile,Jacquemus,J.Angelique,Jay%20Godfrey,JILL%20JILL%20STUART,JONATHAN%20SIMKHAI,Katie%20May,keepsake,KENDALL%20+%20KYLIE,LAcademie,Luciana%20Balderrama,Leal%20Daccarett,LAGENCE,LIKELY,Lovers%20and%20Friends,LoveShackFancy,LPA,Maaji,Michelle%20Mason,Monique%20Lhuillier,Marchesa%20Notte,MAJORELLE,Maria%20Lucia%20Hohan,Marissa%20Webb,Michael%20Costello,MILLY,MISHA,Missoni,Nanushka,Natalie%20Martin,NBD,NICHOLAS,Nookie,Norma%20Kamali,Not%20Yours%20To%20Keep,Nora%20Parada,Nue%20Studio,Oceanus,OFF-WHITE,PatBO,Rixo,Rat%20and%20Boa,retrofete,Reformation,Revolve,Sabina%20Musayev,SALONI,Sanctuary,SAU%20LEE,SAYLOR,Shona%20Joy,Significant%20Other,SOLACE%20London,Something%20Navy,Song%20of%20Style,Splendid,superdown,The%20Sei,THE%20UPSIDE,Theory,Tularosa,Ulla%20Johnson,V.Chapman,Varley,Valeria%20Larr,Veronica%20Beard,VERSACE,Versace%20Jeans%20Couture,Vince,WeWoreWhat,X%20by%20NBD,YAURA,YAURA,Zimmermann', label: 'Top marcas'},
      { key: 'Vestidos', label: 'Todos los vestidos'},
      { key: 'Vestidos-Largos', label: 'Largos' },
      { key: 'Vestidos-Mini', label: 'Mini' },
      { key: 'Vestidos-Midi', label: 'Midi' },
      { key: 'Vestidos-MangaLarga', label: 'Manga larga' },
      { key: 'Vestidos-Bodas', label: 'Novia/Bridal' },
    ]
  },
  {
    key: 'Vestidos-Casuales,Tops,Faldas,Pantalones,Jeans,Shorts,Jumpsuits,Playa,Chamarras,Sets,Deportiva,Lenceria,Maternidad',
    label: 'Ropa',
    options: [
      {   key: 'Vestidos-Casuales,Tops,Faldas,Pantalones,Jeans,Shorts,Jumpsuits,Playa,Chamarras,Sets,Deportiva,Lenceria,Maternidad',
          label: 'Toda la ropa',
      },
      { key: 'Vestidos-Casuales', label: 'Vestidos casuales'},
      { key: 'Tops', label: 'Tops' },
      { key: 'Faldas', label: 'Faldas' },
      { key: 'Pantalones', label: 'Pantalones' },
      { key: 'Jeans', label: 'Jeans' },
      { key: 'Shorts', label: 'Shorts' },
      { key: 'Chamarras', label: 'Chamarras, Sacos, Abrigos' },
      { key: 'Deportiva', label: 'Ropa deportiva' },
      { key: 'Jumpsuits', label: 'Monos y Jumpsuits' },
      { key: 'Playa', label: 'De baño'},
      // { key: 'Tops-Sweaters', label: 'Suéters' },
      { key: 'Sets', label: 'Sets' },
      // { key: 'Lenceria', label: 'Lencería' },
      // { key: 'Maternidad', label: 'Ropa de Maternidad' },
    ]
  },
  {
    key: 'Bolsas',
    label: 'Bolsas',
    options: [
      { key: 'Bolsas,ALEXANDER%20MCQUEEN,Alexander%20Wang,ALLSAINTS,ALO%20YOGA,BALENCIAGA,Bimba%20y%20Lola,BOTTEGA%20VENETA,BURBERRY,Carolina%20Herrera,Chanel,CHLOE,christian%20louboutin,Coach,COMME%20DES%20GARCONS,Cult%20Gaia,CULT%20GAIA,Dior,Fendi,FRAME,Free%20People,Ganni,Giuseppe%20Aanotti,GIUSEPPE%20DI%20MORABITO,GIVENCHY,Gucci,Isabel%20Marant,ISABEL%20MARANT,JACQUEMUS,jw%20pei,LOEWE,Marc%20Jacobs,MIU%20MIU,MONCLER,Nike,Nanushka,MUGLER,OFF-WHITE,ON,Prada,Rebecca%20Minkoff,RETROFETE,SAINT%20LAURENT,SIMON%20MILLER,STAUD,STELLA%20MCCARTNEY,THE%20ATTICO,THE%20ROW,TOM%20FORD,VALENTINO%20GARAVANI,VERSACE,YSL,Varley', label:'Top marcas'},
      { key: 'Bolsas', label: 'Todas las bolsas' },
      { key: 'Bolsas-Bandolera', label: 'Crossbody' },
      { key: 'Bolsas-Shopper', label: 'Totes' },
      { key: 'Bolsas-Mano', label: 'Bolsas de mano' },
      { key: 'Bolsas-Clutchs', label: 'Clutchs y de noche' },
      { key: 'Bolsas-Mochilas', label: 'Mochilas' },
      { key: 'Bolsas-Carteras/Monederos', label: 'Carteras y Monederos' },
      { key: 'Bolsas-Rinoneras', label: 'Riñoneras' },
      { key: 'Bolsas-Cosmetiquera', label: 'Otras' },
    ]
  },
  {
    key: 'Zapatos',
    label: 'Zapatos',
    options: [
      { key: 'Zapatos,adidas%20Originals,Alexander%20Wang,ALLSAINTS,ALOHAS,BCBGeneration,BIRKENSTOCK,Bronx%20and%20Banco,Camila%20Coelho,Coach,Common%20Projects,COMME%20DES%20GARCONS,Converse,Cult%20Gaia,Dolce%20Vita,FEMME%20LA,Fila,Ganni,Giuseppe%20Zanotti,Golden%20Goose,Good%20American,Gucci,Isabel%20Marant,Jeffrey%20Campbell,KENDALL%20+%20KYLIE,Nike,OFF-WHITE,On,Prada,Rebecca%20Minkoff,Sam%20Edelman,Schutz,See%20By%20Chloe,Song%20of%20Style,Steve%20Madden,Stuart%20Weitzman,stella%20mccartney,Tony%20Bianco,Vans,Veja,Versace%20Jeans%20Couture,YEEZY,yves%20saint%20laurent,YSL,Zimmermann', label:'Top marcas'},
      { key: 'Zapatos', label: 'Todos los zapatos'},
      { key: 'Zapatos-Tacones', label: 'Tacones' },
      { key: 'Zapatos-SandaliasTacon', label: 'Sandalias de tacón' },
      { key: 'Zapatos-Sandalias', label: 'Sandalias' },
      { key: 'Zapatos-Flats', label: 'Zapatillas y Flats' },
      { key: 'Zapatos-Mocasines', label: 'Mocasines' },
      { key: 'Zapatos-Plataformas', label: 'Plataformas' },
      { key: 'Zapatos-Tenis', label: 'Tenis casuales' },
      { key: 'Zapatos-Deportivos', label: 'Tenis deportivos' },
      { key: 'Zapatos-Botas', label: 'Botas y Botines' },
      { key: 'Zapatos-BotasTacon', label: 'Botas y Botines de tacón' },
    ]
  },
  {
    key: 'Accesorios',
    label: 'Accesorios',
    options: [
      { key: 'Accesorios', label: 'Todos los accesorios'},
      { key: 'Accesorios-LentesOpticos%2CAccesorios-LentesSol', label: 'Lentes' },
      { key: 'Accesorios-Collares%2CAccesorios-Aretes%2CAccesorios-Anillos%2CAccesorios-Pulseras', label: 'Joyería' },
      { key: 'Accesorios-Reloj', label: 'Relojes' },
      { key: 'Accesorios-Tocados', label: 'Tocados'},
      { key: 'Accesorios-Pelo/Sombreros', label: 'Sombreros y Accesorios de pelo' },
      { key: 'Accesorios-Cinturones', label: 'Cinturones' },
      { key: 'Accesorios-Panuelos', label: 'Pañuelos' },
      { key: 'Accesorios-Bufandas', label: 'Bufandas y Guantes' },
      { key: 'Accesorios-Calcetines', label: 'Calcetines y Medias' },
    ]
  },
]
