import React, {useState} from 'react'
import { InlineTextButton, Button } from '../../components';

import css from './ReferralComponent.css';

const ReferralComponent = props => {

	const {
		onCheckAndValidateReferralCode,
		validationInProgress,
		validationError,
		referredCode,
		referredCodeValidation,
		isFirstBuy
	} = props;

	const [referralCode, setReferralCode] = useState(null);
	const [openReferralTextBox, setOpenReferralTextBox] = useState(false)

	const handleInputChange = (e) => {
		setReferralCode(e.target.value.toLowerCase())
	}

  return (
	<>
	{!referredCode && isFirstBuy && (
		<div className={css.referralMainContainer}>
			<InlineTextButton className={css.referralButton} onClick={() => setOpenReferralTextBox(true)} >¿Tienes código de referidos?</InlineTextButton>
					{openReferralTextBox && (
				<div className={css.referralCodeContainer}>
					<input
					type="text"
					value={referralCode}
					onChange={handleInputChange}
					placeholder="código"
					className={css.referralCodeInput}
					/>
					<Button 
						className={css.referralCodeCopyButton} 
						onClick={() => onCheckAndValidateReferralCode(referralCode)}
						inProgress={validationInProgress}
					>
						Validar
					</Button>
					
				</div>
				)}
			{!referredCode && (referredCodeValidation === 'does-not-exist' ? (<div className={css.validationErrorMessage}>Código no encontrado</div>) : referredCodeValidation === 'under-500' && (<div className={css.validationErrorMessage}>Aplica para compras mayores a $500</div>))}
		</div>
	)}
	</>
  )
}

export default ReferralComponent