import React from 'react';
import css from './SectionSocialButtonActions.css';
import { Button, ReloveIconFavoriteLike, ReloveIconShareListing } from '../../components';
import cssPage from './ListingPage.css'

const SectionSocialButtonActions = ({ isLikedByCurrentUser, onClickLike, onShare ,onCloseShare,isSharing }) => {

    const shareOptions = isSharing ? (<div className={cssPage['share-dialog']}>
    <header className={cssPage.shareHeader}>
      <h3 className={cssPage['close-button']}>Compartir "{window.document.title}"</h3>
      <button className={cssPage['close-button']} onClick={onCloseShare}>
        x
        <svg className={cssPage.svg}>
          <use href="#close"></use>
        </svg>
      </button>
    </header>
    <div className={cssPage.targets}>
      <button className={cssPage.button} onClick={() => {
        window.open('http://www.facebook.com/sharer.php?u=' + window.location.href,'Share', 'toolbar=0, status=0, width=650, height=450');
      }}>
        <svg className={cssPage.svg}>
          <use href="#facebook"></use>
        </svg>
        <span>Facebook</span>
      </button>

      <button className={cssPage.button} onClick={() => {
        window.open('http://twitter.com/home?status=' + encodeURIComponent("Mira esta prenda " + window.location.href),'Share', 'toolbar=0, status=0, width=650, height=450');
      }}>
        <svg className={cssPage.svg}>
          <use href="#twitter"></use>
        </svg>
        <span>Twitter</span>
      </button>
    </div>
    <div className={cssPage.link}>
      <div className={cssPage['pen-url']}>{window.location.href}</div>
      <button className={cssPage['copy-link']} onClick={() => {
        const el = window.document.createElement('textarea');
        el.value = window.location.href;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }}>Copiar Enlace</button>
    </div>
  </div>) : null;



    return (
        <div className={css.containerSocialButtonActions}>
            {shareOptions}
            <Button
                onClick={onClickLike}
                rootClassName={css.buttonSocialButtonIconLike}
            >
                <ReloveIconFavoriteLike className={css.socialButtonIconLike} isFull={isLikedByCurrentUser} />
                <p>Guardar en mis likes</p>
            </Button>
            <Button
                onClick={onShare}
                rootClassName={css.buttonSocialButtonIconLike}
            >
                <ReloveIconShareListing className={css.socialButtonIconLike} />
                <p>Compartir</p>
            </Button>


        </div>
    )
}

export default SectionSocialButtonActions