import React from 'react';
import { Field } from 'react-final-form';
import Multiselect from 'react-widgets/lib/Multiselect'
import classNames from 'classnames';
import { ValidationError } from '../../../components';

import css from './ReloveEditListingForm.css';

let SizeItem = ({ item }) => (
  <span>
    {item.group ? (<strong>{item.group}: </strong>) : ''}
    {item.label}
  </span>
);


const ReloveSizeSelectComponent = props => {
  const {
    className,
    id,
    label,
    options,
    placeholder,
    input,
    meta,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.size, {
    [css.sizeSuccess]: valid,
    [css.sizeError]: hasError,
  });
  const inputProps = { className: selectClasses, id, ...input, ...rest };

  return options ? (
    <section className={classNames(className, css.sizeContainer)} >
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Multiselect
        value={input.value || []}
        containerClassName={selectClasses} 
        data={options}
        tagComponent={SizeItem}
        valueField="key"
        textField="label"
        placeholder={placeholder}
        inputProps={inputProps}
        groupBy={item => item.group ? item.group : ''}
        messages={{
          emptyFilter: 'Parece que no tenemos esa opción',
          createOption: props => 'Agregar opción: "' + props.searchTerm + '"'
        }}
        onChange={items => {
          if(items.length <= 2)
            input.onChange(items)
        }}
        onCreate={item => {
          if(input.value.length < 2) {
            const values = [{key: item, label: item, group: ''}, ...input.value];
            input.onChange(values)
          }
        }}
      />
      <ValidationError fieldMeta={meta} />
    </section>
  ) : null;
}

const ReloveSizeSelectField = props => {
  return <Field component={ReloveSizeSelectComponent} {...props} />;
};

export default  ReloveSizeSelectField;
