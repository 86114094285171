 import React, { Component } from 'react';
 import { bool, func, object, string } from 'prop-types';
 import { injectIntl, intlShape } from '../../util/reactIntl';
 import { Form as FinalForm } from 'react-final-form';
 import { propTypes } from '../../util/types';

 import {
   Form,
   IconCheckmark,
   InlineTextButton,
   Menu,
   MenuLabel,
   MenuItem,
   MenuContent,
   IconPaypal,
 } from '../../components';

 import css from './PaypalPaymentForm.css';

 /**
  * Translate a Conekta API error object.
  *
  * To keep up with possible keys from the Conekta API, see:
  *
  * https://conekta.com/docs/api#errors
  *
  * Note that at least at moment, the above link doesn't list all the
  * error codes that the API returns.
  *
  * @param {Object} intl - react-intl object from injectIntl
  * @param {Object} conektaError - error object from Conekta API
  *
  * @return {String} translation message for the specific Conekta error,
  * or the given error message (not translated) if the specific error
  * type/code is not defined in the translations
  *
  */
 class PaypalPaymentForm extends Component {
   constructor(props) {
     super(props);
     this.handleSubmit = this.handleSubmit.bind(this);

   }

   handleSubmit() {


    this.props.onSubmit();
  }
 
   paymentForm(formRenderProps) {
     const {
       hasPaypalEmail,
       payeeEmail
     } = formRenderProps;


     const defaultCard = (
      <div className={css.savedPaymentMethod}>
        <IconPaypal className={css.cardIcon} />
        {payeeEmail}
      </div>
    );
     
     return hasPaypalEmail && (
       <Form className={css.dummyForm} onSubmit={() => void 0} >
      <Menu className={css.menu} isOpen={false} onToggleActive={() => console.log("active")} useArrow={false}>
        <MenuLabel className={css.menuLabel}>
          <div className={false ? css.menuLabelWrapperExpired : css.menuLabelWrapper}>
            {defaultCard}
          </div>
        </MenuLabel>

        <MenuContent rootClassName={css.menuContent}>
          <MenuItem key="first item" className={css.menuItem}>
            <IconCheckmark
              className={true ? css.iconCheckmark : css.iconCheckmarkHidden}
              size="small"
            />
            <InlineTextButton className={css.menuText} onClick={() => console.log("click")}>
              {defaultCard}
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>


       </Form>
       
     )
   }
 
   render() {
     const { onSubmit, ...rest } = this.props;
     return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />
   }
 }
 
 PaypalPaymentForm.defaultProps = {
   className: null,
   rootClassName: null,
   inProgress: false,
   loadingData: false,
   showInitialMessageInput: true,
   hasHandledCardPayment: false,
   defaultPaymentMethod: null,
   initiateOrderError: null,
   handleCardPaymentError: null,
   confirmPaymentError: null,
   isOffer: false,
 };
 
 PaypalPaymentForm.propTypes = {
   className: string,
   rootClassName: string,
   inProgress: bool,
   loadingData: bool,
   initiateOrderError: object,
   handleCardPaymentError: object,
   confirmPaymentError: object,
   formId: string.isRequired,
   intl: intlShape.isRequired,
   onSubmit: func.isRequired,
   paymentInfo: string.isRequired,
   authorDisplayName: string.isRequired,
   showInitialMessageInput: bool,
   hasHandledCardPayment: bool,
   defaultPaymentMethod: propTypes.defaultPaymentMethod,
   isOffer: bool,
 };
 
 export default injectIntl(PaypalPaymentForm, { forwardRef: true });