import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldPhoneNumberInput, FieldSelectSimple } from '../../components';
import { propTypes } from '../../util/types';

import css from './VerificationForm.css';
import { omitCodesPhone } from '../../util/codePhone';
import { logEvent } from '../../util/logsEvent';

const handleSubmitVerification = onSubmit => async values => {
  const { phoneNumber, countryCode } = values;
  await onSubmit({ phoneNumber: countryCode + phoneNumber });
};

const VerificationFormComponent = props => {
  useEffect(() => {
    logEvent('Phone Verification Displayed');
  }, []);

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmitVerification(props.onSubmit)}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          formId,
          handleSubmit,
          inProgress,
          intl,
          sendEnquiryError,
          success,
          alreadyTakenPhone,
          changePhoneNumber,
          disabled,
        } = fieldRenderProps;
        const phonePlaceholder = intl.formatMessage({
          id: 'ContactDetailsForm.phonePlaceholder',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;
        const codesPhone = omitCodesPhone(['MX']);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {/* <div className={css.title}>Gloset</div> */}
            <div className={css.label}>¡Verifica que eres tú!</div>
            <br></br>
            <div className={css.wrapper}>
              <FieldSelectSimple
                defaultValue={'+521'}
                id="countryCode"
                name="countryCode"
                className={css.contryCode}
              >
                <option value="+521" selected="selected">
                  MX(+521)
                </option>

                {codesPhone.map(codePhone => {
                  return (
                    <option
                      key={codePhone.iso}
                      value={`+${codePhone.code}`}
                    >{`${codePhone.iso}(+${codePhone.code})`}</option>
                  );
                })}
              </FieldSelectSimple>
              <div className={css.filler}></div>
              <FieldPhoneNumberInput
                className={css.phone}
                name="phoneNumber"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                maxLength={10}
                // label={phoneLabel}
                placeholder={phonePlaceholder}
                onBlur={e => changePhoneNumber(false)}
              />
            </div>
            {alreadyTakenPhone ? (
              <div className={css.error}>
                El número introducido ya tiene una cuenta asociada, intenta iniciar sesión
              </div>
            ) : null}
            {success ? null : <div>Algo salio mal, vuelve a introducir el número</div>}
            <div className={submitButtonWrapperClassName}>
              {sendEnquiryError ? (
                <p className={css.error}>
                  <FormattedMessage id="EnquiryForm.sendEnquiryError" />
                </p>
              ) : null}
              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}></p>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  Continuar
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

VerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

VerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const VerificationForm = compose(injectIntl)(VerificationFormComponent);

VerificationForm.displayName = 'VerificationForm';

export default VerificationForm;
