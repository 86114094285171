import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { getSoldAttribute } from '../../util/api';
import { getAllFavoritesListings } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/MyFavoritesPage/SET_INITIAL_STATE';
export const QUERY_LISTINGS_REQUEST = 'app/MyFavoritesPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/MyFavoritesPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_PAGE_SUCCESS = 'app/MyFavoritesPage/QUERY_PAGE_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/MyFavoritesPage/QUERY_LISTINGS_ERROR';
export const QUERY_BOUGHT_SUCCESS = 'app/MyFavoritesPage/QUERY_BOUGHT_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  favoritedListingsIds: [],
  boughtListings: [],
  appendedList: [],
  queryListingsError: null,
  queryListingsInProgress: true,
  pagination: null,
};

export default function myFavoritesPageReducer(state = initialState, action = {}) {
	const { type, payload } = action;
  switch (type) {
  	case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        favoritedListingsIds: state.favoritedListingsIds,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        favoritedListingsIds: payload.listings.data,
        pagination: payload.listings.totalPages,
      };
    case QUERY_PAGE_SUCCESS:
      return{
        ...state,
        appendedList: state.appendedList.concat(payload.listings),
        queryListingsInProgress: false,
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, favoritedListingsIds: [], queryListingsError: payload, queryListingsInProgress: false };
    case QUERY_BOUGHT_SUCCESS:
      return { ...state, boughtListings: payload };
  	default:
  		return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryPageSuccess = listings => ({
  type: QUERY_PAGE_SUCCESS,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryBoughtSuccess = listingsId => ({
  type: QUERY_BOUGHT_SUCCESS,
  payload: listingsId,
});

// ================ Thunks ================ //
const FAVORITES_PAGE_SIZE = 100;

export const queryFavoritedListings = (user) => (dispatch, getState, sdk) => {
  dispatch(setInitialState())
  dispatch(queryListingsRequest());
  return  getAllFavoritesListings({user}).then(
    response => {
      dispatch(queryListingsSuccess(response));
      return response.data;
    }
  ).catch(e => console.log(e));
};

export const getListingsPage = (user, listings, page) => (dispatch, getState, sdk) => {


   let listingPageIds = listings?.slice((page)*FAVORITES_PAGE_SIZE, ((page+1)*FAVORITES_PAGE_SIZE))
   const listingPageUUIds = listingPageIds.map(l => {return l.uuid})
  //quitar la parte de quitar los uuids porque no los manda

  //  dispatch(queryListingsRequest());
    const params = {
      ids: listingPageUUIds,
      include: ['author', 'images', 'createdAt', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      'fields.listing': ['title', 'price', 'publicData', 'metadata'],
      'limit.images': 1,
    };

    return sdk.listings
      .query(params)
      .then(response => {
        // Pick only the id and type properties from the response listing

        dispatch(addMarketplaceEntities(response));
        dispatch(queryPageSuccess(listingPageIds));
        return response;
      })
      .then(response => {
        return getSoldAttribute({ user }).then(res => {
          dispatch(queryBoughtSuccess(res));
          return response;
        });
      })
    .catch(e => dispatch(queryListingsError(storableError(e))));
}

export const loadInitialData = (user, listings, page) => (dispatch, getState, sdk) => {
  
}