import React from 'react';
// import { Button } from '@material-ui/core';
import {ModalInMobile } from '..';
import { CheckVerificationForm } from '../../forms';
 
import css from './CheckVerificationSection.css';
import { logEvent } from '../../util/logsEvent';

const CheckVerificationSection = props => {
  const {
    sendEnquiryInProgress,
    onSubmitEnquiry,
    resendCode,
    onManageDisableScrolling,
    onCloseEnquiry,
    success,
    maxAttempts,
    maxAttemptsChange,
    useModal,
    inProgress,
    disabled
  } = props;

  const handleOnSubmitEnquire = (values)=>{
    onSubmitEnquiry(values);
    logEvent('Phone Verification Code Submitted')
  }

  if(useModal){
    return (
      <div>
          <ModalInMobile
          id="UniqueIdForThisAddressAlert"
          isOpen={true}
          onClose={onCloseEnquiry}
          usePortal
          contentClassName={css.modalContent}
          onManageDisableScrolling={onManageDisableScrolling}
          >
          <CheckVerificationForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          onSubmit={handleOnSubmitEnquire}
          resendCode={resendCode}
          inProgress={sendEnquiryInProgress}
          onOpenTermsOfService={() => {
            return
          }}
          onOpenPrivacyPolicy={() => {
            return
          }}
          success={success}
          maxAttempts={maxAttempts}
          maxAttemptsChange={maxAttemptsChange}
          />
        </ModalInMobile>
      </div>
    )
  }else{
    return (
      <div>
          <CheckVerificationForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          onSubmit={onSubmitEnquiry}
          resendCode={resendCode}
          onOpenTermsOfService={() => {
            return
          }}
          onOpenPrivacyPolicy={() => {
            return
          }}
          success={success}
          maxAttempts={maxAttempts}
          maxAttemptsChange={maxAttemptsChange}
          inProgress={inProgress}
          disabled={disabled}
          />
      </div>
    )
  }
}
export default CheckVerificationSection;