import React from 'react'
// import { Avatar, NamedLink } from '../../components'

import css from './ListingPage.css';

const LikesSection = props => {
	const {
		likedBy
	} = props;

	if (!Array.isArray(likedBy) || likedBy.length < 1) {
		return null;
	}


	const nameList = (
		<div className={css.likesNameList}>
			<span> A {likedBy.length} personas les gusta esto</span>
		</div>
	);

	return (
		<div className={css.likesSection} >
			{nameList}
		</div>
	);
};

LikesSection.defaultProps = {
	likedBy: [],
};

export default LikesSection;