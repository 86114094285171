import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconProtection.css';

const ReloveIconProtection = props => {
  const { rootClassName, className } = props;
  const classes = classNames( css.root, className);
  return (
    <svg className={classes}
      width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Warning / Shield_Check">
        <path id="Vector" d="M12.5 7.5L9.16668 10.8333L7.50001 9.16667M16.6667 5.16663V8.47067C16.6667 13.9442 12.5266 16.3982 10.7715 17.1926C10.5854 17.2768 10.493 17.319 10.2824 17.3552C10.1496 17.378 9.85107 17.378 9.71823 17.3552C9.50764 17.319 9.41437 17.2768 9.2283 17.1926C7.47317 16.3982 3.33334 13.9442 3.33334 8.47066V5.16663C3.33334 4.23321 3.33334 3.76655 3.515 3.41003C3.67479 3.09643 3.92957 2.84144 4.24317 2.68166C4.59969 2.5 5.06675 2.5 6.00017 2.5H14.0002C14.9336 2.5 15.4 2.5 15.7565 2.68166C16.0701 2.84144 16.3254 3.09643 16.4852 3.41003C16.6669 3.76655 16.6667 4.23321 16.6667 5.16663Z" stroke="#20923D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>

  );
};

ReloveIconProtection.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconProtection.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconProtection;
