import React from 'react'

import classNames from 'classnames';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import { FormattedMessage } from '../../util/reactIntl';

import {
    Avatar,
    Button,
    Logo,
    NamedLink
} from '../../components';

import css from './TopbarMobile.css'

const TopbarMobile = (props) => {

    const {
        mobileRootClassName,
        intl,
        SearchIcon,
        currentUser,
        isAuthenticated,
        notificationDot,
        MenuIcon,
        mobileClassName,
        handleMobileQuicklinksOpen,
        handleMobileSearchOpen,
        handleMobileProfileMenuOpen
    } = props;

  return (
    <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
    <div className={css.mobileLeft}>
      <Button
        rootClassName={css.quicklinksMenu}
        onClick={handleMobileQuicklinksOpen}
        title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
      >
        <MenuIcon className={css.menuIcon} />
      </Button>
    </div>
    <NamedLink
      className={css.home}
      name="LandingPage"
      title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
      draggable="false"
    >
      <Logo />
    </NamedLink>
    <div className={css.mobileRight}>
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <MonetizationOnOutlinedIcon className={css.createListingIcon} />
        <span className={css.createListing}>
          <FormattedMessage id="Topbar.createListingMobile" />
        </span>
      </NamedLink>
      <Button
        rootClassName={css.menu}
        onClick={handleMobileProfileMenuOpen}
        title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
      >
        {(isAuthenticated && currentUser) ? (
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        ) : (
          <AccountCircleRoundedIcon color="inherit" className={css.menuIcon} style={{ fontSize: 30, margin: 0 }}/>
        )}
        {notificationDot}
      </Button>
    </div>
  </div>
  )
}

export default TopbarMobile