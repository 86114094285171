import React from 'react';
import classnames from 'classnames';
import { NamedLink, Avatar, Button, ReloveIconFavoriteLike } from '../../components';

import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import css from './ListingPage.css';

const formatter = buildFormatter(spanishStrings)

const SectionAvatar = props => {
  const { user, listing, isLikedByCurrentUser,onClickLike , likedBy} = props;
  const displayName = user?.attributes?.profile?.displayName ? user?.attributes?.profile?.displayName : "";
  const createdDate = listing.attributes.createdAt;
  const hasBeenUpdated = listing?.attributes?.publicData?.hasBeenUpdated
  const updateDate = new Date(+listing.attributes.publicData.updateTime)
  const shownDate = hasBeenUpdated ? updateDate : createdDate;

  return (
    <div  className={css.containerSectionAvatarDesktop}>
      <NamedLink className={css.sectionAvatar} name="ProfilePage" params={{ id: user.id.uuid }}>
        <Avatar user={user} className={css.avatarDesktop} disableProfileLink />
        <div>
          <p className={css.avatarAuthor}>{displayName}</p>
          <p className={css.avatarLastUpdated}>{hasBeenUpdated ? "Actualizado" : "Publicado"}  <TimeAgo date={shownDate} formatter={formatter} /></p>
        </div>
      </NamedLink>
      <div className={css.desktopOnly}>
      <Button
        onClick={onClickLike}
        rootClassName={classnames(css.buttonSocialButtonIconLike)}
      >
        <ReloveIconFavoriteLike className={classnames(css.socialButtonIconLike, { [css.socialButtonIconLikeActive]: isLikedByCurrentUser })} isFull={isLikedByCurrentUser} />
        <span>{likedBy?.length}</span>
      </Button>
      </div>
    </div>
  );
};

export default SectionAvatar;
