import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import { NamedLink, ReloveIconLike, ReloveIconListing, ReloveIconUser } from '../../components';
import css from './NotificationsPage.css';

const getPathname = url => {
  const pathArray = url.split('/');
  const path = pathArray.slice(3).join('/');
  return path;
};
const formatter = buildFormatter(spanishStrings);
const getMessageFromType = notification => {
  const type = notification.type;
  const senderLink =
    notification.from && notification.from.uuid ? (
      <NamedLink
        onClick={e => e.stopPropagation()}
        name="ProfilePage"
        params={{ id: notification.from.uuid }}
      >
        {notification.from.displayName}
      </NamedLink>
    ) : null;

  if (type === 'follow') {
    return <span>¡Tienes una nueva seguidora! {senderLink} ha comenzado a seguirte.</span>;
  }

  const slug = notification.listing?.slug;
  const listingLink = slug ? (
    <NamedLink
      onClick={e => e.stopPropagation()}
      name="ListingPage"
      params={{ id: notification.listing.uuid, slug }}
      title={notification.listing.title}
    >
      {notification.listing.title}
    </NamedLink>
  ) : null;
  const salesLink = (
    <NamedLink
      onClick={e => e.stopPropagation()}
      name="InboxPage"
      params={{ tab: 'sales' }}
      title={notification.listing.title}
    >
      {notification.listing.title}
    </NamedLink>
  );
  if (type === 'like') {
    return (
      <span>
        ¡{senderLink} le ha dado like a tu artículo, {listingLink}!
      </span>
    );
  }
  if (type === 'comment') {
    return (
      <span>
        {senderLink} ha hecho un comentario en tu artículo,{' '}
        <a href={notification?.uri}>
          {notification.listing.title}
        </a>
        , ¡contéstalo pronto para resolver dudas!
      </span>
    );
  }
  if (type === 'comment-reply') {
    return (
      <span>
        {senderLink} dejo una respuesta a tu comentario en{' '}
        <a href={notification?.uri}>{notification.listing.title}</a>
      </span>
    );
  }
  if (type === 'booking/sold') {
    return (
      <span>
        {senderLink} ha comprado tu artículo {listingLink}
      </span>
    );
  }
  if (type === 'booking/bought') {
    return (
      <span>
        Has comprado el artículo {listingLink}, ve otras prendas de {senderLink}
      </span>
    );
  }
  if (type === 'booking/offer-received') {
    return (
      <span>
        {senderLink} ha hecho una oferta por tu artículo {listingLink}
      </span>
    );
  }
  if (type === 'booking/offer-sent') {
    return (
      <span>
        Has hecho una oferta por el artículo {listingLink}, ve otras prendas de {senderLink}
      </span>
    );
  }
  if (type === 'booking/offer-declined') {
    return (
      <span>
        Has rechazado una oferta de {senderLink} para tu artículo {listingLink}
      </span>
    );
  }
  if (type === 'booking/offer-declined-by-provider') {
    return (
      <span>
        {senderLink} ha rechazado tu oferta en {listingLink}, prueba algo más cercano al precio.
      </span>
    );
  }
  if (type === 'booking/priceDiscount') {
    return <span>🙌🏼 ¡Tu favorito {listingLink} bajó de precio! ¡No lo dejes ir!</span>;
  }
  if (type === 'reminder') {
    return (
      <span>
        🙌🏼 Recuerda enviar {salesLink}. Imprime tu guía, empaca tu paquete y déjalo en la paquetería.
      </span>
    );
  }
  if (type === 'reminder_day7') {
    return (
      <span>
        {' '}
        Corre a hacer tu envío hoy, ya pasó una semana y la compradora puede cancelar la venta 🥶
      </span>
    );
  }
  if (type === 'reminder_day10') {
    return <span> Últimos 3 días para enviar ${salesLink} y hacer feliz a esa Gloser 🤗 </span>;
  }
};
const getAvatarFromType = notification => {
  const { type, from, listing } = notification;
  if (type === 'follow') {
    const { uuid: id, displayName } = from;
    return (
      <NamedLink
        className={css.notificationAvatar}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        name="ProfilePage"
        params={{ id }}
        title={displayName}
      >
        <ReloveIconUser className={css.icon} />
      </NamedLink>
    );
  }

  const { uuid: id, slug } = listing;
  return (
    <NamedLink
      className={css.notificationAvatar}
      onClick={e => e.stopPropagation()}
      name="ListingPage"
      params={{ id, slug }}
      title={notification.listing.title}
    >
      <img
        className={css.notificationItemThumbnail}
        src={notification.thumbnail.url}
        height="90"
        alt={notification.listing.title}
      />
      <ReloveIconUser className={css.thumbnailIcon} />
    </NamedLink>
  );
};
const iconItem = type => {
  if (type === 'like') {
    return <ReloveIconLike className={css.icon} />;
  } else if (type === 'comment' || type === 'comment-reply') {
    return <ReloveIconListing className={css.icon} />;
  } else if (type === 'follow') {
    return <ReloveIconUser className={css.icon} />;
  }
};

const NotificationItem = props => {
  const { className, notification, history } = props;
  if (typeof notification.v === 'undefined') {
    return (
      <a href={notification.uri} className={className}>
        <span>
          {iconItem(notification.type)}
          {notification.message}
          <br />
          <TimeAgo
            date={notification.createdAt}
            formatter={formatter}
            className={css.notificationTime}
          />
        </span>
      </a>
    );
  }
  const message = getMessageFromType(notification);
  const notificationAvatar = getAvatarFromType(notification);

  return (
    <div
      className={className}
      onClick={e => {
        e.stopPropagation();
        if (e.target.tagName !== 'A' && e.target.parentElement.tagName !== 'A') {
          // Extract path from uri
          const path = notification.uri
            .split('/')
            .slice(3)
            .join('/');
          history.push(path);
        }
      }}
    >
      {notificationAvatar}
      <span>
        {message}
        <br />
        <TimeAgo
          date={notification.createdAt}
          formatter={formatter}
          className={css.notificationTime}
        />
      </span>
    </div>
  );
};

NotificationItem.defaultProps = { className: null };

const { string, object } = PropTypes;

NotificationItem.propTypes = {
  className: string,
  notification: object.isRequired,
};

export default NotificationItem;
