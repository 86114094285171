import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NotificationBadge,
  ReloveIconFavorite,
  ReloveIconMessages,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';

import css from './TopbarDesktop.css';
import clx from 'classnames'

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    // currentUserHasListings,
    notificationCount,
    messageCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    quickLinksItems,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearch}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const currentPageClass = page => {
    return currentPage === page ? css.currentPageMain : null;
  };

  const favoritesLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.favoritesLink, currentPageClass('MyFavoritesPage'))}
      name="MyFavoritesPage"
    >
      <div className={css.inboxIconGroup}>
        <ReloveIconFavorite className={css.favoritesIcon} />
      </div>
      <div className={css.favorites}>Mis Likes</div>
    </NamedLink>
  ) : null;

  const ordersNotifsDot =
    notificationCount > 0 ? <div className={css.ordersNotificationDot} /> : null;
  const ordersNotifsCount =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;
  const messageNotifsCount =
    messageCount > 0 ? (
      <NotificationBadge className={css.messageNotificationBadge} count={messageCount} />
    ) : null;

  const messagesLink = authenticatedOnClientSide ? (
    <NamedLink className={css.favoritesMessage} name="MessagePage" params={{ tab: 'messages' }}>
      <div className={css.inboxIconGroup}>
        <ReloveIconMessages className={css.favoritesIcon} />
        {messageNotifsCount}
      </div>
      <div className={css.favorites}> Mis Mensajes</div>
    </NamedLink>
  ) : null;

  const privateData = currentUser ? currentUser.attributes.profile.privateData : {};
  const hasNewNotifs =
    privateData.notifications && Array.isArray(privateData.notifications)
      ? privateData.notifications.some(element => !element.isRead)
      : false;

  const notificationDot = hasNewNotifs ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.inboxLink, currentPageClass('NotificationsPage'))}
      name="NotificationsPage"
    >
      <div className={css.inboxIconGroup}>
        <NotificationsOutlinedIcon className={css.inboxIcon} />
        {notificationDot}
      </div>
      <span className={css.inbox}>Notificaciones</span>
    </NamedLink>
  ) : null;

  const currentPageSubmenuClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };


  const profileMenu = (authenticatedOnClientSide && currentUser) ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.avatarWrapper}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          {ordersNotifsDot}
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>

        <MenuItem key="ProfilePage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageSubmenuClass('ProfilePage'))}
            name="ProfilePage"
            params={{id: currentUser.id.uuid}}
          >
            Mi closet
          </NamedLink>
        </MenuItem>


        <MenuItem key="ReferralProgram">
          <NamedLink
            className={clx(css.yourListingsLink)}
            name="ReferralProgram"
          >
            <FormattedMessage id="TopbarDesktop.referralLink" />&nbsp;
            <span style={{color:'#FD7C67', fontSize:'16px'}}>Gana $</span>
          </NamedLink>
        </MenuItem>

        <MenuItem key={'separator-1'}>
        <hr className={css.separator}/>
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageSubmenuClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        
        <MenuItem key="InboxPageOrders">
          <NamedLink
            className={css.yourListingsLink}
            name="InboxPage"
            params={{ tab: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.ordersLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPageSales">
          <NamedLink
            className={css.yourListingsLink}
            name="InboxPage"
            params={{ tab: 'sales' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.salesLink" />
            {ordersNotifsCount}
          </NamedLink>
        </MenuItem>


        <MenuItem key={'separator-1'}>
        <hr className={css.separator}/>
        </MenuItem>


        <MenuItem key="MyBalance">
          <NamedLink
            className={css.yourListingsLink}
            name="MyBalance"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id={"TopbarDesktop.myBalance"}/>
            
          </NamedLink>
        </MenuItem>


        
        {/** configuracion */}

        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageSubmenuClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>



        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const items = quickLinksItems.map(item => {

    const categoryLabel = item.label
    item.subs = item.options.map((item, index) =>
      item.label === 'Top marcas' ? (
        <NamedLink
          className={css.navDropdown}
          name="SearchPage"
          to={{
            search:
              'pub_category=' +
              item.key.slice(0, item.key.indexOf(',')) +
              '&pub_brand=' +
              item.key +
              '&track=' +
              categoryLabel + " " + item.label
          }}
        >
          Top marcas
        </NamedLink>
      ) : (
        <NamedLink
          className={css.navDropdown}
          key={item.key}
          name="SearchPage"
          to={{
            search:
              'pub_category=' + item.key +
              '&track=' +
              categoryLabel + " " + item.label
          }}
        >
          <span>{item.label}</span>
        </NamedLink>
      )
    );
    return item;
  });

  const btnList = items.map((item, index) => (
    <div className={css.navBtn} key={index}>
      <NamedLink
        className={css.dropbtn}
        name="SearchPage"
        to={{ search: 'pub_category=' + item.key + '&track=' + item.label }}
      >
        {item.label}
      </NamedLink>
      <div className={css.dropdownContent}>{item.subs}</div>
    </div>
  ));

  return (
    <div className={css.wrapper}>
      <nav className={classes}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {search}
        {favoritesLink}
        
        {messagesLink}
        {inboxLink}
        
        
        {profileMenu}
        {signupLink}
        {loginLink}
      </nav>
      <nav className={css.navContainer}>
        <div className={css.quickLinks}>
          <div className={css.navBtn}>
            <NamedLink
              className={css.dropbtn}
              name="SearchPage"
              to={{ search: 'hotlist=true' }}
            >
              Hot list
            </NamedLink>
          </div>
          <div className={css.navBtn}>
            <NamedLink
              className={css.dropbtn}
              name="SearchPage" to={{ search: 'collectionName=lo_mas_nuevo' }}

            >
              Lo mas nuevo
            </NamedLink>
          </div>
          {btnList}
        </div>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <MonetizationOnOutlinedIcon className={css.createListingIcon} />
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
      </nav>
    </div>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  messageCount: 0,
  initialSearchFormValues: {},
  quickLinksItems: config.quickLinks,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  messageCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
