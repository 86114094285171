import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import classNames from 'classnames';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import {
  Avatar,
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink, TopbarMobileMenu,
  TopbarMobileQuicklinks,
  TopbarDesktop
} from '../../components';
import config from '../../config';
import { TopbarSearchForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);

    this.handleMobileProfileMenuOpen = this.handleMobileProfileMenuOpen.bind(this);
    this.handleMobileProfileMenuClose = this.handleMobileProfileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleMobileQuicklinksOpen = this.handleMobileQuicklinksOpen.bind(this);
    this.handleMobileQuicklinksClose = this.handleMobileQuicklinksClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMobileProfileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileProfileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleMobileQuicklinksOpen() {
    redirectToURLWithModalState(this.props, 'mobilequicklinks');
  }

  handleMobileQuicklinksClose() {
    redirectToURLWithoutModalState(this.props, 'mobilequicklinks');
  }

  handleSubmit(values) {
    const keywords = values.keywords;
    const { history } = this.props;
    const searchParams = {
      keywords,
      sort: 'relevance'
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      let path = pathByRouteName('LandingPage', routeConfiguration());
      const logoutParam = '?logout=true';
      path += logoutParam;
      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }
    });
  } 

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      messageCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
    } = this.props;

    const { mobilemenu, mobilesearch, mobilequicklinks, keywords } = parse(location.search);
    const privateData = currentUser ? currentUser.attributes.profile.privateData : {};
    const hasNewNotifs = privateData.notifications && Array.isArray(privateData.notifications) ? privateData.notifications.some(element => !element.isRead) : false;
    const notificationDot = notificationCount > 0 || hasNewNotifs ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileProfileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';
    const isMobileQuicklinksOpen = isMobileLayout && mobilequicklinks === 'open';

    const mobileProfileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        messageCount={messageCount}
        currentPage={currentPage}
      />
    );
    const searchBarDisablePages = ['login', 'signup']
    const showSearchBar = searchBarDisablePages.some(word => location.pathname.includes(word)) ? false : true;
    const initialSearchFormValues = { keywords };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={css.mobileOnly}>
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <div className={css.mobileLeft}>
            <Button
              rootClassName={css.quicklinksMenu}
              onClick={this.handleMobileQuicklinksOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
            </Button>
            <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            draggable="false"
          >
            <Logo />
          </NamedLink>
          </div>
          <div className={css.mobileRight}>
            <NamedLink className={css.createListingLink} name="NewListingPage">
              <MonetizationOnOutlinedIcon className={css.createListingIcon} />
              <span className={css.createListing}>
                <FormattedMessage id="Topbar.createListingMobile" />
              </span>
            </NamedLink>
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileProfileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              {(isAuthenticated && currentUser) ? (
                <Avatar className={css.avatar} user={currentUser} disableProfileLink />
              ) : (
                <AccountCircleRoundedIcon color="inherit" className={css.menuIcon} style={{ fontSize: 30, margin: 0 }}/>
              )}
              {notificationDot}
            </Button>
          </div>
          
        </div>

        {showSearchBar && (
        <TopbarSearchForm
          onSubmit={this.handleSubmit}
          initialValues={initialSearchFormValues}
          isMobile
          className={css.searchbar}
        />
        )}

        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            messageCount={messageCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileProfileMenuOpen}
          onClose={this.handleMobileProfileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainerMobile}
          closeButtonClassName={css.buttonCloseMobileMenu}

        >
          {authInProgress ? null : mobileProfileMenu}
        </Modal>
        <Modal
          id="TopbarMobileQuicklinks"
          isOpen={isMobileQuicklinksOpen}
          onClose={this.handleMobileQuicklinksClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainerMobileFilter}
        >
          <TopbarMobileQuicklinks />
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  messageCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  messageCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;