import React, { Component, createRef } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton, ReloveIconPhotos, NamedLink } from '../../components';
import { propTypes } from '../../util/types';

import Arrow from '@material-ui/icons/ArrowForwardIos';
import { createSlug } from '../../util/urlHelpers';

import css from './SendMessageForm.css';

const BLUR_TIMEOUT_MS = 100;

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.messageInputRef = createRef();
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDivClick = this.handleDivClick.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  handleDivClick() {
    if (this.messageInputRef.current && this.messageInputRef.current.input) {
      this.messageInputRef.current.input.focus();
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            listing,
            isOwnListing,
          } = formRenderProps;

          const listingSlug = createSlug(listing.attributes.title || '');
          const isDraftVariant = listing.variant;
          const listingTab = isDraftVariant ? 'photos' : 'description';

          const params = {
            id: listing.id.uuid,
            slug: listingSlug,
            type: listing.type,
            edit: listingTab,
          };

          const { innerWidth: width } = window;
          const isMobile = width < 550;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.sendMessageContainer} onClick={this.handleDivClick} onTouchStart={this.handleDivClick}>
                <FieldTextInput
                  inputRef={this.messageInputRef}
                  inputRootClass={css.textarea}
                  rootClassNam={rootClassName}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder={messagePlaceholder}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  autoFocus
                />
                <div className={css.submitContainer}>
                  <div className={css.errorContainer}>
                    {sendMessageError ? (
                      <p className={css.error}>
                        <FormattedMessage id="SendMessageForm.sendFailed" />
                      </p>
                    ) : null}
                  </div>
                  <SecondaryButton
                    rootClassName={css.submitButton}
                    disabled={submitDisabled}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  >
                    <div className={css.sendIcon}><Arrow /></div>
                  </SecondaryButton>
                </div>
              </div>
              {isOwnListing ? isMobile ? 
                <NamedLink className={css.editListingButton} name="EditListingPage" params={params}>
                  <ReloveIconPhotos className={css.sendIconPhotos} ></ReloveIconPhotos>
                </NamedLink> : 
                <NamedLink className={css.editListingLink} name="EditListingPage" params={params}>
                  Agregar más fotos
                </NamedLink>
              : null}
            </Form>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
