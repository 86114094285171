import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReloveIconMessages.css';

const ReloveIconMessages = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      viewBox="0 -80 700 700"
      width="28"
      // style="fill: skyblue; stroke: cadetblue; stroke-width: 2;"
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="m551.56 471.92h-403.1c-18.328 0-34.977-7.4922-47.039-19.555s-19.555-28.711-19.555-47.039v-250.62c0-18.328 7.4922-34.977 19.555-47.039s28.711-19.555 47.039-19.555h403.1c18.328 0 34.977 7.4922 47.039 19.555s19.555 28.711 19.555 47.039v250.62c0 18.328-7.4922 34.977-19.555 47.039s-28.711 19.555-47.039 19.555zm19.355-34.441-153.25-138.01c-19.555 16.684-43.613 25.031-67.652 25.031-24.043 0-48.098-8.3516-67.652-25.031l-153.25 138.01c5.6602 3.4258 12.281 5.4102 19.355 5.4102h403.1c7.0547 0 13.691-1.9805 19.355-5.4102zm-131.56-157.48 148.78 133.98c0.65625-2.7891 1.0234-5.6953 1.0234-8.668v-250.62c0-2.9922-0.35156-5.8789-1.0234-8.668zm-327.45 133.98 148.78-133.98-148.78-133.98c-0.65625 2.7891-1.0234 5.6953-1.0234 8.668v250.62c0 2.9922 0.35156 5.8789 1.0234 8.668zm17.219-291.46 162.84 146.66 0.13281 0.11719 7.4766 6.7383c14.398 12.969 32.426 19.453 50.449 19.453 18.027 0 36.055-6.4844 50.449-19.453l7.4766-6.7383 0.13281-0.11719 162.84-146.66c-5.6602-3.4258-12.281-5.4102-19.355-5.4102h-403.1c-7.0547 0-13.691 1.9805-19.355 5.4102z"/>
      {/* <path d="m562.8 128.8h-425.6c-6.1602 0-11.199 5.0391-11.199 11.199v280c0 6.1602 5.0391 11.199 11.199 11.199h425.6c6.1602 0 11.199-5.0391 11.199-11.199v-280c0-6.1602-5.0391-11.199-11.199-11.199zm-26.879 22.398-185.92 185.92-185.92-185.92zm-387.52 15.68 113.12 113.12-113.12 113.12zm16.242 241.92 112.56-113.12 64.961 64.961c2.2383 2.2383 5.0391 3.3594 7.8398 3.3594s5.6016-1.1211 7.8398-3.3594l64.961-64.961 112.56 113.12zm386.96-15.68-113.12-113.12 113.12-113.12z"/> */}

      {/* <path d="m165.2 117.6c-27.633 0-50.398 22.77-50.398 50.398v224c0 27.629 22.77 50.398 50.398 50.398h369.6c27.633 0 50.398-22.77 50.398-50.398v-224c0-27.629-22.77-50.398-50.398-50.398zm3.6758 33.602h362.43l-170.98 152.6c-6.5195 5.8203-13.766 5.8242-20.301 0l-171.15-152.61zm-20.477 26.773 115.15 102.72-115.15 110.6zm403.2 0v213.15l-114.8-110.6zm-140 125.12 109.73 105.7h-342.65l110.07-105.52 28.875 25.727c18.355 16.367 46.742 16.391 65.102 0l28.879-25.902z"/> */}

      {/* <path d="m601.99 116.56c-0.003906-1.2617-0.20312-2.5352-0.62109-3.7734-0.054688-0.16406-0.17188-0.28516-0.23438-0.44141-0.16797-0.4375-0.42187-0.80078-0.63281-1.2109-0.46094-0.875-0.97266-1.6758-1.6133-2.3984-0.33203-0.37109-0.66797-0.6875-1.0352-1.0117-0.73437-0.64453-1.5273-1.1641-2.3984-1.6172-0.40234-0.20703-0.76562-0.44141-1.1914-0.60547-1.3398-0.50781-2.7422-0.85547-4.2383-0.85547l-480.05-0.003906c-1.4883 0-2.8945 0.34766-4.2344 0.85547-0.42969 0.16406-0.80078 0.39844-1.2109 0.60938-0.86328 0.44922-1.6523 0.96484-2.3789 1.6055-0.375 0.33203-0.71094 0.64844-1.043 1.0234-0.63672 0.71875-1.1523 1.5195-1.6055 2.3867-0.21875 0.41406-0.46875 0.77734-0.63672 1.2227-0.0625 0.15625-0.17969 0.28125-0.23047 0.44141-0.42188 1.2422-0.61719 2.5156-0.62109 3.7734-0.003906 0.023437-0.015625 0.039062-0.015625 0.0625v326.76c0 6.6211 5.3594 11.977 11.977 11.977h480.05c6.6133 0 11.977-5.3594 11.977-11.977v-326.76c0-0.023438-0.011719-0.039063-0.011719-0.0625zm-480.03 314.84v-290.56l220.77 168.68c2.1562 1.6406 4.7109 2.457 7.2734 2.457 2.5664 0 5.125-0.81641 7.2734-2.457l220.77-168.68v290.56l-456.09-0.003906zm228.04-146.47-204.62-156.34h409.24z"/> */}
    </svg>
  );
};

ReloveIconMessages.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReloveIconMessages.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReloveIconMessages;
