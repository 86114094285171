import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import InfiniteScroll from 'react-infinite-scroller';

import config from '../../config';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ListingCard,
  ReloveIconFavoriteVariant
} from '../../components';

import { queryFavoritedListings, getListingsPage } from './MyFavoritesPage.duck';

import css from './MyFavoritesPage.css';

export class MyFavoritesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: null,
      currentPage: 0
    };
  }

  async componentDidMount() {
    const { currentUser, getFavorites, getPage } = this.props;

    if(!!currentUser && !!currentUser.id) {
	    const listings = await getFavorites(currentUser.id.uuid)
      // this.setState({listings: "hey"})
      getPage(currentUser.id.uuid,listings,this.state.currentPage)
      this.setState({currentPage: this.state.currentPage+1})
    }
  }

  async componentDidUpdate(prevProps) {
  	const { currentUser, getFavorites, getPage } = this.props;
  	if(currentUser !== prevProps.currentUser && !!currentUser.id) {
	    const listings = await getFavorites(currentUser.id.uuid)
      // this.setState({listings: "hey"})
      getPage(currentUser.id.uuid,listings,this.state.currentPage)
      this.setState({currentPage: this.state.currentPage+1})
	  }
  }

  render() {
    const {
      scrollingDisabled,
      favoritedListings,
      queryListingsInProgress,
      queryListingsError,
      intl,
      boughtListings,
      pagination,
    } = this.props;


    const hasMore = this.state.currentPage < pagination

    // const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    // const listingsAreLoaded = !queryListingsInProgress && hasPaginationInfo;

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: 'Mis Likes',
        siteTitle: config.siteTitle,
      }
    );

    const loadFunc = () => {
      if(!queryListingsInProgress) {
        let page = this.state.currentPage
        this.props.getPage(this.props.currentUser.id.uuid,this.props.sortedListingsIds,page)
        this.setState({currentPage: page+1})
      }
    }

    const loader = (
      <Grid container key={'loader'} justify={'center'} style={{marginTop: '10px'}}>
        <CircularProgress  className={css.loader} color={'inherit'}/>
      </Grid>
    );

    let content = null;

    // const linkProps = {
    //   pageName: 'MyFavoritesPage',
    //   pagePathParams: {
    //     component: 'PaginationLinks',
    //     example: 'Empty',
    //   },
    //   pagination,
    // };
    if (queryListingsInProgress) {
    	content = (
    		<Grid item xs={12} style={{textAlign: 'center'}}>
    			<CircularProgress className={css.loader} color={'inherit'}/>
    		</Grid>
    	);
    } else if (queryListingsError) {
    	content = (
	    	<Grid item xs={12}>
	  			<h3><FormattedMessage id="MyFavoritesPage.error"/></h3>
	  		</Grid>
  		);
    } else if(favoritedListings.length > 0) {
    	  content = (
          // <div className={css.listings}>
              <InfiniteScroll
                loadMore={loadFunc}
                initialLoad={false}
                hasMore={hasMore}
                loader={loader}
              >
                <div className={css.listingCards}>
                  {favoritedListings.map(l => (
                    <Grid item xs={6} md={3} key={l.id.uuid}>
                      <ListingCard
                        className={css.favoritedItem}
                        listing={l}
                        isBought={boughtListings.includes(l.id.uuid)}
                      />
                    </Grid>
                ))}
                {/* {this.props.children} */}
              </div>
            </InfiniteScroll>
          // </div>
        )
    } else {
    	content = (
    		<Grid item xs={12}>
	  			<h3><FormattedMessage id="MyFavoritesPage.empty"/></h3>
	  		</Grid>
	  	);
    }
    // const paginationLinks =
    //   listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
    //     <PaginationLinks
    //       className={css.pagination}
    //       pageName="MyFavoritesPage"
    //       pagination={pagination}
    //     />
    //   ) : null;
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'MyFacvoritesPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="MyFavoritesPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
          	<div className={css.root}>
          		<h1 className={css.title}><FormattedMessage id="MyFavoritesPage.title"/> <ReloveIconFavoriteVariant className={css.titleIcon}/></h1>
          			<Grid item xs={12} className={css.main}>
          				{content}
          			</Grid>
              {/* {paginationLinks} */}
          	</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

MyFavoritesPageComponent.defaultProps = {
  currentUser: null,
  favoritedListings: [],
  boughtListings: [],
  queryListingsInProgress: null,
  queryListingsError: null,
  // pagination:null,
};

const { arrayOf, number, shape, string } = PropTypes;

MyFavoritesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  favoritedListings: arrayOf(propTypes.listing).isRequired,
  queryListingsInProgress: PropTypes.bool,
  queryListingsError: propTypes.error,
  // pagination: propTypes.pagination.isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  history: shape({
    location: shape({
      search: string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    favoritedListingsIds,
    queryListingsError,
    appendedList,
    queryListingsInProgress,
    boughtListings,
    pagination,
  } = state.MyFavoritesPage;



  const listings = getListingsById(state, appendedList);

  return {
  	scrollingDisabled: isScrollingDisabled(state),
  	favoritedListings: listings,
    favoritedListingsIds: appendedList,
    sortedListingsIds: favoritedListingsIds,
    queryListingsError,
    queryListingsInProgress,
    boughtListings,
    currentUser,
    pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  getFavorites: user => dispatch(queryFavoritedListings(user)),
  getPage: (user, listings, page) => dispatch(getListingsPage(user, listings, page)),
});

const MyFavoritesPage = compose(
  withRouter,
  connect(
  	mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(MyFavoritesPageComponent);

export default MyFavoritesPage;
