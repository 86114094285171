import { getAddress, getShippingPrice, isZipAvailable } from '../util/api'

export const getAddressById = (user, isBoolean = false) => {
  return getAddress({ user, isBoolean })
};

export const getShippingPriceByZipCode = (zipCode, authorZipCode = '') => {
  return getShippingPrice({ zipCode, authorZipCode });
}

export const getIsZipCodeAvailable = (zipCode) => {
  return isZipAvailable({ zipCode });
}
