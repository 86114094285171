import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton } from '../../components';
import { propTypes } from '../../util/types';
import VerificationInput from 'react-verification-input';

import css from './CheckVerificationForm.css';

const CheckVerificationFormComponent = props => {

  return(
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        resendCode,
        inProgress,
        sendEnquiryError,
        success,
        maxAttempts,
        maxAttemptsChange,
        disabled
      } = fieldRenderProps;

      let onChange = success;
      const handleOnchange = (value) => {
        // Handle the complete verification value here
        onChange = true;
        return value;
      };
    
      const handleSubmitVerificationInput = (event) => {
        event.preventDefault();
        handleSubmit();
      };

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <div>
        <Form className={classes} onSubmit={handleSubmitVerificationInput}>
        {/* <div className={css.title}>Gloset</div> */}
        <div className={css.label}>Ingresa el código que te llegó por Whatsapp</div>
        <br></br>
        <Field name='code' id={formId ? `${formId}.code` : 'code'}>
        {({ input }) => 
            (
              <VerificationInput
                classNames={{
                  container: css.container,
                  character: css.character,
                }}    
                length={6} 
                value={input.value}
                onChange={handleOnchange(input.onChange)}
              />
            )}
        </Field>
            {
              maxAttempts ? (<div className={css.error}>
                Número de intentos excedidos, volver a intentar más tarde
              </div>)
              :
              onChange ? null : (<div className={css.error}>
                Está mal el código
              </div>)
            }
            <button className={css.buttonResend} onClick={resendCode}>Enviar nuevo código</button>

          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
              </p>
              <input type="hidden" id={formId ? `${formId}.code` : 'code'} name="code" value={''} />
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                Continuar
              </PrimaryButton>
            </div>
          </div>
        </Form>
        </div>
        );
      }}
    />

  );
};
      

CheckVerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

CheckVerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckVerificationForm = compose(injectIntl)(CheckVerificationFormComponent);

CheckVerificationForm.displayName = 'CheckVerificationForm';

export default CheckVerificationForm;
