import React from 'react'

const ReloveIconX = () => {
  return (
<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Social Icons">
<path id="Vector" d="M22.9079 2.87988H27.1247L17.9121 13.4092L28.75 27.7373H20.264L13.6175 19.0474L6.01243 27.7373H1.79304L11.6468 16.475L1.25 2.87988H9.95139L15.9592 10.8228L22.9079 2.87988ZM21.4279 25.2134H23.7645L8.68174 5.2713H6.17433L21.4279 25.2134Z" fill="black"/>
</g>
</svg>

  )
}

export default ReloveIconX