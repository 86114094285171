import { addMarketplaceEntities } from './marketplaceData.duck';
import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { transitionsToRequested } from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import {
  apiGetFollowers,
  apiGetFollowing,
  apiSetFollowingData,
  readNotifications, 
  fetchStripeCustomer,
  updateUser,
} from '../util/api';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { AmplitudeAnalytics, logEventSetUserID } from '../util/logsEvent';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_MESSAGES_REQUEST = 'app/user/FETCH_CURRENT_USER_MESSAGES_REQUEST';

export const FETCH_CURRENT_USER_MESSAGES_SUCCESS = 'app/user/FETCH_CURRENT_USER_MESSAGES_SUCCESS';

export const FETCH_CURRENT_USER_MESSAGES_ERROR = 'app/user/FETCH_CURRENT_USER_MESSAGES_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const FETCH_CURRENT_USER_FOLLOWING_REQUEST = 'app/user/FETCH_CURRENT_USER_FOLLOWING_REQUEST';
export const FETCH_CURRENT_USER_FOLLOWING_SUCCESS = 'app/user/FETCH_CURRENT_USER_FOLLOWING_SUCCESS';
export const FETCH_CURRENT_USER_FOLLOWING_ERROR = 'app/user/FETCH_CURRENT_USER_FOLLOWING_ERROR';

export const MARK_NOTIFICATIONS_AS_READ_REQUEST = 'app/user/MARK_NOTIFICATIONS_AS_READ_REQUEST';
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'app/user/MARK_NOTIFICATIONS_AS_READ_SUCCESS';
export const MARK_NOTIFICATIONS_AS_READ_ERROR = 'app/user/MARK_NOTIFICATIONS_AS_READ_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
      ? newCurrentUser
      : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  loadingCurrentUser: false,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserMessageCount: 0,
  currentUserNotificationCountError: null,
  currentUserMessageCountError: null,

  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserFollowing: [],
  loadedFollowing: false,
  currentUserFollowingInProgress: false,
  currentUserFollowingError: null,
  markNotifsAsReadInProgress: false,
  markNotifsAsReadError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null, loadingCurrentUser: true };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        loadingCurrentUser: false,
        currentUser: mergeCurrentUser(state.currentUser, payload),
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, currentUserShowError: payload, loadingCurrentUser: false };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserMessageCount: 0,
        currentUserNotificationCountError: null,
        currentUserMessageCountError: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_MESSAGES_REQUEST:
      return { ...state, currentUserMessageCountError: null };
    case FETCH_CURRENT_USER_MESSAGES_SUCCESS:
      return { ...state, currentUserMessageCount: payload.transactions.length };
    case FETCH_CURRENT_USER_MESSAGES_ERROR:
      return { ...state, currentUserMessageCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case MARK_NOTIFICATIONS_AS_READ_REQUEST:
      return {
        ...state,
        markNotifsAsReadInProgress: true,
        markNotifsAsReadError: null,
      };
    case MARK_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        markNotifsAsReadInProgress: false,
      };
    case MARK_NOTIFICATIONS_AS_READ_ERROR:
      return {
        ...state,
        markNotifsAsReadInProgress: false,
        markNotifsAsReadError: payload,
      };

    case FETCH_CURRENT_USER_FOLLOWING_REQUEST:
      return {
        ...state,
        loadedFollowing: false,
        currentUserFollowingInProgress: true,
        currentUserFollowingError: null,
      };
    case FETCH_CURRENT_USER_FOLLOWING_SUCCESS:
      return {
        ...state,
        currentUserFollowingInProgress: false,
        loadedFollowing: true,
        currentUserFollowing: payload.following,
      };
    case FETCH_CURRENT_USER_FOLLOWING_ERROR:
      return {
        ...state,
        currentUserFollowingInProgress: false,
        currentUserFollowingError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserMessageCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserMessagesRequest = () => ({
  type: FETCH_CURRENT_USER_MESSAGES_REQUEST,
});

export const fetchCurrentUserMessagesSuccess = transactions => ({
  type: FETCH_CURRENT_USER_MESSAGES_SUCCESS,
  payload: { transactions },
});

const fetchCurrentUserMessagesError = e => ({
  type: FETCH_CURRENT_USER_MESSAGES_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserFollowingRequest = () => ({
  type: FETCH_CURRENT_USER_FOLLOWING_REQUEST,
});

const fetchCurrentUserFollowingSuccess = following => ({
  type: FETCH_CURRENT_USER_FOLLOWING_SUCCESS,
  payload: { following },
});

const fetchCurrentUserFollowingError = e => ({
  type: FETCH_CURRENT_USER_FOLLOWING_ERROR,
  error: true,
  payload: e,
});

const markNotificationsAsReadRequest = () => ({ type: MARK_NOTIFICATIONS_AS_READ_REQUEST });

const markNotificationsAsReadSuccess = user => ({
  type: MARK_NOTIFICATIONS_AS_READ_SUCCESS,
  payload: user,
});

const markNotificationsAsReadError = e => ({
  type: MARK_NOTIFICATIONS_AS_READ_ERROR,
  payload: e,
  error: true,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 1,
  };

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  //this needs to be changed
  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUserMessages = currentUser => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserMessagesRequest());

  const apiQueryParams = {
    include: ['messages', 'provider', 'customer'],
    last_transitions: 'transition/send-enquiry',
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  //given notifications how to filter only those that have messages unread?
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      let filteredResponse = response.data.data.filter(
        r =>
          (r.attributes.metadata.unreadMessagesProvider === true &&
            currentUser === r.relationships.provider.data.id.uuid) ||
          (r.attributes.metadata.unreadMessagesCustomer === true &&
            currentUser === r.relationships.customer.data.id.uuid)
      );
      const transactions = filteredResponse;
      dispatch(fetchCurrentUserMessagesSuccess(transactions));
    })
    .catch(e => dispatch(fetchCurrentUserMessagesError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];
      if(currentUser?.stripeCustomer?.attributes?.stripeCustomerId){
        return fetchStripeCustomer({stripeCustomerId: currentUser.stripeCustomer.attributes.stripeCustomerId}).then(fetchStripeCustomerRes => {
          currentUser.stripeCustomer = fetchStripeCustomerRes

          if(!currentUser.attributes.profile.privateData.stripeCustomerId){
            updateUser({id: currentUser.id.uuid, privateData: {stripeCustomerId: currentUser.stripeCustomer.attributes.stripeCustomerId}})
          }
          dispatch(currentUserShowSuccess(currentUser));
          return currentUser;
        })
      }
      // Fetch and save stripeAccount to store.stripe.stripeAccount if stripeCustomerId exists in privateData
      if(currentUser?.attributes?.profile?.privateData?.stripeCustomerId){
        return fetchStripeCustomer({stripeCustomerId: currentUser.attributes.profile.privateData.stripeCustomerId}).then(fetchStripeCustomerRes => {
          // return fetchStripeCustomerRes
          currentUser.stripeCustomer = fetchStripeCustomerRes
          dispatch(currentUserShowSuccess(currentUser));
          return currentUser;
        })
      }
      // Save stripeAccount to store.stripe.stripeAccount if it exists
      else if (currentUser?.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }
      const amplitude = new AmplitudeAnalytics();
      // set current user id to the logger
      amplitude.setUser(currentUser.id.uuid);
      log.setUserId(currentUser.id.uuid);
      logEventSetUserID(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications());
      dispatch(fetchCurrentUserMessages(currentUser.id.uuid));
      const { loadedFollowing, currentUserFollowingInProgress } = getState().user;
      if (!loadedFollowing && !currentUserFollowingInProgress) {
        dispatch(getCurrentUserFollowing());
      }
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed', {...params}, 'user');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const fetchCurrentUserStripeDetails = (params = null) => (dispatch, getState, sdk) => {

}

export const markNotificationsAsRead = (params = null) => (dispatch, getState, sdk) => {
  dispatch(markNotificationsAsReadRequest());

  return readNotifications()
    .then(response => {
      const user = response.data.data;
      dispatch(markNotificationsAsReadSuccess(user));
    })
    .catch(e => {
      log.error(e, 'mark-notifications-as-read-failed', {...params}, 'user');
      dispatch(markNotificationsAsReadError(storableError(e)));
    });
};

export const getCurrentUserFollowing = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserFollowingRequest());
  const { currentUser } = getState().user;

  apiGetFollowing({ user: currentUser.id.uuid, withDetails: true, isList: true })
    .then(response => {
      const followingList = response.following;

      let promises = [];

      followingList.forEach(rel => {
        promises.push(
          sdk.users.show({
            id: rel.user,
            include: ['profileImage', 'publicData'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
          })
        );
      });

      return Promise.allSettled(promises);
    })
    .then(responses => {
      const succesful = responses.filter(res => res.status === 'fulfilled');
      let ids = [];
      succesful.forEach(res => {
        dispatch(addMarketplaceEntities(res.value));
        ids.push(res.value.data.data.id);
      });
      dispatch(fetchCurrentUserFollowingSuccess(ids));
    })
    .catch(e => {
      dispatch(fetchCurrentUserFollowingError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

export const setFollowingData = (userId, callback) => async (dispatch, getState, sdk) => {
  try {
    let responses = await Promise.allSettled([
      apiGetFollowers({ user: userId.uuid }),
      apiGetFollowing({ user: userId.uuid }),
    ]);

    let followers =
      responses[0].status === 'fulfilled' && responses[0].value?.success === true
        ? responses[0].value.followers.length
        : 0;

    let following =
      responses[1].status === 'fulfilled' && responses[1].value?.success === true
        ? responses[1].value.following.length
        : 0;

    let user_edit = await apiSetFollowingData({ userId, followers, following });

    dispatch(callback(user_edit));
  } catch (e) {
    console.error(e);
  }
};
