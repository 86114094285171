import React, { useState } from 'react';

import { Button, Modal, PrimaryButton } from '../../components';
import css from './ModalAddUsername.css';

const ModalAddUsername = props => {
    const {
        onManageDisableScrolling,
        isUserModalOpen, 
        setIsUserModalOpen,
        setNewUserName,
        handleNewUserCreation,
        generateReferralCodeInProgess,
        generateNewUserInProgress, 
        generateNewUserTakenError
    } = props;

    // State to store the input value
    const [inputValue, setInputValue] = useState('');

    // Function to handle input changes
    const handleInputChange = (e) => {
        const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        setInputValue(filteredValue); // Update the state with the filtered value
        setNewUserName(filteredValue);
    };
    return onManageDisableScrolling ? (
        <Modal
            id="addUsername"
            isOpen={isUserModalOpen}
            onClose={() => {
                setIsUserModalOpen(false)
            }}
            onManageDisableScrolling={onManageDisableScrolling}
            closeButtonMessage={""}
            customModalClass={css.modal}
            closeButtonClassName={css.close}
        >
            <div>
                <div className={css.codeHeading}>Para generar tu código, crea tu nombre de usuario </div>

                <div className={css.referralCodeButtonContainer}>
                    <input
                        type="text"
                        onChange={handleInputChange}
                        placeholder=""
                        className={css.referralCodeInput}
                        value={inputValue} // Bind input value to state
                    />
                    <PrimaryButton className={css.referralCodeCopyButton} inProgress={generateReferralCodeInProgess || generateNewUserInProgress} disabled={generateReferralCodeInProgess || generateNewUserInProgress || inputValue === ''} onClick={() => handleNewUserCreation()}>CREAR</PrimaryButton>
                </div>
                {generateNewUserTakenError && <div className={css.error}>Usuario no disponible</div>}
            </div>
        </Modal>
    ) : <></>
}

export default ModalAddUsername;
