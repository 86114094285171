import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './SuscriptionForm.css';

const KEY_CODE_ENTER = 13;


const SuscriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        sendEnquiryError,
        onOpenTermsOfService,
        onOpenPrivacyPolicy,
      } = fieldRenderProps;

        const emailPlaceholder = intl.formatMessage({
            id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
            id: 'LoginForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
            id: 'LoginForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const handlePrivacyKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenPrivacyPolicy();
          }
        };

        const privacyPolicy = (
          <span
            className={css.termsLink}
            onClick={onOpenPrivacyPolicy}
            role="button"
            tabIndex="0"
            onKeyUp={handlePrivacyKeyUp}
          >
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
          </span>
        );
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
        <div className={css.title}>Gloset</div>
        <div className={css.label}>¡Únete a la comunidad y obtén tu primer envío gratis!</div>
        <br></br>
          <FieldTextInput
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            // label={emailLabel}
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ privacyPolicy, termsLink }}
                  />
                </span>
              </p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                Únete a la comunidad
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

SuscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

SuscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SuscriptionForm = compose(injectIntl)(SuscriptionFormComponent);

SuscriptionForm.displayName = 'SuscriptionForm';

export default SuscriptionForm;
