import PropTypes from 'prop-types';
import React from 'react';

const IconEmailSent = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="none" viewBox="0 0 110 110">
      <path fill="url(#paint0_linear)" d="M93.47 37.78c-3.718-9.46-15.172-15.135-24.873-11.38-11.074 4.286-7.402 21.9-17.46 25.462-5.434 1.923-13.467-5.264-18.956-6.366-10.116-2.03-19.273 5.107-21.528 14.991-2.3 10.084 1.39 23.553 12.468 26.29 13.198 3.26 20.072-8.604 31.143-12.01 11.361-3.496 23.994 4.76 33.4-5.285 6.948-7.422 9.488-22.332 5.806-31.701z"/>
      <path fill="#000" d="M89.308 41.94c0-6.569-5.325-11.894-11.894-11.894-5.09 0-9.43 3.198-11.129 7.692h-43.01c-1.837 0-3.327 1.49-3.327 3.327v36.824c0 1.837 1.49 3.327 3.327 3.327H81.88c1.837 0 3.326-1.49 3.326-3.327V50.922c2.511-2.18 4.101-5.394 4.101-8.981z" opacity=".1"/>
      <path fill="#fff" d="M81.881 77.057H23.275c-1.837 0-3.327-1.489-3.327-3.326V36.907c0-1.837 1.49-3.327 3.327-3.327H81.88c1.837 0 3.326 1.49 3.326 3.327V73.73c0 1.837-1.489 3.326-3.326 3.326z"/>
      <path fill="#fff" d="M85.207 36.907v14.054c-2.087 1.812-4.811 2.91-7.793 2.91-6.57 0-11.895-5.326-11.895-11.894 0-3.277 1.325-6.245 3.469-8.396H81.88c1.836 0 3.326 1.489 3.326 3.326z"/>
      <path fill="#fff" d="M85.207 36.908v4.193l-29.59 22.73c-.867.668-1.947 1.034-3.04 1.034-1.092 0-2.171-.366-3.04-1.033L19.947 41.1v-4.193c0-1.838 1.491-3.326 3.327-3.326h58.607c1.836 0 3.326 1.488 3.326 3.326z"/>
      <path fill="#fff" d="M53.59 49.444l31.617 24.287c0 1.837-1.49 3.326-3.326 3.326H23.273c-1.837 0-3.326-1.489-3.326-3.326l31.616-24.287c.598-.459 1.429-.459 2.026 0z"/>
      <path fill="#fff" d="M63.969 57.416l-8.351 6.415c-.868.667-1.948 1.033-3.04 1.033-1.093 0-2.172-.366-3.04-1.033l-8.351-6.415 10.378-7.971c.597-.46 1.428-.46 2.026 0l10.378 7.971z"/>
      <path fill="#fff" d="M51.565 61.193L19.948 36.907c0-1.837 1.49-3.327 3.327-3.327H81.88c1.837 0 3.326 1.49 3.326 3.327L53.591 61.193c-.598.46-1.429.46-2.026 0zM71.696 72.632H66.35c-.46 0-.832-.373-.832-.832v-3.684c0-.46.373-.832.832-.832h5.345c.459 0 .831.372.831.831V71.8c0 .46-.372.832-.831.832zM61.87 69.242h-4.63c-.459 0-.83-.373-.83-.832 0-.459.371-.831.83-.831h4.63c.46 0 .832.372.832.831 0 .46-.373.832-.832.832zM61.87 72.632H45.599c-.46 0-.832-.373-.832-.832 0-.46.372-.831.832-.831H61.87c.46 0 .832.372.832.831 0 .46-.373.832-.832.832z"/>
      <path fill="#EE5C78" d="M77.414 49.677c6.57 0 11.895-5.325 11.895-11.894 0-6.57-5.326-11.895-11.895-11.895-6.57 0-11.895 5.326-11.895 11.895s5.326 11.894 11.895 11.894z"/>
      <path fill="#000" d="M77.414 50.509c7.017 0 12.726-5.709 12.726-12.726 0-7.018-5.709-12.726-12.726-12.726-7.017 0-12.726 5.709-12.726 12.726 0 4.082 1.934 7.719 4.932 10.049L53.084 60.534c-.298.23-.714.23-1.013 0L20.81 36.52c.186-1.193 1.22-2.108 2.465-2.108h38.147c.46 0 .832-.372.832-.832 0-.459-.372-.831-.832-.831H23.275c-2.293 0-4.158 1.865-4.158 4.158V73.73c0 2.293 1.865 4.158 4.158 4.158H81.88c2.293 0 4.158-1.865 4.158-4.158V51.754c0-.46-.372-.832-.832-.832-.46 0-.831.373-.831.832v20.29L62.603 55.319l8.473-6.508c1.867 1.078 4.03 1.698 6.338 1.698zm-34.862 4.81L20.78 72.044v-33.45L42.552 55.32zm41.79 18.797c-.185 1.193-1.217 2.11-2.461 2.11H23.275c-1.245 0-2.276-.917-2.462-2.11l23.104-17.748 7.141 5.485c.447.343.984.515 1.52.515s1.072-.171 1.52-.515l7.14-5.486 23.105 17.749zM66.352 37.783c0-6.1 4.963-11.063 11.063-11.063 6.1 0 11.063 4.963 11.063 11.063 0 6.1-4.963 11.063-11.063 11.063-6.1 0-11.063-4.963-11.063-11.063z"/>
      <path fill="#fff" d="M72.222 33.727l1.698 4.056-1.698 4.056c-.288.69.407 1.38 1.094 1.086l10.248-4.377c.673-.288.673-1.242 0-1.53l-10.248-4.377c-.687-.294-1.382.397-1.094 1.086z"/>
      <path fill="#FFDBED" d="M77.792 38.56l-5.11 2.183 1.237-2.96-1.239-2.961 5.112 2.184c.637.273.637 1.282 0 1.553z"/>
      <path fill="#00ECA2" d="M65.023 25.82c-.618 0-1.235-.234-1.705-.704-.94-.94-.94-2.47 0-3.41s2.47-.941 3.41 0c.94.94.94 2.47 0 3.41-.47.47-1.088.705-1.705.705zm0-3.158c-.192 0-.383.073-.53.22-.291.29-.291.766 0 1.058.293.291.767.291 1.059 0 .292-.292.292-.767 0-1.059-.146-.146-.338-.219-.53-.219zM46.347 88.63c-.617 0-1.235-.236-1.705-.706-.94-.94-.94-2.47 0-3.41s2.47-.94 3.41 0 .94 2.47 0 3.41c-.47.47-1.087.706-1.705.706zm0-3.159c-.191 0-.383.073-.53.219-.291.292-.291.767 0 1.059.293.291.767.291 1.06 0 .291-.292.291-.767 0-1.059-.147-.146-.338-.219-.53-.219z"/>
      <path fill="#FF7EB8" d="M59.027 27.962c-.212 0-.425-.08-.588-.243l-.992-.992-.992.992c-.324.324-.851.324-1.176 0-.325-.325-.325-.852 0-1.176l1.58-1.58c.325-.325.852-.325 1.176 0l1.58 1.58c.325.325.325.851 0 1.176-.162.162-.375.243-.588.243zM83.138 85.47c-.213 0-.426-.08-.588-.243l-.992-.992-.993.992c-.324.325-.85.325-1.175 0-.325-.325-.325-.851 0-1.176l1.58-1.58c.324-.324.851-.324 1.175 0l1.58 1.58c.325.325.325.852 0 1.176-.162.162-.375.244-.587.244z"/>
      <path fill="#6583FE" d="M39.752 86.26c-.213 0-.426-.08-.588-.243l-.992-.992-.992.992c-.325.325-.852.325-1.176 0-.325-.325-.325-.851 0-1.176l1.58-1.58c.324-.324.851-.324 1.176 0l1.58 1.58c.325.325.325.852 0 1.176-.163.163-.375.244-.588.244z"/>
      <defs>
        <linearGradient id="paint0_linear" x1="81.905" x2="42.986" y1="95.836" y2="44.278" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C6D1FF"/>
          <stop offset=".583" stopColor="#E6D5F4"/>
          <stop offset=".997" stopColor="#FFD9EC"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

IconEmailSent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconEmailSent.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmailSent;
