import React from 'react'
import css from './SectionProtection.css';
import { ReloveIconLock, ReloveIconProtection } from '../../components';

const SectionProtection = () => {
    return (
        <div className={css.containerSectionProtection}>
            <div className={css.containerInformation}>
                <ReloveIconLock/>
                <p className={css.safePurchaseText}> <span>Compra segura:</span> Si tu artículo no es como está descrito, te regresamos tu dinero</p>
            </div>
            <div className={css.containerInformation}>
                <ReloveIconProtection />
                <p className={css.authProtectionText}>  <span>Autenticación:</span> Gratis en +$10,000 MXN; o puedes solicitarlo en el checkout</p>
            </div>

            <div className={css.containerInformation}>
                <div></div>
                <a href={'https://gloset.notion.site/Autenticaci-n-5330d8c5a1c84bceb6ebf591dec54f3f'} className={css.moreInformationText}>Más información</a>
            </div>
            

        </div>
    )
}

export default SectionProtection